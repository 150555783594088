import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import InputComponent from "../../../components/InputComponent";
import MultiSelectParent from "../../../components/multiselect/MultiSelectParent";
import { apiEndPoint } from "../../../contants/Endpoints";
import { hotelsDetails } from "../../../store/admin/hotel/api/adminContractApi";
import { noAuthAxios } from "../../../utils/axiosWrapperOpen";
import { toastMessage } from "../../../utils/toast";

function NewTourCreate() {

  const dispatch = useDispatch();
  const [tourName, setTourName] = useState("");
  const [address, setAddress] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [facilitiesRejectArray, setFacilitiesRejectArray] = useState({});
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [amenties, setAmenties] = useState([]);
  const [tourOverview, setTourOverview] = useState([]);
  const [tourSpecialization, setTourSpecialization] = useState([]);
  const [tourAmenties, setTourAmenties] = useState([]);
  const [inclusion, setInclusion] = useState([]);
  const [exclusion, setExclusion] = useState([])

  let { id } = useParams();

  const [destinationList, setDestinationList] = useState([]);

  useEffect(() => {
    getDestinationList();
    if (id === "0") {
      // setIsLoading(false);
    } else {
      dispatch(hotelsDetails(process.env.REACT_APP_USER_TYPE, id));
    }
  }, []);

  const getDestinationList = () => {
    noAuthAxios
      .get(`${apiEndPoint.ADMIN_GET_INSPIRED}?requestId=123&currency=BDT`)
      .then((res) => {
        setDestinationList(res.data.inspiredLocationData);
      })
      .catch((err) => {
        toastMessage("Error fething travel destination", "error");
      });
  };

  const handleChange = (newValue, index, array, setArray) => {
    const newData = [...array];
    if (index === null) {
      newData.push(newValue);
    } else {
      newData.splice(index, 1);
    }
    setArray(newData);
  };

  return (
    <div>
      <div className="mt-[25px] mb-[50px]">
        <h2 className="text-center text-[22px]">Tour Create</h2>
        <div className="w-[180px] h-[1px] bg-bg-semiblack mx-auto mt-[4px]"></div>
        <div className="flex justify-between items-start max-w-[1100px] gap-x-[30px] mt-[30px]">
          <div className="w-[550px] ">
            <div className="flex flex-col gap-[8px]">
              <div>
                <p className="text-[16px] mb-[3px]">Tour Name :</p>
                <InputComponent
                  warningView={false}
                  required={false}
                  inputValue={tourName}
                  setInputValue={setTourName}
                  isReadOnly={false}
                  placeholder="Tour Name"
                  name="tourName"
                />
              </div>

              <div>
                <p className="text-[16px] mb-[3px]">Select Destination</p>
                <MultiSelectParent
                  required={true}
                  selectedArray={selectedDestination}
                  setSelectedArray={setSelectedDestination}
                  displayField={"name"}
                  selectionKey="id"
                  data={destinationList}
                  placeholder="Travel Destinations"
                  // isAdmin={
                  //   process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                  //     ? true
                  //     : false
                  // }
                  isReadOnly={false}
                  rejectionArray={facilitiesRejectArray}
                  setRejectionArray={setFacilitiesRejectArray}
                  name="selectedDestinations"
                />
              </div>

              <div>
                <p className="text-[16px] mb-[3px]">Short Address :</p>
                <InputComponent
                  warningView={false}
                  required={false}
                  inputValue={address}
                  setInputValue={setAddress}
                  isReadOnly={false}
                  placeholder="Short Address"
                  name="shortAddress"
                />
              </div>

              <div>
                <p className="text-[16px] mb-[3px]">Starting Price :</p>
                <InputComponent
                  warningView={false}
                  required={false}
                  inputValue={startingPrice}
                  setInputValue={setStartingPrice}
                  isReadOnly={false}
                  placeholder="Starting Price"
                  name="startingPrice"
                />
              </div>

              {/* amenities */}

              <div>
                <div className="border rounded-[8px] p-[8px]">
                  <p className="border-b border-bg-semiblack text-[16px] text-center">
                    Add Amenities
                  </p>

                  <List
                    data={amenties}
                    onRemove={(index) =>
                      handleChange(null, index, amenties, setAmenties)
                    }
                  />
                  <Form
                  name="Amenties"
                    onSubmit={(value) =>
                      handleChange(value, null, amenties, setAmenties)
                    }
                  />
                </div>
              </div>

              {/* tour duration */}
              <div>
                <p className="text-[16px] mb-[3px]">Tour duration :</p>
                <InputComponent
                  warningView={false}
                  required={false}
                  inputValue={startingPrice}
                  setInputValue={setStartingPrice}
                  isReadOnly={false}
                  placeholder="Tour duration"
                  name="startingPrice"
                />
              </div>

              {/* tour overview */}
              <div>
                <div className="border rounded-[8px] p-[8px]">
                  <p className="border-b border-bg-semiblack text-[16px] text-center">
                    Add Tour Overview
                  </p>

                  <List
                    data={tourOverview}
                    onRemove={(index) =>
                      handleChange(null, index, tourOverview, setTourOverview)
                    }
                  />
                  <Form
                   name="Tour Overview"
                    onSubmit={(value) =>
                      handleChange(value, null, tourOverview, setTourOverview)
                    }
                  />
                </div>
              </div>

              {/* tour specialization */}
              <div>
                <div className="border rounded-[8px] p-[8px]">
                  <p className="border-b border-bg-semiblack text-[16px] text-center">
                    Add Tour Specialization
                  </p>

                  <List
                    data={tourSpecialization}
                    onRemove={(index) =>
                      handleChange(null, index, tourSpecialization, setTourSpecialization)
                    }
                  />
                  <Form
                   name="Tour Specialization"
                    onSubmit={(value) =>
                      handleChange(value, null, tourSpecialization, setTourSpecialization)
                    }
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="w-[550px] ">
            {/* Tour Amenities */}
          <div className="mt-[25px]">
                <div className="border rounded-[8px] p-[8px]">
                  <p className="border-b border-bg-semiblack text-[16px] text-center">
                  Tour Amenities
                  </p>

                  <List
                    data={tourAmenties}
                    onRemove={(index) =>
                      handleChange(null, index, tourAmenties, setTourAmenties)
                    }
                  />
                  <Form
                  name="Tour Amenities"
                    onSubmit={(value) =>
                      handleChange(value, null, tourAmenties, setTourAmenties)
                    }
                  />
                </div>
              </div>
{/* Inclusion */}
              <div className="mt-[25px]">
                <div className="border rounded-[8px] p-[8px]">
                  <p className="border-b border-bg-semiblack text-[16px] text-center">
                  Inclusion
                  </p>

                  <List
                    data={inclusion}
                    onRemove={(index) =>
                      handleChange(null, index, inclusion, setInclusion)
                    }
                  />
                  <Form
                  name="Inclusion"
                    onSubmit={(value) =>
                      handleChange(value, null, inclusion, setInclusion)
                    }
                  />
                </div>
              </div>
{/* exclusion */}
              <div className="mt-[25px]">
                <div className="border rounded-[8px] p-[8px]">
                  <p className="border-b border-bg-semiblack text-[16px] text-center">
                  Exclusion
                  </p>

                  <List
                    data={exclusion}
                    onRemove={(index) =>
                      handleChange(null, index, exclusion, setExclusion)
                    }
                  />
                  <Form
                  name="Exclusion"
                    onSubmit={(value) =>
                      handleChange(value, null, exclusion, setExclusion)
                    }
                  />
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// same multi input value from 
function Form(props) {
 
  const handleSubmit = (event) => {
    event.preventDefault();
    const inputValue = event.target.input.value;
    props.onSubmit(inputValue);
    event.target.input.value = "";
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="h-[40px] mt-[10px] rounded-[8px] border border-bg-semiblack flex justify-start items-center"
    >
      <input
        name="input"
        placeholder={props?.name}
        type="text"
        className="h-full rounded-[8px] px-[8px] w-full "
      />
      
      <button
        type="submit"
        className="text-bg-white rounded-tr-[6px] cursor-pointer rounded-br-[6px] bg-btn-secondary hover:bg-btn-primary h-full w-[165px]"
      >
        Submit
      </button>
    </form>
  );
}
// common array store list view
function List(props) {
 
  const handleRemove = (index) => {
    props.onRemove(index);
  };

  return (
    <div className="flex justify-start items-center gap-x-[6px] mt-[10px]">
      <div className="flex flex-col w-full">
        {props.data?.map((item, index) => (
          <div
            className="flex  justify-between items-center gap-[3px]"
            key={index}
          >
            <div className="flex justify-start gap-x-[3px] items-center">
              <div className="w-[10px] h-[10px] rounded-full bg-[#000000b1]"></div>
              {item}
            </div>
            <button
              className=" bg-btn-primary text-bg-primary p-[2px] w-[15px] flex justify-center items-center h-[15px] cursor-pointer rounded-full"
              onClick={() => handleRemove(index)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewTourCreate;
