import React from "react";
//mr-[${mr ? mr : "20px"}]
const ButtonOutlined = ({ buttonOutlinedHandle, disable, title, h, w, mr}) => {
  // console.log("disable",disable)
  return (
    <button
      style={{ width: w ? w : 50, height: h ? h : 50 }}
      disabled={disable === "yes" ? disable : null}
      // {
      //   ...disable === 'yes' ? disable : null
      // }
      onClick={buttonOutlinedHandle}
      className={
        `w-[130px] h-[33px] border active:scale-90 transition duration-300 flex justify-center items-center rounded-[300px] leading-small bg-bg-primary text-[18px] text-btn-primary mr-[20px]  active:bg-txt-secondary active:border active:border-btn-primary active:text-btn-primary ` +
        (disable === "yes"
          ? "text-bg-semiblack border-txt-hint"
          : "border-btn-primary hover:bg-btn-primary hover:text-bg-white") +
        (disable === "yes" ? "" : "hover:bg-btn-primary hover:text-bg-white")
      }
    >
      {title ? title : "Submit"}
    </button>
  );
};

export default ButtonOutlined;
