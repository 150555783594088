import React, { useEffect } from "react";

function MultiSelectObject({
    item,
    setSelectedArray,
    selectedArray,
    displayField,
    setInputFieldValue,
    focusHandler,
    selectionKey,
}) {
    function checkIfSelected() {
        let result = selectedArray.find((element) => {
            return element[selectionKey] === item[selectionKey];
        });
        return result ? true : false;
    }

    const addSelected = (item) => {
        setSelectedArray([...selectedArray, item]);
    };
    const removeSelected = (item) => {
        setSelectedArray(
            selectedArray.filter((i) => i[selectionKey] !== item[selectionKey]),
        );
    };
    const handleChange = () => {
        if (!checkIfSelected()) {
            addSelected(item);
        } else {
            removeSelected(item);
        }
        setInputFieldValue("");
        focusHandler();
    };
    return (
        <div className="pb-4" key={Math.random()} id={Math.random()}>
            <label
                className={"text-xl w-full pl-2 cursor-pointer"}
                id={Math.random()}
            >
                <input
                    key={Math.random()}
                    id={Math.random()}
                    className={" text-2xl mr-6"}
                    type="checkbox"
                    value={item.value}
                    // checked={checked}
                    checked={checkIfSelected()}
                    onChange={handleChange}
                />
                {displayField ? item[displayField] : item.label}
            </label>
        </div>
    );
}

export default MultiSelectObject;
