import React, { useEffect, useRef, useState } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
// import SingleSelect from "./SingleSelect";
 
import RootComponent from "../../rootComponent/RootComponent";
import SingleSelectHotelReturn from "./SingleSelectHotelReturn";

function  SingleSelectParentHotelReturn({
  data,
  selectedValue,
  setSelectedValue,
  placeholder,
  displayField,
  selectedFieldName,
  propertyName,
  type,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  rejectionArray,
  setRejectionArray,
  name,
  getData,
  required=false,
}) {
  const [visible, setVisible] = React.useState(false);

  // state for ref width support
  const [width, setWidth] = React.useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  const [searchValue, setSearchValue] = useState("")

  let component = (
    <ReactOutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div className="w-full flex flex-col rounded-lg ">
        <div className=" bg-bg-primary pb-2 pt-2">
          <div
            className="w-full rounded-[8px] outline-custom black_box h-[49px] flex outline-[1px] outline-offset-[-1px] outline-none"
            ref={ref}
            onClick={() => { data.length === 0 && getData(); setVisible(!visible)}}
          >
            <div className="cursor-auto text-[#9e9989] md:text-sm text-left  py-3 h-full rounded-[8px] pl-3 ">
              <span className="w-full whitespace-nowrap">{placeholder}{required ? "*" : null}: </span>
            </div>
            <p
              className={`pl-4 py-3 bg-bg-primary ${
                selectedValue.id || propertyName
                  ? "text-txt-primary"
                  : "text-bg-semiblack"
              }`}
            >
              {selectedValue.id
                ? selectedValue.propertyName
                : propertyName
                ? propertyName
                : null}
            </p>
            <button className="bg-btn-primary ml-auto w-[49px] rounded-[8px] px-5 hover:bg-btn-secondary duration-500">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.5L6 8.5L10 4.5"
                  stroke="#FFFBEF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        {visible && (
          <div
            className=" pt-4 rounded-2xl mt-16 bg-bg-primary custom-shadow z-50 absolute max-h-[200px] h-auto overflow-scroll"
            style={{ width: width }}
          >
            <div className="">
              {data && data.length
                ? data.map((item, index) => {
                    return (
                      <SingleSelectHotelReturn
                        displayField={displayField}
                        key={index}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        setSearchValue={setSearchValue}
                        item={item}
                        setVisible={setVisible}
                        selectedFieldName={selectedFieldName}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        )}
      </div>
    </ReactOutsideClickHandler>
  )
  let readOnlyViewComponent = <p>{selectedValue.propertyName}</p>;
  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        name={name}
      />
    </div>
  );
}

export default SingleSelectParentHotelReturn;
