import React, { useEffect, useState } from "react";
import ButtonFilled from "../../../components/ButtonFilled";
import ButtonOutlined from "../../../components/ButtonOutlined";
import Loading from "../../../components/Loader/Loading";
import FileMultipleCategory from "../../../components/multipleimage/FileMultipleCategory";
import SingleSelectParentCategoryReturn from "../../../components/singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturn";
import { apiEndPoint, BOOKING_ENDPOINTS } from "../../../contants/Endpoints";
import { asyncLocalStorage } from "../../../utils/asyncLocalStorage";
import { authAxios } from "../../../utils/axiosWrapper";
import { toastMessage } from "../../../utils/toast";
import InputComponent from "../../../components/InputComponent";
import OutsideClickHandler from "react-outside-click-handler";

export default function SendEmailView({
    to,
    goBack,
    callBack,
    endPoint,
    personName,
    showAttachment = true,
    showBody = false,
    bookingId = null,
    mailType = "default",
}) {
    const [subject, setSubject] = useState("");
    const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [linkGenrated, setLinkGenrated] = useState(false);

    const [propertyName, setPropertyName] = useState("");
    const [showDropDown, setShowDropDown] = useState(false);

    const [bookings, setBookings] = useState([]);
    const [selectedBookingUuId, setSelectedBookingUuId] = useState("");
    const [body, setBody] = useState(
        `${process.env.REACT_APP_FRONT_END}booking-details?uuid=`
    );

    const [hotelList, setHotelList] = useState([]);

    const getHotels = () => {
        authAxios.get(`${apiEndPoint.MERCHANT_GET_OWN_HOTELS}`).then((res) => {
            setHotelList(res.data.propertyDataList);
        });
    };

    const [selectedHotels, setSelectedHotels] = useState([]);

    const addHotel = (item) => {
        const index = selectedHotels.findIndex((el) => el.id === item.id);
        if (index < 0) {
            setSelectedHotels([...selectedHotels, item]);
        }
    };

    const removeHotel = (id) => {
        const tmp = selectedHotels.filter((el) => el.id !== id);
        setSelectedHotels([...tmp]);
    };

    useEffect(() => {
        asyncLocalStorage.getItem("id").then((id) => {
            authAxios
                .get(
                    `${apiEndPoint.ADMIN_BOOKINGS_BY_USER_ID}/${id}?requestId=123`
                )
                .then((res) => {
                    setBookings(res.data.data);
                });
        });

        if (showAttachment) {
            getHotels();
        }
    }, []);

    useEffect(() => {
        if (setSelectedBookingUuId != "") {
            console.log(selectedBookingUuId);
            setLinkGenrated(false);
            setBody("");
        }
    }, [selectedBookingUuId]);

    const GeneratePaymentLink = () => {
        if (selectedBookingUuId) {
            // authAxios
            // .patch(`${apiEndPoint.ADMIN_GET_PAYMENT_LINK}`, {
            //     cancelUrl: `${process.env.REACT_APP_FRONT_END}directbooking/payment-status?status=failed&uuid=${selectedBookingUuId.invoiceUuId}`,
            //     failUrl: `${process.env.REACT_APP_FRONT_END}directbooking/payment-status?status=failed&uuid=${selectedBookingUuId.invoiceUuId}`,
            //     successUrl: `${process.env.REACT_APP_FRONT_END}directbooking/payment-status?status=success&uuid=${selectedBookingUuId.invoiceUuId}`,
            //     invoiceId: selectedBookingUuId.invoiceUuId,
            //     paymentChannel: "bkash.old",
            //     requestId: "11",
            // })
            // .then((res) => {
            setBody(
                `${process.env.REACT_APP_FRONT_END}directbooking/booking-details?uuid=${selectedBookingUuId.invoiceUuId}`
            );
            setLinkGenrated(true);
            // });
        } else {
            toastMessage("Please select a booking!");
        }
    };

    const SendMail = () => {
        setLoading(true);
        let formData = null;
        if (showAttachment) {
            formData = new FormData();

            formData.append("file", urlTempStorageArray[0]);
            formData.append("name", personName);
            formData.append("to", to);
            formData.append("body", body);
            formData.append("id", bookingId);
            formData.append("subject", subject);
        } else {
            formData = {
                to,
                paymentUrl: body,
                invoiceUuId: selectedBookingUuId.invoiceUuId,
                requestId: "string",
                name: personName,
            };
        }

        if (showAttachment) {
            authAxios
                .post(
                    `${endPoint}?requestId=email${
                        showBody && "&id=" + bookingId
                    }`,
                    formData
                )
                .then((res) => {
                    toastMessage("E-mail sent successfully!");
                    setSubject("");
                    setBody("");
                    setUrlTempStorageArray([]);
                    goBack();
                    setLoading(false);
                    callBack();
                })
                .catch((err) => {
                    toastMessage("Operation failed!");
                    setLoading(false);
                });
        } else {
            authAxios
                .patch(`${endPoint}/${bookingId}?requestId=email`, formData)
                .then((res) => {
                    toastMessage("E-mail sent successfully!");
                    setSubject("");
                    setBody("");
                    setUrlTempStorageArray([]);
                    goBack();
                    setLoading(false);
                    callBack();
                })
                .catch((err) => {
                    toastMessage("Operation failed!");
                    setLoading(false);
                });
        }
    };

    return (
        <div className="h-full w-full bg-bg-primary">
            <div className="flex gap-2 justify-between items-center">
                <button
                    onClick={() => goBack()}
                    className="px-6 text-2xl hover:text-txt-hint"
                >
                    <i className="fa fa-arrow-left"></i>{" "}
                </button>
                <div className="text-2xl">
                    Send email to : <span className="font-semibold">{to}</span>
                </div>
                <div className="px-6"></div>
            </div>

            <div className="px-6 py-8">
                {loading && (
                    <div className="flex justify-center h-[300px] items-center">
                        <Loading />
                    </div>
                )}
                {!loading && (
                    <div>
                        {showBody && (
                            <React.Fragment>
                                <div className="flex gap-2 items-center justify-center">
                                    <SingleSelectParentCategoryReturn
                                        data={bookings}
                                        displayField={"invoiceUuId"}
                                        placeholder="Select Booking"
                                        selectedFieldName={"invoiceUuId"}
                                        selectedValue={selectedBookingUuId}
                                        setSelectedValue={
                                            setSelectedBookingUuId
                                        }
                                        name="test"
                                    />
                                </div>
                                {linkGenrated && (
                                    <div>
                                        <div className="text-bg-semiblack mb-2 capitalize mt-8">
                                            Payment Link:
                                        </div>
                                        <div className="">
                                            <textarea
                                                rows={"3"}
                                                name="subject"
                                                className=" w-full bg-[transparent] p-6 outline-0 outline-none focus:outline-none border-[#2a220e73] border rounded-lg placeholder:text-bg-semiblack "
                                                value={body}
                                                placeholder="Write something..."
                                                onChange={(e) =>
                                                    setBody(e.target.value)
                                                }
                                                autoComplete={"off"}
                                            />
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        {showAttachment && (
                            <div>
                                <div className="flex flex-wrap gap-2 py-2 items-center h-[50px]">
                                    Selected Hotels :{" "}
                                    {selectedHotels.map((hotel, index) => (
                                        <div
                                            key={hotel.id}
                                            className="px-2 py-1 rounded-md shadow flex items-center gap-1"
                                        >
                                            {hotel.propertyName}
                                            <span
                                                onClick={() => {
                                                    removeHotel(hotel.id);
                                                }}
                                                className="cursor-pointer ml-1 border-l pl-2"
                                            >
                                                {" "}
                                                X{" "}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        setShowDropDown(false);
                                    }}
                                >
                                    <div className="relative">
                                        <InputComponent
                                            placeholder={"Search hotel"}
                                            inputValue={propertyName}
                                            setInputValue={(val) => {
                                                setPropertyName(val);
                                                setShowDropDown(true);
                                            }}
                                        />
                                        {showDropDown && (
                                            <div className="absolute w-[100%] bg-bg-primary top-12 rounded-xl shadow-xl overflow-auto max-h-[400px] mt-1">
                                                {hotelList
                                                    .filter((el) =>
                                                        el.propertyName
                                                            .toLowerCase()
                                                            .includes(
                                                                propertyName.toLowerCase()
                                                            )
                                                    )
                                                    .map((hotel, index) => (
                                                        <div
                                                            onClick={() => {
                                                                addHotel(hotel);
                                                                setShowDropDown(
                                                                    false
                                                                );
                                                                setPropertyName(
                                                                    ""
                                                                );
                                                            }}
                                                            key={hotel.id}
                                                            className="py-1 px-4 cursor-pointer hover:bg-btn-primary hover:text-bg-primary"
                                                        >
                                                            {hotel.propertyName}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </OutsideClickHandler>

                                <div className="text-bg-semiblack mb-2 capitalize mt-8 text-center text-xl">
                                    Attachment:
                                    {urlTempStorageArray.length > 0 ? (
                                        <span className="font-semibold">
                                            {" "}
                                            {urlTempStorageArray[0].name}{" "}
                                        </span>
                                    ) : (
                                        " No file selected"
                                    )}
                                </div>
                                <div className="">
                                    <FileMultipleCategory
                                        id={1}
                                        setNeedUpdate={() => {}}
                                        urlTempStorageArray={
                                            urlTempStorageArray
                                        }
                                        setUrlTempStorageArray={
                                            setUrlTempStorageArray
                                        }
                                        multiple={false}
                                        type={"alt"}
                                        upload={false}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {mailType === "paymentLink" && (
                    <React.Fragment>
                        {linkGenrated && (
                            <div className="mt-10 flex justify-center gap-4">
                                <div className="w-[300px]">
                                    <ButtonOutlined
                                        w={"100%"}
                                        arrow="no"
                                        disable={"no"}
                                        title={"Send Now"}
                                        buttonOutlinedHandle={SendMail}
                                    />
                                </div>
                            </div>
                        )}
                        {!linkGenrated && (
                            <div className="mt-10 flex justify-center gap-4">
                                <div className="w-[300px]">
                                    <ButtonFilled
                                        w={"100%"}
                                        arrow="no"
                                        // disable={"no"}
                                        title={"Generate Payment Link"}
                                        buttonFilledHandle={GeneratePaymentLink}
                                    />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}

                {mailType === "default" && (
                    <div className="mt-10 flex justify-center gap-4">
                        <div className="w-[300px]">
                            <ButtonOutlined
                                w={"100%"}
                                arrow="no"
                                disable={"no"}
                                title={"Send Now"}
                                buttonOutlinedHandle={SendMail}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
