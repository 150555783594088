import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import ButtonFilled from "../../../components/ButtonFilled";
import { adminRoute } from "../../../contants/Constants";
import { apiEndPoint } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";

function AdminTourManage() {
    const navigate = useNavigate();

    const [alltours, setAllTours] = React.useState([]);

    const getTours = () => {
        authAxios
            .get(`${apiEndPoint.ADMIN_GET_TOUR}`)
            .then((res) => {
                setAllTours(res.data.tourDataList);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        getTours();
    }, []);

    return (
        <div className="container mx-auto">
            <div className="flex justify-between items-center mb-2">
                <div className="text-xl">All Tours</div>
                <ButtonFilled
                    title={"Create New"}
                    w="300px"
                    buttonFilledHandle={() => {
                        navigate(`${adminRoute.ADMIN_TOUR_CREATE}`);
                    }}
                />
            </div>
            <hr />
            <div className="mt-10">
                {alltours.map((tour) => (
                    <div className="border-b border-t mb-2 border-[#e2e2e2]">
                        <div className=" items-center border-b border-[#b1b1b1] py-4 flex justify-between px-4 hover:border-bg-semiblack">
                            <div>
                                <i className="fa fa-car mr-2" ></i>
                                <span className="font-semibold">
                                    {tour.title}
                                </span>
                            </div>
                            <div className="gap-2 flex">
                                <button
                                    onClick={() =>
                                        navigate(
                                            `${adminRoute.ADMIN_TOUR_MANAGE}/${tour.id}/add-category`
                                        )
                                    }
                                    className="bg-bg-primary active:scale-95 hover:text-[#2e2e2eab] px-3 py-1 shadow-md rounded-md text-[black]"
                                >
                                    Add Category
                                </button>
                                <button
                                    onClick={() =>
                                        navigate(
                                            `${adminRoute.ADMIN_TOUR_Details_BASE}/${tour.id}`
                                        )
                                    }
                                    className="bg-bg-primary active:scale-95 hover:text-[#4242e48a] px-3 py-1 shadow-md rounded-md text-[blue]"
                                >
                                    Edit
                                </button>
                                <button className="bg-bg-primary active:scale-95 hover:text-[#ff000077] px-3 py-1 shadow-md rounded-md text-[red]">
                                    Delete
                                </button>
                            </div>
                        </div>
                        <div className="ml-12 my-2 text-sm">
                            {tour.categoryData?.length > 0
                                ? tour.categoryData.map((cat) => (
                                      <div className="flex gap-2 items-center py-2">
                                          <i className="fa fa-circle"></i>
                                          {cat.title}
                                          <button
                                              onClick={() =>
                                                  navigate(
                                                      `${adminRoute.ADMIN_TOUR_MANAGE}/package/${cat.id}/edit`
                                                  )
                                              }
                                              className="bg-bg-primary active:scale-95 hover:text-[#4242e48a] px-3 py-1 shadow-md rounded-md text-[blue]"
                                          >
                                              Edit
                                          </button>
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminTourManage;
