export const GET_USER_DETAILS = "GET_USER_DETAILS";


export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'; 

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'; 

export const FETCH_ROLE_REQUEST = 'FETCH_ROLE_REQUEST';
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
export const FETCH_ROLE_ERROR = 'FETCH_ROLE_ERROR'; 

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR'; 

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR'; 

export const EDIT_ROLE_REQUEST = 'EDIT_ROLE_REQUEST';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_ERROR = 'EDIT_ROLE_ERROR'; 

export const FETCH_FUNCTION_REQUEST = 'FETCH_FUNCTION_REQUEST';
export const FETCH_FUNCTION_SUCCESS = 'FETCH_FUNCTION_SUCCESS';
export const FETCH_FUNCTION_ERROR = 'FETCH_FUNCTION_ERROR'; 

export const CREATE_FUNCTION_REQUEST = 'CREATE_FUNCTION_REQUEST';
export const CREATE_FUNCTION_SUCCESS = 'CREATE_FUNCTION_SUCCESS';
export const CREATE_FUNCTION_ERROR = 'CREATE_FUNCTION_ERROR'; 

export const EDIT_FUNCTION_REQUEST = 'EDIT_FUNCTION_REQUEST';
export const EDIT_FUNCTION_SUCCESS = 'EDIT_FUNCTION_SUCCESS';
export const EDIT_FUNCTION_ERROR = 'EDIT_FUNCTION_ERROR'; 

export const DELETE_FUNCTION_REQUEST = 'DELETE_FUNCTION_REQUEST';
export const DELETE_FUNCTION_SUCCESS = 'DELETE_FUNCTION_SUCCESS';
export const DELETE_FUNCTION_ERROR = 'DELETE_FUNCTION_ERROR'; 

export const FETCH_FUNCTION_TO_ROLE_REQUEST = 'FETCH_FUNCTION_TO_ROLE_REQUEST';
export const FETCH_FUNCTION_TO_ROLE_SUCCESS = 'FETCH_FUNCTION_TO_ROLE_SUCCESS';
export const FETCH_FUNCTION_TO_ROLE_ERROR = 'FETCH_FUNCTION_TO_ROLE_ERROR'; 

export const ASSIGN_FUNCTION_TO_ROLE_REQUEST = 'ASSIGN_FUNCTION_TO_ROLE_REQUEST';
export const ASSIGN_FUNCTION_TO_ROLE_SUCCESS = 'ASSIGN_FUNCTION_TO_ROLE_SUCCESS';
export const ASSIGN_FUNCTION_TO_ROLE_ERROR = 'ASSIGN_FUNCTION_TO_ROLE_ERROR'; 