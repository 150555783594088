import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dual from "./comp/Dual";

function RolePermissionAssign() {
  const roles = useSelector((state) => state.fetchFunctionReducer.data);
  console.log(roles, "roles");
  
  return (
    <div className="">
        <div>
          <Dual />
        </div>
    </div>
  );
}

export default RolePermissionAssign;
