import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import ButtonFilled from "../../../components/ButtonFilled";
import ButtonOutlined from "../../../components/ButtonOutlined";
import { SvgCross } from "../../../components/common/AllSVGImport";
import PaginationComponent from "../../../components/common/Pagination";
import Loading from "../../../components/Loader/Loading";
import { apiEndPoint } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import { integerDateToValidDate } from "../../../utils/DateFormatTranferToSixDigit";

const AdminTourList = () => {
    const [bookingOverlay, setBookingOverlay] = useState(false);

    const [selectedBooking, setSelctedBooking] = useState(null);

    const [pageSize, setPageSize] = useState(5);
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [status, setStatus] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const [loading, setLoading] = useState(true);

    // const [newBookings, setNewBookings] = useState(initialNew);
    // const [inProgBookings, setInProgBookings] = useState(initialProg);
    // const [bookedBookings, setBookedBookings] = useState(initialBooked);
    // const [cancelledBookings, setCancelledBookings] = useState(initialCancel);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [currentView, setCurrentView] = useState("default");

    const statusToUrlMap = {
        INIT: "",
        IN_PROGRESS: "ADMIN_SEND_EMAIL_HOTEL_LIST",
        HOTEL_LIST_SENT: "ADMIN_DIRECT_BOOKING_MERGE",
    };

    const statusToButtonTextMap = {
        IN_PROGRESS: "Send hotel list",
        HOTEL_LIST_SENT: "Send payment link",
    };

    const [directBookings, setDirectBookings] = useState([]);

    useEffect(() => {
        getBookings();
    }, [currentPage, status, startDate, endDate, pageSize]);

    const getBookings = () => {
        setLoading(true);
        authAxios
            .get(
                `${apiEndPoint.ADMIN_GET_TOUR_BOOKINGS}?requestId=123&invoiceStatus=${status}&pageSize=${pageSize}&pageNo=${currentPage}&referenceId=${searchValue}`
            )
            .then((res) => {
                setDirectBookings(() => [...res.data.invoiceDataList]);
                setTotalElements(res.data.totalElement);
                setLoading(false);
            })
            .catch((err) => {
                setDirectBookings([]);
                setLoading(false);
                console.log(err);
            });
    };

    const statusData = [
        { value: "", name: "All" },
        { value: "INIT", name: "New" },
        { value: "IN_PROGRESS", name: "In Progress" },
        { value: "PENDING_PAYMENT", name: "Payment pending" },
        { value: "PAYMENT_LINK_SENT", name: "Payment Link Sent" },
        { value: "BOOKING_REQUESTED", name: "Payment successfull" },
        { value: "CONFIRMED", name: "Confirmed" },
        { value: "CANCELLED", name: "Cancelled" },
    ];
    // const

    return (
        <div className="container mx-auto">
            <div className="flex gap-10 justify-between items-center">
                <div className="text-2xl">
                    Direct Bookings ({totalElements}){" "}
                </div>
            </div>

            <div className="flex gap-4 items-center mt-10">
                <div> Filter : </div>
                {statusData.map((st, index) => (
                    <button
                        key={st.value}
                        onClick={() => {
                            setStatus(st.value);
                        }}
                        className={`rounded-lg transition duration-400 active:scale-90 py-2 px-6 shadow-md hover:bg-btn-secondary hover:text-txt-secondary ${
                            status === st.value &&
                            "bg-btn-primary text-txt-secondary"
                        } `}
                    >
                        {st.name}
                    </button>
                ))}
            </div>

            <div className="my-4 flex gap-2 items-center max-w-[750px]">
                {/* <div> Search : </div> */}
                <input
                    className="px-4 flex-grow outline-none rounded-lg shadow-md bg-[transparent] focus:shadow-lg h-[50px]"
                    placeholder="Enter Booking ID"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <ButtonFilled
                    title={"Search"}
                    buttonFilledHandle={() => {
                        status !== "" ? setStatus("") : getBookings();
                    }}
                    w={"220px"}
                    arrow="no"
                />
            </div>
            <div className="pb-20">
                <div className="h-[550px] overflow-y-auto w-full">
                    {!loading ? (
                        <table
                            id="students"
                            className="min-w-full leading-normal"
                        >
                            <thead>
                                <tr className="sticky h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider"></th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        ID
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Date
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Tour Name
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Tour Location
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Name
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Phone
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Email
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Status
                                    </th>
                                    {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Action
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody className="">
                                {directBookings &&
                                    !loading &&
                                    directBookings.map((booking, index) => {
                                        const {
                                            tourTitle,
                                            location,
                                            personName,
                                            personEmail,
                                            personNumber,
                                            id,
                                            invoiceUuid,
                                            invoiceStatus,
                                            bookingDetailsDataList,
                                        } = booking; //destructuring
                                        return (
                                            <tr
                                                key={invoiceUuid || index}
                                                className="border-b"
                                            >
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                                    {currentPage * pageSize +
                                                        (index + 1)}
                                                    .
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                                    {invoiceUuid}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                    {new Date(
                                                        bookingDetailsDataList[0].startDate
                                                    ).toDateString()}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                    {tourTitle}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                    {location}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                    {personName}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                    {personNumber}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                    {personEmail}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                    {Object.values(
                                                        statusData
                                                    ).map(
                                                        (item) =>
                                                            item.value ===
                                                                invoiceStatus &&
                                                            item.name
                                                    )}
                                                </td>
                                                {/* <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                                    <ButtonOutlined
                                                        title={"See details"}
                                                        w="100%"
                                                        buttonOutlinedHandle={() => {}}
                                                    />
                                                </td> */}
                                            </tr>
                                        );
                                    })}
                                {directBookings.length === 0 && !loading && (
                                    <tr className="border">
                                        <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                            No data
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <div className="w-full flex justify-center items-center">
                            <div>
                                <Loading />
                            </div>
                        </div>
                    )}
                </div>
                <PaginationComponent
                    totalElements={totalElements}
                    currentPage={currentPage}
                    dataSize={directBookings.length}
                    pageSize={pageSize}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    showCount={true}
                />
                {/* {loading && (

                )} */}
            </div>
            {bookingOverlay && (
                <ReactModal
                    isOpen={bookingOverlay}
                    onRequestClose={() => {
                        setCurrentView("default");
                        setBookingOverlay(false);
                        setSelctedBooking(null);
                    }}
                    ariaHideApp={false}
                >
                    <div className="min-h-[200px] mx-auto min-w-[1200px] py-8 break-words relative">
                        <div
                            onClick={() => {
                                setCurrentView("default");
                                setBookingOverlay(false);
                                setSelctedBooking(null);
                            }}
                            className="cursor-pointer absolute right-8 top-4"
                        >
                            <SvgCross />
                        </div>
                        {currentView === "default" && (
                            <React.Fragment>
                                <div className="mb-12">
                                    <div className="text-center text-2xl">
                                        Booking{" "}
                                        <span className="font-bold">
                                            {selectedBooking.referenceId}
                                        </span>
                                    </div>
                                    <div className="text-center">
                                        Status:
                                        <select
                                            className="font-semibold bg-[transparent] px-4 cursor-pointer"
                                            onChange={(e) => {}}
                                            value={selectedBooking.status}
                                        >
                                            {statusData.map((st, index) => (
                                                <React.Fragment>
                                                    <option value={st.value}>
                                                        {" "}
                                                        {st.name}{" "}
                                                    </option>
                                                </React.Fragment>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="text-center my-2">
                                        Booking Date :{" "}
                                        {new Date(
                                            selectedBooking.createdAt
                                        ).toDateString()}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                        <div className="col-span-1">
                                            <div
                                                id="address"
                                                className="required"
                                            >
                                                <div className="text-bg-semiblack mb-2 capitalize ">
                                                    Location
                                                </div>
                                                <div className="h-[49px]">
                                                    <input
                                                        value={
                                                            selectedBooking.address
                                                        }
                                                        className=" w-full bg-[transparent] pl-6 outline-0 outline-none focus:outline-none border-[#2a220e73] border h-full rounded-lg placeholder:text-bg-semiblack "
                                                        placeholder="Dhaka, Bangladesh"
                                                        autoComplete={"off"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-bg-semiblack mb-2 capitalize mt-4">
                                                Name:
                                            </div>
                                            <div className="h-[49px]">
                                                <input
                                                    name="name"
                                                    className=" w-full bg-[transparent] pl-6 outline-0 outline-none focus:outline-none border-[#2a220e73] border h-full rounded-lg placeholder:text-bg-semiblack "
                                                    value={selectedBooking.name}
                                                    autoComplete={"off"}
                                                />
                                            </div>
                                            <div className="text-bg-semiblack mb-2 capitalize mt-4">
                                                phone number:
                                            </div>
                                            <div className="h-[49px]">
                                                <input
                                                    name="email"
                                                    value={
                                                        selectedBooking.phoneNo
                                                    }
                                                    className=" w-full bg-[transparent] pl-6 outline-0 outline-none focus:outline-none border-[#2a220e73] border h-full rounded-lg placeholder:text-bg-semiblack "
                                                    placeholder="example@email.com"
                                                    autoComplete={"off"}
                                                />
                                            </div>
                                            <div className="text-bg-semiblack mb-2 capitalize mt-4">
                                                email:
                                            </div>
                                            <div className="h-[49px]">
                                                <input
                                                    name="email"
                                                    value={
                                                        selectedBooking.email
                                                    }
                                                    className=" w-full bg-[transparent] pl-6 outline-0 outline-none focus:outline-none border-[#2a220e73] border h-full rounded-lg placeholder:text-bg-semiblack "
                                                    placeholder="example@email.com"
                                                    autoComplete={"off"}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-1">
                                            <div className="text-bg-semiblack mb-2">
                                                Budget range
                                            </div>
                                            <div className="text-txt-primary my-2 capitalize h-[49px]">
                                                <input
                                                    value={`${selectedBooking.fromPrice} ${selectedBooking.currency} - ${selectedBooking.toPrice} ${selectedBooking.currency} per night`}
                                                    className=" w-full bg-[transparent] pl-6 outline-0 outline-none focus:outline-none border-[#2a220e73] border h-full rounded-lg placeholder:text-bg-semiblack "
                                                />
                                            </div>

                                            <div className="mt-4 grid  grid-cols-1 gap-8">
                                                <div className="col-span-1 relative">
                                                    <div className="text-bg-semiblack text-base11 sm:text-base16 mb-2">
                                                        Duration
                                                    </div>
                                                    <button className="w-full text-base11 sm:text-base14 rounded-[8px] h-[49px] flex justify-start items-center px-2 border-[#9e9989] border relative">
                                                        {`${new Date(
                                                            integerDateToValidDate(
                                                                selectedBooking.startDate
                                                            )
                                                        ).toDateString()} - ${new Date(
                                                            integerDateToValidDate(
                                                                selectedBooking.endDate
                                                            )
                                                        ).toDateString()}`}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="text-bg-semiblack mb-2 capitalize mt-4">
                                                property type
                                            </div>
                                            <div className="h-[49px]">
                                                <input
                                                    name="email"
                                                    value={
                                                        selectedBooking.propertyType
                                                    }
                                                    className=" w-full bg-[transparent] pl-6 outline-0 outline-none focus:outline-none border-[#2a220e73] border h-full rounded-lg placeholder:text-bg-semiblack "
                                                    placeholder="example@email.com"
                                                    autoComplete={"off"}
                                                />
                                            </div>
                                            <div className="hidden lg:grid grid-cols-4 mt-4 justify-between gap-2">
                                                <div className="col-span-1 ">
                                                    <div className="text-bg-semiblack text-sm text-center mb-4">
                                                        Number of Rooms
                                                    </div>
                                                    <div className="rounded-[70px] shadow-lg h-[50px] flex justify-center items-center  w-full">
                                                        <button className="px-1 w-full h-full">
                                                            -
                                                        </button>
                                                        <input
                                                            value={
                                                                selectedBooking.noOfRooms
                                                            }
                                                            className="bg-[transparent]  outline-none text-txt-primary text-center w-[50px] h-full"
                                                        />

                                                        <button className="px-1 w-full h-full">
                                                            +
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="hidden sm:block sm:col-span-1"></div>

                                                <div className="col-span-1 ">
                                                    <div className="text-bg-semiblack text-sm text-center mb-4">
                                                        Number of Adults
                                                    </div>
                                                    <div className="rounded-[70px] shadow-lg h-[50px] flex justify-center items-center  w-full">
                                                        <button className="px-1 w-full h-full">
                                                            -
                                                        </button>
                                                        <input
                                                            value={
                                                                selectedBooking.adult
                                                            }
                                                            className="bg-[transparent]  outline-none text-txt-primary text-center w-[50px] h-full"
                                                        />

                                                        <button className="px-1 w-full h-full">
                                                            +
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-span-1 ">
                                                    <div className="text-bg-semiblack text-sm text-center mb-4">
                                                        Number of Child
                                                    </div>
                                                    <div className="rounded-[70px] shadow-lg h-[50px] flex justify-center items-center  w-full">
                                                        <button className="px-1 w-full h-full">
                                                            -
                                                        </button>
                                                        <input
                                                            value={
                                                                selectedBooking.child
                                                            }
                                                            className="bg-[transparent]  outline-none text-txt-primary text-center w-[50px] h-full"
                                                        />

                                                        <button className="px-1 w-full h-full">
                                                            +
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        <div className="mt-10 flex justify-center gap-4">
                            {currentView !== "mailsend" &&
                                (selectedBooking.status === "IN_PROGRESS" ||
                                    selectedBooking.status ===
                                        "HOTEL_LIST_SENT") && (
                                    <div className="w-[300px]">
                                        <ButtonFilled
                                            w={"100%"}
                                            arrow="no"
                                            title={
                                                statusToButtonTextMap[
                                                    selectedBooking.status
                                                ]
                                            }
                                            buttonFilledHandle={() =>
                                                setCurrentView("mailsend")
                                            }
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
                </ReactModal>
            )}
        </div>
    );
};

export default AdminTourList;
