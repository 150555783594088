export const parseDate = (dateValue) => {
    const val = dateValue?.toString();
    const day = val[6].toString() + val[7].toString();
    const month = val[4].toString() + val[5].toString();
    const year = val.slice(0, 4);
    console.log(`${day}  ${month} ${year}`);
    return `${month}/${day}/${year}`;
  };
  
  
  const getDay = (day1, day2) => {
    let date_1 = new Date(parseDate(day1));
    let date_2 = new Date(parseDate(day2));
    let difference = date_1.getTime() - date_2.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return totalDays;
  };
  
  export default getDay;