import React, { useState } from 'react';
import ButtonFilled from '../../../../components/ButtonFilled';
import ButtonOutlined from '../../../../components/ButtonOutlined';


function BookingRejectReason({ onSubmit, onCancel, loading }) {

    const [description, setDescription] = useState("");
    const [selectedReason, setSelectedReason] = useState(null);
    const [reasonList, setReasonList] = useState([
        "Customer Cancellation",
        "Rules Broken",
        "Payment period expired",
    ]);
    const [isHidden, setIsHidden] = useState(true);

    return (
        <div className='flex justify-center'>
            <div className='w-2/3'>
                <div className='text-center text-3xl mb-12'>
                    You are about to Cancel a reservation for Mr. John Doe - Reservation #1
                </div>

                <div>
                    Please, choose the reason from the dropdown
                </div>

                <div className='my-1 w-full relative'>
                    <div onClick={() => setIsHidden(!isHidden)} className="cursor-pointer hover:border-[#b1ad9c] border-[#9e9989] border h-[50px] rounded-[8px] w-full flex items-center px-4 justify-between">
                        {!selectedReason &&
                            <div className='text-bg-semiblack'>
                                Choose the reason from the dropdown
                            </div>
                        }
                        <div>
                            {selectedReason}
                        </div>

                        <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2 4.5L6 8.5L10 4.5"
                                stroke="#9e9989"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <ul
                        id="scroll-x"
                        className={
                            "max-h-[200px] w-full h-auto overflow-scroll custom-shadow dropdown-menu absolute text-gray-700 bg-bg-primary mt-2 rounded-[8px] custom-shadow z-10 " +
                            (isHidden ? "hidden" : "")
                        }
                    >
                        {reasonList
                            .map((each, index) => {
                                return (
                                    <li key={index}>
                                        <div
                                            onClick={() => { setSelectedReason(each); setIsHidden(true) }}
                                            // onMouseUp={() => handleClick()}
                                            className="cursor-pointer text-txt-primary hover:bg-btn-secondary hover:text-txt-secondary py-2 px-4 block whitespace-no-wrap"
                                        >
                                            {each}
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </div>

                <div className='my-12'>
                    <div>
                        Write the description and submit for review
                    </div>
                    <textarea value={description} onChange={e => setDescription(e.target.value)} rows={'5'} className='resize-none border-[#9e9989] border bg-[transparent] rounded-[8px] w-full flex-grow p-4 outline-0 outline-none'>

                    </textarea>
                </div>

                <div className="flex gap-x-8 w-full justify-center mt-20">
                    <ButtonOutlined
                        title={"Submit"}
                        w="200px"
                        h="49px"
                        buttonOutlinedHandle={()=>{onSubmit(selectedReason,description)}}
                    />
                    <ButtonFilled
                        title={"Close"}
                        w="200px"
                        h="49px"
                        buttonFilledHandle={onCancel}
                        arrow={true}
                    />

                </div>
            </div>
        </div>
    )
}

export default BookingRejectReason;