import React from "react";

function MultiSelect({
  item,
  setSelectedArray,
  selectedArray,
  displayField,
  setInputFieldValue,
  focusHandler,
}) {
  function checkIfSelected() {
    return selectedArray.includes(item.id)
  }
  const addSelected = (item) => {
    setSelectedArray([...selectedArray, item.id]);
  };
  const removeSelected = (item) => {
    setSelectedArray(selectedArray.filter((i) => i !== item['id']));
  };
  const handleChange = () => {
    if (!checkIfSelected()) {
      addSelected(item);
    } else {
      removeSelected(item);
    }
    setInputFieldValue("");
    focusHandler();
  };
  return (
    <div className="pb-4 " key={Math.random()} id={Math.random()}>
      <label className={"text-xl w-full pl-2 cursor-pointer inline-block hover:text-[#777]"} id={Math.random()}>
        <input
          key={Math.random()}
          id={Math.random()}
          className={" text-2xl mr-6"}
          type="checkbox"
          value={item.value}
          checked={checkIfSelected()}
          onChange={handleChange}
        />
        {displayField ? item[displayField] : item.label}
      </label>
    </div>
  );
}

export default MultiSelect;
