import React from "react";

function TrippleDocActionButton() {
  return (
    <button onClick={()=>{console.log("clicked")}}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26C17.1046 26 18 25.1046 18 24Z"
          fill="#2A220E"
        />
        <path
          d="M18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16Z"
          fill="#2A220E"
        />
        <path
          d="M18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8Z"
          fill="#2A220E"
        />
      </svg>
    </button>
  );
}

export default TrippleDocActionButton;
