import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import ButtonFilled from "../../../components/ButtonFilled";
import { SvgCross } from "../../../components/common/AllSVGImport";
import getDay from "../../../components/common/getDay";
import InputComponent from "../../../components/InputComponent";
import { apiEndPoint } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import CustomValidation from "../../../utils/customValidation";
import { DateFormatTranferToSixDigit } from "../../../utils/DateFormatTranferToSixDigit";
import { toastMessage } from "../../../utils/toast";

const  AdminCreateInvoice =()=>{
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [dueDate, setDueDate] = useState(new Date());
    const [bookingDate, setBookingDate] = useState(new Date());

    const [travelStartDate, setTravelStartDate] = useState(new Date());
    const [travelEndDate, setTravelEndDate] = useState(new Date());
    const [duration,setDuration] = useState('');

    const [invoiceNr, setInvoiceNr] = useState("");
    const [bookingCode,setBookingCode] = useState("");
    const [orgName, setOrgName] = useState("");
    const [orgPhone, setOrgPhone] = useState("");
    const [orgEmail, setOrgEmail] = useState("");
    const [signature, setSignature] = useState("");
    const [reservation, setReservation] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [saved, setSaved] = useState(true);
    const [serviceWarning,setServiceWarning] = useState(false);
    const [calenderWarning,setCalenderWarning] =useState(false);
    const [clicked, setClicked] = useState(false);


    const [address, setaddress] = useState("");
    const [mode, setMode] = useState("create");
    const [paidAmount, setPaidAmount] = useState(0.0);
    const [discount, setDiscount] = useState(0.0);
    const [discountPercent, setDiscountPercent] = useState(0);
    const [subTotal, setSubtotal] = useState(0.0);
    const [payable, setPayable] = useState(0.0);
    const [invoiceTotal, setinvoiceTotal] = useState(0.0);
    const [items, setItems] = useState([]);
    const demoItem = {
        id: items.length + 1,
        description: "",
        price: 0.0,
        qty: 0,
        totalPrice: 0.0,
    };
    const [selectedItem, setSelectedItem] = useState(demoItem);


    useEffect(() => {
        setSaved(false);
        if (selectedItem.price && selectedItem.qty) {
            setSelectedItem({
                ...selectedItem,
                totalPrice: parseFloat(
                    selectedItem.qty * selectedItem.price
                ).toFixed(2),
            });
        }
    }, [selectedItem.price, selectedItem.qty]);

    useEffect(()=>{
        // console.log(DateFormatTranferToSixDigit(travelEndDate),travelStartDate, 'date test')
        const tempEnd = DateFormatTranferToSixDigit(travelEndDate);
        const tempStart = DateFormatTranferToSixDigit(travelStartDate);

     const day = getDay(tempEnd,tempStart);
     console.log(day,'test date')
     if(day>0){
        setDuration(day);
     }
    },[travelEndDate,travelStartDate])



    useEffect(() => {
        setSaved(false);
        let total = 0;
        if (items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                total = total + parseFloat(element.totalPrice);
            }
        }
        setSubtotal(parseFloat(total).toFixed(2));
        setinvoiceTotal(parseFloat(total - discount - paidAmount).toFixed(2));
    }, [items]);

    useEffect(() => {
        const data = localStorage.getItem("invoiceData");
        if (data) {
            const parsed = JSON.parse(data);
            setOrgName(parsed.orgName);
            setOrgEmail(parsed.orgEmail);
            setOrgPhone(parsed.orgPhone);
            setSignature(parsed.signature);
            setReservation(parsed.reservation);
            setInvoiceDate(new Date(parsed.invoiceDate));
            setDueDate(new Date(parsed.dueDate));
            setaddress(parsed.address);
            setPaidAmount(parsed.paidAmount);
            setDiscount(parsed.discount);
            setDiscountPercent(parsed.discountPercent);
            setSubtotal(parsed.subTotal);
            setinvoiceTotal(parsed.invoiceTotal);
            setItems(parsed.items);
            localStorage.removeItem("invoiceData");
        }
    }, []);

    const printDiv = (divName) => {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        const data = {
            invoiceNr,
            orgName,
            orgPhone,
            orgEmail,
            signature,
            reservation,
            invoiceDate,
            dueDate,
            address,
            paidAmount,
            discount,
            discountPercent,
            subTotal,
            invoiceTotal,
            items,
        };
        localStorage.setItem("invoiceData", JSON.stringify(data));
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    const handleDiscount = (type, val) => {
        if (type === "amount") {
            setDiscount(val);
            setDiscountPercent(parseFloat((val / subTotal) * 100).toFixed(2));
        } else {
            setDiscountPercent(val);
            setDiscount(parseFloat(subTotal * (val / 100)).toFixed(2));
        }
        setPayable(subTotal-discount);
    };

    useEffect(() => {
        setSaved(false);
        if (subTotal > 0) {
            setinvoiceTotal(
                parseFloat(subTotal - discount - paidAmount).toFixed(2)
            );
        }
    }, [discount, paidAmount]);


    const SaveInvoice = () => {
        const obj = {
            bookingCode: "string",
            bookingDate:bookingDate.toISOString(),
            duration: duration,
            id: 0,
            invoice:{
                address: address,
                bookingCode:"string",
                currency:"BDT",
                customerName:orgName,
                discountAmount: discount,
                discountPercent: discountPercent,
                dueAmount: 0,
                dueDate: dueDate.toISOString(),
                email:orgEmail,
                id:0,
                invoiceDate:invoiceDate.toISOString(),
                invoiceNr:"string",
                paidAmount:0,
                phoneNumber:orgPhone,
                purchasedServices:items.map((item) => {
                    return {
                        description: item.description,
                        perQuantityPriceAftDiscount: item.price,
                        perQuantityPriceBefDiscount: item.price,
                        quantity: item.qty,
                        title: item.description
                    };
                }),
                signedBy:signature,
                status:"string",
                totalAmount: subTotal,
                totalPayAble: subTotal-discount,
            },
            requestId:"1234",
            travelEndDate:travelEndDate.toISOString(),
            travelStartDate:travelStartDate.toISOString()
        };
        authAxios
            .post(`${apiEndPoint.ADMIN_ADD_MANUAL_BOOKING}`, obj)
            .then((res) => {
                setSaved(true);
                toast.success("Save successful!");
                setInvoiceNr(res?.data?.invoiceData?.invoiceNr);
                setBookingCode(res?.data?.invoiceData?.bookingCode)
                // setSaveInvoice(res.data?.bookingCode)
            })
            .then(()=>{
                setClicked(false);
            })
            .catch((err) => {});
    };


    const isNoService =()=>{
        console.log(items,'check')
        if(items.length<=0){
            return true
        }
        return false;
    }

    const isDateValidate =()=>{
        const tempEnd = DateFormatTranferToSixDigit(travelEndDate);
        const tempStart = DateFormatTranferToSixDigit(travelStartDate);

        const day = getDay(tempEnd,tempStart);
        if(day>0){
            return true;
        }
        else
        return false;
    }


    return (
        <div className="max-w-[110rem] mx-auto ">
            <div className="flex w-full gap-4">
                {/* input form data */}
                <div className="w-1/2  flex flex-col gap-6">
                    <div>
                        <div className="my-2">Invoice Date</div>
                        <ReactDatePicker
                            title="Invoice Date"
                            placeholderText="Invoice Date"
                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                            selected={invoiceDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) =>{ setInvoiceDate(()=>{
                                return date;
                            });
                            console.log(date.toISOString(),'date test')}}
                        />
                         <div className="my-2">Booking Date</div>
                        <ReactDatePicker
                            title="Booking Date"
                            placeholderText="Booking Date"
                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                            selected={bookingDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) =>{ setBookingDate(()=>{
                                return date;
                            });
                            console.log(date.toISOString(),'date test')}}
                        />
                        <div className="my-2">Invoice Due Date</div>
                        <ReactDatePicker
                            title="Invoice Due Date"
                            placeholderText="Invoice Due Date"
                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                            selected={dueDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setDueDate(date)}
                        />
                    </div>

                    <div>
                        <div className="my-2">Travel Start Date</div>
                        <ReactDatePicker
                            title="Start Date"
                            placeholderText="Start Date"
                            className={` border  text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full ${calenderWarning ? "border-btn-pink" : "border-btn-primary"}`}
                            selected={travelStartDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) =>{
                                setCalenderWarning(false)
                                setTravelStartDate(date);
                            }}
                        />
                        <div className="my-2">Travel End Date</div>
                        <ReactDatePicker
                            title="End Date"
                            placeholderText="End Date"
                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                            selected={travelEndDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                                setCalenderWarning(false)
                                setTravelEndDate(date)}}
                        />
                    </div>
                    <div>
                        <div className="font-bold text-xl">Bill To:</div>
                        <div className="py-2 flex flex-col gap-4">
                            <div className="flex  flex-col space-y-1  ">
                                <div><p className="text-[12px] ">Paid BY :</p></div>
                                <div> <InputComponent
                                name={"orgName"}
                                inputValue={orgName}
                                setInputValue={(val) => setOrgName(val)}
                                warningView={false}
                                placeholder="Name"
                                required={true}
                            />
                                </div>

                            </div>

                            <div className="flex  flex-col space-y-1  ">
                                <div><p className="text-[12px] ">Customer Phone number :</p></div>
                                <div> <InputComponent
                                name={"orgPhone"}
                                inputValue={orgPhone}
                                setInputValue={(val) => {
                                    setOrgPhone(val);
                                }}
                                warningView={false}
                                required={true}
                                placeholder="Phone Number"
                                />
                                </div>

                            </div>

                            <div className="flex  flex-col space-y-1  ">
                                <div><p className="text-[12px] ">Customer Email :</p></div>
                                <div> <InputComponent
                                 name={"orgEmail"}
                                 inputValue={orgEmail}
                                 setInputValue={(val) => setOrgEmail(val)}
                                 warningView={false}
                                 required={true}
                                 placeholder="Email"
                                />
                                </div>

                            </div>

                            <div className="flex  flex-col space-y-1  ">
                                <div><p className="text-[12px] ">Customer Address :</p></div>
                                <div><InputComponent
                                name={"address"}
                                inputValue={address}
                                setInputValue={(val) => setaddress(val)}
                                warningView={false}
                                required={true}
                                placeholder="Address"
                            />
                                </div>

                            </div>




                            {/* <InputComponent
                                name={"orgEmail"}
                                inputValue={orgEmail}
                                setInputValue={(val) => setOrgEmail(val)}
                                warningView={false}
                                placeholder="Email"
                            />
                            <InputComponent
                                name={"address"}
                                inputValue={address}
                                setInputValue={(val) => setaddress(val)}
                                warningView={false}
                                placeholder="Address"
                            /> */}
                            {/* <ReactQuill
                                theme="snow"
                                defaultValue={""}
                                value={address}
                                onChange={setaddress}
                                placeholder={"Adress..."}
                            /> */}
                        </div>

                    </div>




                    {/* <div>
                        <div className=" text-base mb-2">
                            Reservation Number
                        </div>
                        <InputComponent
                            name={"reservation"}
                            warningView={false}
                            inputValue={reservation}
                            setInputValue={(val) => {
                                setReservation(val);
                            }}
                        />
                    </div> */}

                    <div>
                        <div className="font-bold text-base">Services:</div>
                        <div className="text-txt-primary font-extralight py-2">
                            {items.map((item, index) => (
                                <div
                                    key={item.description + index}
                                    className={`p-4 flex items-center gap-2 justify-between my-2 border  rounded-lg `}
                                >
                                    <div className="w-[300px]">
                                        {item.description}
                                        <span className="ml-2">
                                            {item.price} x {item.qty} =
                                            {item.totalPrice}
                                        </span>
                                    </div>
                                    <div>
                                        <button
                                            className="shadow px-4 py-2 rounded-lg hover:bg-bg-semiwhite active:scale-95"
                                            onClick={() => {
                                                setMode("edit");
                                                setSelectedItem(item);
                                                setShowModal(true);
                                            }}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="shadow ml-2 text-[red] px-4 py-2 rounded-lg hover:bg-bg-semiwhite active:scale-95"
                                            onClick={() => {
                                                setItems(
                                                    items.filter(
                                                        (el) =>
                                                            el.id !== item.id
                                                    )
                                                );
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                            {items.length === 0 ? (
                                <div className={`border   rounded-lg py-2 px-4 my-2 ${serviceWarning ? "border-[#FE9A9A]" : "border-[#e2e2e2]"}`}>
                                    No services
                                </div>
                            ) : null}
                            <div className="flex justify-end">
                                <button
                                    className="shadow px-4 py-2 rounded-lg hover:bg-bg-semiwhite active:scale-95 "
                                    onClick={() => {
                                        setMode("create");
                                        setSelectedItem(demoItem);
                                        setShowModal(true);
                                    }}
                                >
                                    Add new
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                    <div className="font-bold text-xl">Bill Details:</div>
                    <div className="flex  flex-col space-y-1  ">
                                <div><p className="text-[12px] ">Total Amount :</p></div>
                                <div> <InputComponent
                                name={"totalAmount"}
                                inputValue={subTotal}
                                required={true}
                                setInputValue={(val) => {
                                    setSubtotal(val)
                                    setPayable(parseInt(val)-discount);
                                }}
                                warningView={false}
                                placeholder="Total amount"
                                isReadOnly={true}
                            />

                                </div>

                            </div>
                    </div>
                    <div>
                        <div className="text-base mb-2">Discount</div>
                        <div className="flex gap-2 items-center">
                            <InputComponent
                                name={"discount"}
                                warningView={false}
                                inputValue={discount}
                                setInputValue={(val) => {
                                    // setDiscount(val);
                                    handleDiscount("amount", val);
                                }}
                                addSpan={true}
                                spanValue="BDT"
                            />
                            or
                            <InputComponent
                                name={"discountPercent"}
                                warningView={false}
                                inputValue={discountPercent}
                                setInputValue={(val) => {
                                    // setDiscountPercent(val);
                                    handleDiscount("percent", val);
                                }}
                                addSpan={true}
                                spanValue="%"
                            />
                        </div>
                    </div>

                    <div className="w-full">
                        <div className=" text-base mb-2">Total payable </div>
                        <div className="border w-full ">{subTotal - discount} BDT</div>
                    </div>

                    <div>

                       <div className="w-full flex flex-col items-end">
                       <div className=" text-base mt-8">Invoice Signature</div>
                        <div className="w-[10rem]">
                        <InputComponent
                            name={"sign"}
                            required={true}
                            warningView={false}
                            inputValue={signature}
                            setInputValue={(val) => {
                                setSignature(val);
                            }}
                        />
                        </div>
                        </div>
                        <hr className=" mt-1" />

                    </div>


                    <div className="flex justify-center gap-2 ">
                        {saved ? (
                            <button
                                className="bg-btn-primary hover:bg-btn-secondary px-4 py-1 rounded-lg text-bg-primary w-56"
                                onClick={() => printDiv("printable")}
                            >
                                Export
                            </button>
                        ) : null}


                        {!saved ? (
                            <button
                            disabled={clicked === true}
                                className={`${clicked ? "bg-btn-warning hover:bg-btn-warning" : "bg-btn-primary hover:bg-btn-secondary"} bg-btn-primary  px-4 py-1 rounded-lg mb-[20px] text-bg-primary w-56`}
                                onClick={() =>{
                                    setClicked(true)
                                    const temp= isNoService();
                                    const validated = isDateValidate();

                                    if (CustomValidation()) {
                                        if(temp){
                                            toastMessage("Please add Services", "error");
                                            setServiceWarning(true);
                                            setClicked(false);
                                        }
                                        else if(!validated){
                                            setCalenderWarning(true);
                                            toastMessage("The start date of travel cannot be later or equal to the end date.", "error");
                                            setClicked(false);
                                        }
                                        else
                                          {  SaveInvoice();
                                            setCalenderWarning(false);
                                            setServiceWarning(false);}
                                    } else {
                                        console.log(isNoService(),'check 2');
                                        toastMessage("Please fill up all required fields!", "error");
                                    }
                                 }}
                            >
                               {clicked ? "Please Wait..." : "Save"}
                            </button>
                        ) : null}
                    </div>
                </div>

                 {/* review data */}
                <div
                    style={{ fontSize: "12px", lineHeight: "16px" }}
                    className="w-2/3 py-6"
                >
                    <div
                        className="border px-12 py-20 w-full bg-bg-white"
                        id="printable"
                    >
                        <div className="flex justify-between items-center">

                            <div className="w-1/2 ">
                                <div>
                                    <img src="/logo.svg" alt="logo" />
                                </div>
                            </div>
                            <div className="text-xl font-black uppercase tracking-[0.5rem]">
                                INVOICE
                            </div>
                        </div>
                        <hr className="my-2" />

                        <div className=" mt-4 flex flex-col gap-8">
                            <div className="flex justify-between gap-2">
                                <div className="w-full">
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Invoice NR:
                                        </div>
                                        <div className=""> {invoiceNr} </div>
                                    </div>
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Booking Code:
                                        </div>
                                        <div className="">
                                        {bookingCode && bookingCode}
                                        </div>
                                    </div>
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Date:
                                        </div>
                                        <div className="">
                                            {format(
                                                new Date(invoiceDate),
                                                "d/M/yyyy"
                                            )}
                                        </div>
                                    </div>
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Due Date:
                                        </div>
                                        <div className="">
                                            {format(
                                                new Date(dueDate),
                                                "d/M/yyyy"
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-1/2">
                                    <div className="flex gap-2 items-end">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Reservation no.:
                                        </div>
                                        <div className=""> {reservation} </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className="flex justify-between gap-2 text-sm">
                                <div className="w-1/2 border p-2">
                                    <div className="uppercase font-bold tracking-widest text-btn-primary">
                                        Bill to:
                                    </div>
                                    <div className="py-4 space-y-[5px]">
                                        <div>Customer Name : {orgName} </div>
                                        <div>
                                            Address : {address}
                                        </div>
                                        <div>
                                            Phone : {orgPhone}
                                        </div>
                                        <div>
                                            Email : {orgEmail}
                                        </div>
                                    </div>
                                    {/* <div className="">
                                        <div>  </div>
                                        <div>
                                            {address.length > 0
                                                ? "Address:"
                                                : null}{" "}
                                            {address}{" "}
                                        </div>
                                        <div>
                                            {orgPhone.length > 0
                                                ? "Phone:"
                                                : null}{" "}
                                            {orgPhone}{" "}
                                        </div>
                                        <div>
                                            {orgEmail.length > 0
                                                ? "Email:"
                                                : null}{" "}
                                            {orgEmail}{" "}
                                        </div>
                                    </div> */}
                                </div>
                                <div className="w-1/2 flex justify-center p-2 ">
                                    <div>
                                        <div className="uppercase font-bold tracking-widest  text-btn-primary">
                                            Payable to:
                                        </div>
                                        <div className="py-4 tracking-wider">
                                            Ghuddy Limited <br />
                                            Rahman Villa, Ka-18, Baridhara North Road
                                            <br />
                                            Gulshan, Dhaka-1212, Bangladesh
                                            <br />
                                            +880 1797078379
                                            <br />
                                            support@ghuddy.com
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='p-2'>
                            <div className="uppercase font-bold tracking-widest text-btn-primary text-sm">
                                        Order details:
                                    </div>
                                <table className="w-full">
                                    <thead>
                                        <tr>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                No.
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Description
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Price
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Qty.
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Total Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {items.map((item, index) => (
                                            <tr key={index} className="border">
                                                <td className="border p-2 text-center">
                                                    {index + 1}.
                                                </td>
                                                <td className="border p-2 text-start">
                                                    {item.description}
                                                </td>
                                                <td className="border p-2 text-center">
                                                    {item.price}
                                                </td>
                                                <td className="border p-2 text-center">
                                                    {item.qty}
                                                </td>
                                                <td className="border p-2 text-end px-4">
                                                    {item.totalPrice}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="flex justify-between gap-2">
                                <div>*All price VAT inclusive</div>
                                <div className="w-1/2">
                                    <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                        <div className="lowercase tracking-wider">
                                            subtotal:
                                        </div>
                                        <div className="text-end px-4">
                                            {subTotal}
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                        <div className="lowercase tracking-wider">
                                            discount:
                                        </div>
                                        <div className="text-end px-4">
                                            {discount}
                                        </div>
                                    </div>
                                    {/* <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                        <div className="capitalize tracking-wider">
                                            paid:
                                        </div>
                                        <div className="text-end px-4">
                                            {parseFloat(paidAmount).toFixed(2)}
                                        </div>
                                    </div> */}
                                    <div className="flex justify-between border-b py-2 font-bold uppercase tracking-wider">
                                        <div className="">
                                            total payable amount:
                                        </div>
                                        <div className="text-end px-4">
                                            {invoiceTotal}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between gap-2">
                                <div className="w-1/2">
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            Account Name:
                                        </div>
                                        <div className=""> Ghuddy Limited </div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            bank name:
                                        </div>
                                        <div className="">Brac bank</div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            account:
                                        </div>
                                        <div className="">2301204588732001</div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            branch:
                                        </div>
                                        <div className="">Jamalpur branch</div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            Bkash merchant:
                                        </div>
                                        <div className="">+8801797078379</div>
                                    </div>
                                </div>
                                <div className="w-1/2 text-center flex flex-col justify-end">
                                    <div className="py-2"> {signature} </div>
                                    <div className="px-2 border-t py-2 ">
                                        <div className="font-bold uppercase tracking-wider">
                                            Thank you for your purchase
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ReactModal
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <div className="min-h-[200px] mx-auto min-w-[800px] py-8 break-words relative">
                    <div
                        onClick={() => {
                            setShowModal(false);
                        }}
                        className="cursor-pointer absolute right-8 top-4"
                    >
                        <SvgCross />
                    </div>

                    <div className="flex flex-col gap-4">
                        <div className="text-xl text-center">
                            {mode === "create" ? "Add" : "Edit"} Item
                        </div>
                        <InputComponent
                            name={"desc"}
                            warningView={false}
                            inputValue={selectedItem.description}
                            setInputValue={(val) => {
                                setSelectedItem({
                                    ...selectedItem,
                                    description: val,
                                });
                            }}
                            placeholder="Item description"
                        />
                        <InputComponent
                            name={"qty"}
                            warningView={false}
                            inputValue={selectedItem.qty}
                            setInputValue={(val) => {
                                setSelectedItem({
                                    ...selectedItem,
                                    qty: val,
                                });
                            }}
                            placeholder="Item quantity"
                        />
                        <InputComponent
                            name={"price"}
                            warningView={false}
                            inputValue={selectedItem.price}
                            setInputValue={(val) => {
                                setSelectedItem({
                                    ...selectedItem,
                                    price: val,
                                });
                            }}
                            placeholder="Item price"
                        />
                        <div className="flex justify-center">
                            <ButtonFilled
                                title={mode === "create" ? "Add" : "Edit"}
                                w="200px"
                                arrow={"no"}
                                buttonFilledHandle={() => {
                                    if (mode === "create") {
                                        setItems([...items, selectedItem]);
                                    } else {
                                        const index = items.findIndex(
                                            (el) => el.id === selectedItem.id
                                        );
                                        if (index > -1) {
                                            setItems(
                                                items.map((item, subIndex) => {
                                                    if (index === subIndex) {
                                                        return {
                                                            ...selectedItem,
                                                        };
                                                    } else {
                                                        return { ...item };
                                                    }
                                                })
                                            );
                                        }
                                    }
                                    setShowModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ReactModal>
        </div>
    )
}
export default AdminCreateInvoice;
