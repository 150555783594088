import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import ButtonFilled from '../../../components/ButtonFilled';
import { apiEndPoint, baseUrl } from '../../../contants/Endpoints';
import { authAxios } from '../../../utils/axiosWrapper';

const AdminPriceFilterList = (props) => {
    const {filterList,deleteState,setDeleteState} = props;
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedId,setSelectedId] = useState(null);
    const handleDelete = (id)=>{
        console.log(id)
        authAxios.delete(`${baseUrl}${apiEndPoint.ADMIN_DELETE_FILTER_BY_ID}${id}?requestId=delete`)
        .then((res)=>{
            console.log(res);
            toast.success("Filter deleted successfully!");
            // setTimeout(()=>{
            //     window.location.reload();
            // },800)
        })
        .then(()=>{
            setSelectedId(null);
            setDeleteState(!deleteState);
            setShowConfirmModal(false)
        })
    }

    return (
        <div>

        {filterList.length> 0 ?
        (<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-sm text-gray-700 uppercase bg-bg-primaryShadow dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Filter Id
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Policy Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Policy Type
                        </th>
                       
                        <th scope="col" className="px-6 py-3">
                            Start Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                            End Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Rack Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Corporate Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Ghuddy Commission
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Shurjo Commission
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Red Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                        Ghuddy Subsidy
                        </th>
                        <th scope="col" className="px-6 py-3">
                        Ghuddy Total discount
                        </th>
                        <th scope="col" className="px-6 py-3">
                        Total Ghuddy Commission
                        </th>
                        <th scope="col" className="px-6 py-3">
                        Total Shurjo Commission
                        </th>
                        <th scope="col" className="px-6 py-3">
                        Delete
                        </th>
                    </tr>
                </thead>
                <tbody>
                {filterList.length > 0 &&
                filterList.map((filter)=>
                    <tr  key= {filter.filterId}  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-btn-secondary dark:hover:bg-gray-600 hover:text-bg-primary">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap dark:text-white">
                            {filter.filterId}
                        </th>
                        <td className="px-6 py-4">
                            {filter.policyName}
                        </td>
                        <td className="px-6 py-4">
                            {filter.policyType}
                            (
                            {filter?.policyType === 'WEEKEND' &&
                            filter.days.map((day)=><div key={day}>{day},</div>)


                            })
                        </td>
                        
                        <td className="px-6 py-4 min-w-[150px] ">
                            {filter.startDate}
                        </td>
                        <td className="px-6 py-4 min-w-[150px] ">
                        {filter.endDate}
                        </td>
                        <td className="px-6 py-4">
                            {filter.deltaRackPrice}
                        </td>
                        <td className="px-6 py-4">
                        {filter.deltaCorporatePrice}
                        </td>
                        
                        <td className="px-6 py-4">
                        {filter.deltaGhuddyCommissionAmount}
                        </td>

                        <td className="px-6 py-4">
                        {filter.deltaShurjoCommissionAmount}
                        </td>
                        <td className="px-6 py-4">
                        {filter.deltaGhuddyWebSiteRedPrice
}
                        </td>
                        
                        <td className="px-6 py-4">
                        {filter.deltaGhuddySubsidyAmount?.toFixed(3)}
                        </td>
                        <td className="px-6 py-4">
                        {filter.deltaTotalDiscountPercentPoint?.toFixed(3)}
                        </td>
                        <td className="px-6 py-4">
                        {filter.deltaTotalGhuddyCommissionAmount?.toFixed(3)}
                        </td>
                        <td className="px-6 py-4">
                        {filter.deltaTotalShurjoCommissionAmount?.toFixed(3)}
                        </td>
                        <td className="px-6 py-4 ">
                            <span
                             onClick={()=>{
                                setShowConfirmModal(true)
                                setSelectedId(filter.filterId)

                            }} 
                            className=" cursor-pointer border  px-3 py-1 font-medium text-[#ff0000] hover:underline">Delete</span>
                        </td>
                    </tr>
        
        )
        }


                            
                    
                </tbody>
            </table>

            <ReactModal
            style={{ content: { width: "auto" } }}
            isOpen={showConfirmModal}
            onRequestClose={() => {
            setShowConfirmModal(false);
            setSelectedId(false);
            }}
        >
        <div className="px-10 py-8 ">
          <div className="text-center text-2xl mb-4">
            To proceed with deletion, please click Confirm button!
          </div>
        
          <div className="flex justify-center gap-2 mt-8">
            <ButtonFilled
              title={"Confirm Delete"}
              w="250px"
              arrow={"no"}
              buttonFilledHandle={() => {
                handleDelete(selectedId)
              }}
            />
          </div>
        </div>
      </ReactModal>
        </div>)
        :
        <div className='p-10 w-full text-center' >No Active Filter has been set yet! </div>
        }





          
            
        </div>
    );
};

export default AdminPriceFilterList;