import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import ReactModal from 'react-modal';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import ButtonFilled from '../../../components/ButtonFilled';
import InputComponent from '../../../components/InputComponent';
import SingleSelectParentReturnObject from '../../../components/singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject';
import { adminRoute } from '../../../contants/Constants';
import { apiEndPoint, baseUrl } from '../../../contants/Endpoints';
import { authAxios } from '../../../utils/axiosWrapper';
import CustomValidation from '../../../utils/customValidation';
import { toastMessage } from '../../../utils/toast';
import AdminPaymentSlipPrinted from './AdminPaymentSlipPrinted';

const AdminCreatePaymentSlip = () => {
    const [customerName,setCustomerName] = useState('');
    const [externalReference,setExternalReference] =  useState('');
    const [fee,setFee] = useState(0);
    const [invoiceId,setInvoiceId] = useState('');
    const [paidAmount,setPaidAmount] = useState();
    const [paymentMethodId,setPaymentMethodId] = useState(0);
    const [phoneNo,setPhoneNo] = useState('');
    const [receivedAmount,setReceivedAmount] = useState();
    const [transactionId,setTransactionId] = useState(null);
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [allMethod,setAllMethod] = useState([]);
    const [selectedMethod,setSelectedMethod] = useState('');
    const [showConfirmModal,setShowConfirmModal] = useState(false);
    const [saved, setSaved] = useState(false);
    // const [originalContents,setoriginalContents] = useState();

    const [receiptNr,setReceiptNr] = useState('');

    const [invoiceDetails,setInvoiceDetails] =useState([]);
    const {id} =useParams();
    const navigate = useNavigate();

    useEffect(()=>{
      authAxios.get(`${baseUrl}${apiEndPoint.ADMIN_GET_ALL_PAYMENT_METHOD}`)
      .then((res)=>{
        setAllMethod(res.data.paymentMethods);
      })
    },[])

    const printDiv =(divName)=>{
      const printDiv =  document.getElementById(divName);
      document.body.style.visibility = "hidden";
      document.getElementById(divName).style.visibility = "visible";
      document.getElementById(divName).style.position = "absolute";
      document.getElementById(divName).style.left = "0";
      document.getElementById(divName).style.top = "0";
      window.print();
      navigateFunc();
      
      // var divContents = document.getElementById(divName).innerHTML;
      //       var a = window.open('', '', 'height=500, width=500');
      //       a.document.write('<html>');
      //       a.document.write('<body > <h1>Div contents are <br>');
      //       a.document.write(divContents);
      //       a.document.write('</body></html>');
      //       a.document.close();
      //       a.print();
    }


    // const printDiv = (divName) => {
    //   var printContents = document.getElementById(divName).innerHTML;
    //   var originalContents = document.body.innerHTML;
    //   document.body.innerHTML = printContents;
    //   window.print();
    //   document.body.innerHTML = originalContents;
    //   navigateFunc()     
      
    // };
  const navigateFunc =()=>{
    document.body.style.visibility = "visible";
    navigate(`${adminRoute.ADMIN_ALL_INVOICE_RECORDS}`);
  }
  
  

    const adminCreatePaymentSlip = ()=>{
      // var originalContentstemp = document.body.innerHTML;
      // setoriginalContents(originalContentstemp);
      const slipData = {
          currency: "BDT",
          externalReference,
          fee,
          invoiceId:id,
          name:customerName,
          paidAmount,
          paymentDate: paymentDate.toISOString(),
          phoneNo : invoiceDetails?.phoneNumber,
          receivedAmount,
          slipNr:null,
          transactionId,
          paymentMethodId : paymentMethodId?.id
      }

      // console.log(slipData,'test 1')
      authAxios.post(`${baseUrl}${apiEndPoint.ADMIN_CREATE_PAYMENT_SLIP}`,slipData)
      .then((res) => {    
        toast.success("Created successfully!");
        setShowConfirmModal(true);
        setSaved(true);
        setReceiptNr(res.data?.slipNr);
        console.log(res,'test');
    })
    .catch((err) => {});
    }

    //console.log(paymentMethodId,'test 1')
    useEffect(()=>{
      authAxios
      .get(
          `${baseUrl + apiEndPoint.ADMIN_GET_SINGLE_INVOICE_DETAILS}/${id}?requestId=11`
      )
      .then((res) => {
          console.log(res.data,'data res');
         setInvoiceDetails(res.data);
         setCustomerName(invoiceDetails?.customerName);
      })
      .catch((err) => {
          setInvoiceDetails([]);
        
      });
  },[])


    return (
        <div className="px-[16px]  min-h-screen pb-[20px] ">
        <div className="max-w-[1100px] flex flex-col items-center   mx-auto">
          <h2 className="text-center text-[22px] pt-[30px] underline">
            Create Payment Slip
          </h2> 
          <div className='w-full  flex flex-col xl:flex-row xl:gap-4 mt-5 '>
            <div className='w-full xl:w-1/2  px-4 py-3 border rounded-[1rem] w-[300px] lg:w-[600px]'>
              <div className="my-2 flex  justify-center ">
              <span className='w-[180px]  flex items-center'>Payment Date : </span>
              <ReactDatePicker
                              title="Invoice Due Date"
                              placeholderText="Invoice Due Date"
                              className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                              selected={paymentDate}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setPaymentDate(date)}
                          />
              </div>
              <div className="my-2 flex  justify-center ">
              <span className='w-[180px]  flex items-center'>Invoice Nr : </span>
              <InputComponent
                                  name={"invoiceId"}
                                  inputValue={id}
                                  setInputValue={(id) => setInvoiceId(id)}
                                  warningView={false}
                                  placeholder="Invoice Number" 
                                  required
                                  isReadOnly={true}
                                  />
              </div>
              <div className='space-y-[1rem]'> 
                  <InputComponent
                                  name={"CustomerName"}
                                  inputValue={invoiceDetails?.customerName}
                                  //setInputValue={(val) => setCustomerName(invoiceDetails?.customerName)}
                                  warningView={false}
                                  placeholder="Customer Name" 
                                  required
                                  isReadOnly={true}
                                  />
                  
                  <InputComponent
                                  name={"CustomerNumber"}
                                  inputValue={invoiceDetails?.phoneNumber}
                                  setInputValue={(val) => setPhoneNo(invoiceDetails?.phoneNumber)}
                                  warningView={false}
                                  placeholder="Phone Number" 
                                  required
                                  isReadOnly={true}
                                  />

                  <InputComponent
                                  name={"ExternalReference"}
                                  inputValue={externalReference}
                                  setInputValue={(val) => setExternalReference(val)}
                                  warningView={false}
                                  placeholder="External reference" 
                                  required
                                  />
                  <InputComponent
                                  name={"TransactionId"}
                                  inputValue={transactionId}
                                  setInputValue={(val) => setTransactionId(val)}
                                  warningView={false}
                                  placeholder="Transaction Id" 
                                  isReadOnly={true}
                                  />

                  <InputComponent
                                  name={"PaidAmount"}
                                  inputValue={paidAmount}
                                  setInputValue={(val) => setPaidAmount(val)}
                                  warningView={false}
                                  placeholder="Paid amount" 
                                  required
                                  />
                  <InputComponent
                                  name={"ReceivedAmount"}
                                  inputValue={receivedAmount}
                                  setInputValue={(val) => setReceivedAmount(val)}
                                  warningView={false}
                                  placeholder="Received amount" 
                                  required
                                  />
                  <InputComponent
                                  name={"Fee"}
                                  inputValue={fee}
                                  setInputValue={(val) => setFee(val)}
                                  warningView={false}
                                  placeholder="Fee" 
                                  //required
                                  />


                  <div>
                  <SingleSelectParentReturnObject
                                              // required={true}
                                              selectedValue={
                                                  selectedMethod
                                              }
                                              setSelectedValue={
                                                  setSelectedMethod
                                              }
                                              data={allMethod}
                                              placeholder="Method Type"
                                              displayField={"methodName"}
                                              selectedFieldName={"methodName"}
                                              setSelectedObject={
                                                  setPaymentMethodId
                                              }
                                              // isAdmin={
                                              //     process.env
                                              //         .REACT_APP_USER_TYPE ===
                                              //     UserType.ADMIN
                                              //         ? true
                                              //         : false
                                              // }
                                              isReadOnly={false}
                                              // rejectionArray={propertyRejectArray}
                                              // setRejectionArray={
                                              //     setPropertyRejectArray
                                              // }
                                              name="methodSelected"
                                          />
                                        
                                        


                  </div>

                  {/* <div>
                  {allMethod.length>0 && 
                <SingleSelectFromSimpleArray  
                                required={true}
                                data={allMethod}
                                selectedValue={paymentMethodId}
                                setSelectedValue={
                                  setPaymentMethodId
                                }
                                placeholder="Method Type"
                                isAdmin={false}
                                isReadOnly={false}
                                // rejectionArray={
                                //     addressRejectArray
                                // }
                                // setRejectionArray={
                                //     setAddressRejectArray
                                // }
                                name="MethodSelector"
                            />}
                  </div> */}




                                          
              </div>

              <div className='mt-[20px]'>
              <button  className="bg-btn-primary hover:bg-btn-secondary px-4 py-1 rounded-lg text-bg-primary w-56"  
                            onClick={() =>{ 
                              //adminCreatePaymentSlip();     
                                      if (CustomValidation()) {
                                        adminCreatePaymentSlip();
                                              
                                      } else {
                                        
                                          toastMessage("Please fill up all required fields!", "error");
                                      }
                                  }}  
                                            
                                              w="100%"
                                              h="49px" >Submit </button>

              </div>
                
            


            </div>

          <div className='w-full xl:w-1/2 shadow '>
            <AdminPaymentSlipPrinted 
            receiptNr={receiptNr}
            paymentDate={paymentDate}
            externalReference={externalReference}
            invoiceDetails={invoiceDetails}
            paidAmount={paidAmount}
            receivedAmount={receivedAmount}
            fee={fee}
            />
          {/* <div
            id="printable"
            className=' bg-[#FFFFFF] pb-[30px] w-full flex flex-col justify-center'>
           
                            
                            <div className="p-4 flex justify-between w-full">
                                <div>
                                    <img src="/logo.svg" alt="logo" />
                                </div>
                                <div className="text-xl font-black uppercase tracking-[0.5rem]">
                                Cash Receipt
                                </div>
                            </div>
                        <hr className="my-2" />

                        <div className='p-4'>
                          <div className='flex justify-between my-4'>
                            <div>Receipt Nr. {receiptNr && receiptNr}</div>
                            <div> Payment date :{format(
                                                new Date(paymentDate),
                                                "d/M/yyyy"
                                            )}</div>

                          </div>

                          <div className='mt-[40px] flex flex-col space-y-[5px]'>
                            <span>Received from : {invoiceDetails?.customerName} </span>
                            <span>Phone number : {invoiceDetails?.phoneNumber} </span>
                            <span>External Reference : {externalReference}</span>
                            <span>Payment amount details:</span>

                          </div>

                          
                          <div className='flex   text-[16px] '>
                            <div className=' flex flex-col items-start w-[200px]'>
                              <span className='border w-full pl-[5px]'> Paid Amount</span>
                              <span className='border w-full  pl-[5px]'> Received Amount</span>
                              <span className='border w-full  pl-[5px]'> Fee</span>
                            </div>
                            <div className=' flex flex-col items-start w-[200px]'>
                            <span className='border w-full pl-[5px]'> {paidAmount? paidAmount : "0"}</span>
                              <span className='border w-full  pl-[5px]'> {receivedAmount? receivedAmount : "0"} </span>
                              <span className='border w-full  pl-[5px]'> {fee} </span>
                            </div>
                          </div>

                        </div>

                        <div className='w-full flex justify-end mt-[50px]'>
                        <div className="p-4 tracking-wider text-[12px]">
                                            Ghuddy Limited <br />
                                            Rahman Villa, Ka-18, Baridhara North Road
                                            <br />
                                            Gulshan, Dhaka-1212, Bangladesh
                                            <br />
                                            +880 1797078379
                                            <br />
                                            support@ghuddy.com
                                            <br />
                                        </div>

                        </div>

                        <div className="text-[12px] text-center flex flex-col justify-end mt-[20px]">
                                    <div className="py-2"> Ghuddy </div>
                                    <div className="px-2 border-t py-2 ">
                                        <div className="font-bold uppercase tracking-wider">
                                            Thank you for your purchase
                                        </div>
                                    </div>
                                </div>

            </div> */}
            {saved ? (
                            <button
                                className=" w-full bg-btn-primary hover:bg-btn-secondary px-4 py-2 rounded-lg text-bg-primary "
                                onClick={() => printDiv("printable")}
                            >
                                Export
                            </button>
                        ) : 
                        <button
                                className=" w-full  bg-btn-warning  px-4 py-2 rounded-lg text-bg-primary "
                            >
                              Submit complete receipt to Export
                            </button>}

          </div>

           

          </div>
         


          </div>
          <ReactModal
                style={{ content: { width: "auto" } }}
                isOpen={showConfirmModal}
                onRequestClose={() => {
                   setShowConfirmModal(false);
                }}
            >
                <div className="px-10 py-8 ">
                    <div className="text-center text-2xl mb-4">
                        Payment slip Created successfully. Do you want to download it now?
                    </div>
                    
                    
                    <div className="flex justify-center gap-2 mt-8">
                        
                        <ButtonFilled
                            title={"Download now"}
                            w="250px"
                            arrow={"no"}
                            buttonFilledHandle={() => {
                              setShowConfirmModal(false);
                              printDiv("printable");
                            }}
                        />
                        <ButtonFilled
                            title={"Skip"}
                            w="150px"
                            arrow={"no"}
                            buttonFilledHandle={() => {
                              setShowConfirmModal(false);
                              navigate(`${adminRoute.ADMIN_ALL_INVOICE_RECORDS}`)
                            }}
                        />
                    </div>
                </div>
            </ReactModal>

          
            
        </div>
    );
};

export default AdminCreatePaymentSlip;