import React, { useEffect, useState } from "react";
import CurrentTimeDateShow from "../comp/CurrentTimeDateShow.js";
import TopShortcutItem from "../comp/TopShortcutItem";
import CheckInCheckOutShortcutTable from "../comp/CheckInCheckOutShortcutTable";
import ComplainShortcutTable from "../comp/ComplainShortcutTable";
import ActionLogShortcutTable from "../comp/ActionLogShortcutTable";
import SliderCardWithDetails from "../SliderCardWithDetails";
import { useDispatch, useSelector } from "react-redux";
import { hotelsInfo } from "../../../store/admin/hotel/api/adminContractApi";
import RoundedDropdownButton from "../../../components/common/RoundedDropdownButton";
import { authAxios } from "../../../utils/axiosWrapper.js";
import { apiEndPoint } from "../../../contants/Endpoints.js";

function MerchantDashboard({}) {
    const dispatch = useDispatch();

    const hotelList = useSelector(
        (state) => state.hotelsInfoGetReducer.data.propertyDataList
    );
    const [selectedHotelId, setSelectedHotelId] = useState(null);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [arrivals, setArrivals] = useState([]);
    const [properties, setProperties] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [issues, setIssues] = useState([]);

    const changeHotel = (index) => {
        setSelectedHotelId(index);
    };

    useEffect(() => {
        if (hotelList && hotelList.length > 0) {
            setSelectedHotel(hotelList.find((el) => el.id === selectedHotelId));
        }
        //TODO: CALL API TO GET SELECTED HOTEL DETAILS AND DATA
    }, [selectedHotelId]);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(hotelsInfo("USER", 0));
        authAxios
            .get(
                `${apiEndPoint.MERCHANT_DASHBOARD_DATA}?propertyId=${
                    selectedHotel?.id || ""
                }`
            )
            .then((res) => {
                setArrivals(res.data.arrivals);
                setProperties(res.data.properties);
                setBookings(res.data.bookings);
                setIssues(res.data.issues);
            });
    }, [selectedHotel]);

    let actionLogData = [
        {
            name: "Mr. Doe",
            doneBy: "Johney",
            dateAndTime: "2020-01-01",
        },
        {
            name: "Mr. Doe",
            doneBy: "Johney",
            dateAndTime: "2020-01-01",
        },
        {
            name: "Mr. Doe",
            doneBy: "Johney",
            dateAndTime: "2020-01-01",
        },
        {
            name: "Mr. Doe",
            doneBy: "Johney",
            dateAndTime: "2020-01-01",
        },
        {
            name: "Mr. Doe",
            doneBy: "Johney",
            dateAndTime: "2020-01-01",
        },
        {
            name: "Mr. Doe",
            doneBy: "Johney",
            dateAndTime: "2020-01-01",
        },
    ];
    let data = [
        {
            property: "Superstar Inc. Plaza",
            name: "Luiso Somantila De Vito Gucci",
            time: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            name: "Luiso Somantila De Vito Gucci",
            time: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            name: "Luiso Somantila De Vito Gucci",
            time: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            name: "Luiso Somantila De Vito Gucci",
            time: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            name: "Luiso Somantila De Vito Gucci",
            time: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            name: "Luiso Somantila De Vito Gucci",
            time: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            name: "Luiso Somantila De Vito Gucci",
            time: "11:45 AM",
        },
    ];
    let complainData = [
        {
            property: "Superstar Inc. Plaza",
            customer: "Shoaib Mehedi",
            dateTime: "7 Apr 2022, 11:45 AM",
            complainDetails: "AC is not working",
            bookingDetails: "1 Bedroom, King Bed, 2 Adults, 2 Children",
            status: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            customer: "Shoaib Mehedi",
            dateTime: "7 Apr 2022, 11:45 AM",
            complainDetails: "AC is not working",
            bookingDetails: "1 Bedroom, King Bed, 2 Adults, 2 Children",
            status: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            customer: "Shoaib Mehedi",
            dateTime: "7 Apr 2022, 11:45 AM",
            complainDetails: "AC is not working",
            bookingDetails: "1 Bedroom, King Bed, 2 Adults, 2 Children",
            status: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            customer: "Shoaib Mehedi",
            dateTime: "7 Apr 2022, 11:45 AM",
            complainDetails: "AC is not working",
            bookingDetails: "1 Bedroom, King Bed, 2 Adults, 2 Children",
            status: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            customer: "Shoaib Mehedi",
            dateTime: "7 Apr 2022, 11:45 AM",
            complainDetails: "AC is not working",
            bookingDetails: "1 Bedroom, King Bed, 2 Adults, 2 Children",
            status: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            customer: "Shoaib Mehedi",
            dateTime: "7 Apr 2022, 11:45 AM",
            complainDetails: "AC is not working",
            bookingDetails: "1 Bedroom, King Bed, 2 Adults, 2 Children",
            status: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            customer: "Shoaib Mehedi",
            dateTime: "7 Apr 2022, 11:45 AM",
            complainDetails: "AC is not working",
            bookingDetails: "1 Bedroom, King Bed, 2 Adults, 2 Children",
            status: "11:45 AM",
        },
        {
            property: "Superstar Inc. Plaza",
            customer: "Shoaib Mehedi",
            dateTime: "7 Apr 2022, 11:45 AM",
            complainDetails: "AC is not working",
            bookingDetails: "1 Bedroom, King Bed, 2 Adults, 2 Children",
            status: "11:45 AM",
        },
    ];
    // let arivals = [
    //   { lebel: "Check In:", value: "2" },
    //   { lebel: "Check Out:", value: "120" },
    // ];
    // let properties = [
    //   { lebel: "Hotels:", value: "2" },
    //   { lebel: "Rooms:", value: "120" },
    //   { lebel: "Available:", value: "44" },
    //   { lebel: "Booked:", value: "55" },
    // ];
    // let bookings = [
    //   { lebel: "Total:", value: "2" },
    //   { lebel: "Confirmed:", value: "120" },
    //   { lebel: "Pending:", value: "44" },
    //   { lebel: "Cancelled:", value: "55" },
    // ];
    // let issues = [
    //   { lebel: "Total:", value: "2" },
    //   { lebel: "Pending:", value: "120" },
    //   { lebel: "Resolved:", value: "44" },
    // ];

    return (
        <div>
            <div className="px-6 2xl:px-0 2xl:container mx-auto">
                <div className="pt-12 grid grid-cols-3">
                    <div className="col-span-1">
                        <div className="pl-4 pb-2">
                            Select Hotel
                        </div>
                        {hotelList && hotelList.length > 0 && (
                            <RoundedDropdownButton
                                selectHandler={changeHotel}
                                dropdownData={[
                                    { propertyName: "All", id: null },
                                    ...hotelList,
                                ]}
                                title={`${
                                    selectedHotel?.propertyName || "All"
                                }`}
                                w="full"
                                h="49px"
                                selector={"propertyName"}
                            />
                        )}
                    </div>
                </div>

                <p className="text-txt-primary pt-4 pb-6 text-[27px]">
                    Quick Stats
                </p>
                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-2">
                        <CurrentTimeDateShow />
                    </div>
                    <TopShortcutItem title={"Arrivals"} list={arrivals} />{" "}
                    <TopShortcutItem title={"Properties"} list={properties} />{" "}
                    <TopShortcutItem title={"Bookings"} list={bookings} />{" "}
                    <TopShortcutItem title={"Issues"} list={issues} />
                </div>

                <div className="grid grid-flow-col gap-8 mt-8">
                    <div>
                        <p className="text-left text-[27px]">Check In</p>
                        <CheckInCheckOutShortcutTable data={data} />
                    </div>
                    <div>
                        <p className="text-left text-[27px]">Check Out</p>
                        <CheckInCheckOutShortcutTable data={data} />
                    </div>
                </div>
                <p className="text-center text-[27px] mt-8">New Complains</p>
                <ComplainShortcutTable data={complainData} />

                <p className="text-left text-[27px] my-8">Hotel Information</p>
                <SliderCardWithDetails hotelList={hotelList} />

                <p className="text-left text-[27px] mt-8">New Complains</p>
                <ComplainShortcutTable data={complainData} />
                <div className="grid grid-cols-2 gap-8 mt-8">
                    <div>
                        <p className="text-center text-[27px]">Check In</p>
                        <ActionLogShortcutTable data={actionLogData} />
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}

export default MerchantDashboard;
