import React from "react";

const FooterButton = ({ buttonOutlinedHandle, title, h, w }) => {
  // console.log("disable",disable) hover:bg-btn-primary hover:text-bg-white 
  return (
    <button
      style={{ width: w ? w : 50, height: h ? h : 50 }}
      onClick={buttonOutlinedHandle}
      className="w-[130px] h-[33px] border border-btn-primary flex justify-center items-center rounded-[300px] leading-small 
      
      bg-bg-primary text-[12px] text-btn-primary mr-[20px]  active:bg-txt-secondary active:border active:border-btn-primary active:text-btn-primary "
    >
      {title ? title : "Footer"}
      <div className="px-2">
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 5L9 1"
            stroke="#376576"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </button>
  );
};

export default FooterButton;
