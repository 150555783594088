import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DashboardHotelCard from "../../../components/card/DashboardHotelCard";
import HotelCard from "../../../components/card/HotelCard";
import { DashboardRoutes } from "../../../contants/Constants";
import { hotelsInfo } from "../../../store/admin/hotel/api/adminContractApi";

function MerchantDashboardHotels() {
  let navigate = useNavigate();
  const [totalElement, setTotalElement] = useState(0);
  const [listHotel, setListHotel] = useState();

  const response = useSelector((state) => state.hotelsInfoGetReducer);

  console.log(response.data, "resres");
  const dispatch = useDispatch();
//   console.log("setSuperContractId", superContractId);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(hotelsInfo("USER", 0));
  }, []);

  useEffect(() => {
    if (response && response.data && response.data.propertyDataList) {
      console.log("response: ", response.data);
      setTotalElement(response.data.totalElements);
      setListHotel(response.data.propertyDataList);
    //   setLoading(false);
    }
  }, [response]);

  // some helper state

  const handleNewHotel = () => {
    // setPosition("hotel-move");
    // setSelectedHotelId(0);
  };
//   console.log(selectedHotelId, "setSelectedHotelId")
  const buttonOutlinedHandle = () => {
    // setPosition("nothing");
  };
  const buttonFilledHandle = () => {
    // setPosition("add-category-move");
  };

  
  return (
    <div>
      <div className="grid grid-cols-4 gap-0.5 px-4 pt-4 mb-[249px]">
        {listHotel && listHotel.length > 0
          ? listHotel.map((each) => {
            console.log(listHotel, "listHotel")
              return (
                <div
                  className="w-auto h-auto text-lg m-[10px] rounded-[8px] "
                  key={each.id}
                >
                  <DashboardHotelCard
                    hotel={each}
                  />
                </div>
              );
            })
          : null}
          <div
            onClick={handleNewHotel}
            className="w-[315px] h-[92%] cursor-pointer font-medium text-center text-btn-secondary custom-shadow py-[50px] bg-bg-primary text-lg m-[10px] rounded-[16px] "
          >
            <h2 className="py-6">Add Hotel</h2>
            <h1 className="bg-btn-secondary text-bg-white rounded-full ml-[45%] w-[27px] h-[27px]">
              +
            </h1>
          </div>
      </div>
    </div>
  );
}

export default MerchantDashboardHotels;
