import React from 'react';

const DateFormatterSlash = (dateValue) => {
    const inputDate = new Date(dateValue);
    let date, month, year;
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${date}/${month}/${year}`;
};

export default DateFormatterSlash;