import React, { useEffect, useState } from "react";
import RoundedDropdownButton from "../../components/common/RoundedDropdownButton";
import { apiEndPoint, PROPERTY_ENDPOINTS } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import OutsideClickHandler from "react-outside-click-handler";
import InputComponent from "../../components/InputComponent";
import { toastMessage } from "../../utils/toast";
import SingleSelectParentCategoryReturn from "../../components/singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturn";
import ButtonFilled from "../../components/ButtonFilled";
import { DateFormatTranferToSixDigit } from "../../utils/DateFormatTranferToSixDigit";

export default function AdminDealsTool() {
    const [selectedHotelId, setSelectedHotelId] = useState(null);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [hotelList, setHotelList] = useState([]);
    const [title, setTitle] = useState("");
    const [mode, setMode] = useState("view");
    const [discountPercent, setDiscountPercent] = useState(0);

    const [selectedCategories, setSelectedCategories] = React.useState([]);
    const [deals, setDeals] = React.useState([]);
    const [categories, setCategories] = React.useState([]);

    const [dealStartDate, setDealStartDate] = useState();
    const [dealEndDate, setDealEndDate] = useState();

    const [bookingStartDate, setBookingStartDate] = useState();
    const [bookingEndDate, setBookingEndDate] = useState();

    const [showDropDown, setShowDropDown] = useState(false);
    const [featureName, setFeatureName] = useState("");

    const [subFeatures, setSubFeatures] = useState([
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
    ]);

    const handleSubmit = () => {
        const dealObj = {
            title: title,
            requestId: "deal",
            propertyId: selectedHotel.id,
            dealStartDate: DateFormatTranferToSixDigit(dealStartDate),
            dealEndDate: DateFormatTranferToSixDigit(dealEndDate),
            bookingStartDate: DateFormatTranferToSixDigit(bookingStartDate),
            bookingEndDate: DateFormatTranferToSixDigit(bookingEndDate),
            discountPercent: discountPercent,
            features: subFeatures.map((feature) => feature.title),
            categoryIds: selectedCategories.map((item) => item.roomCategoryId),
        };
        //   submit to api
        authAxios
            .post(apiEndPoint.ADMIN_CREATE_DEAL, dealObj)
            .then((res) => {
                console.log(res);
                toastMessage("success", "Deal created successfully");
                //   reset all states
                setTitle("");
                setSelectedHotelId(null);
                setSelectedHotel(null);
                setDealStartDate(null);
                setDealEndDate(null);
                setBookingStartDate(null);
                setBookingEndDate(null);
                setDiscountPercent(0);
                setSubFeatures([
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                ]);
                setSelectedCategories([]);
            })
            .catch((err) => {
                console.log(err);
                toastMessage("error", "Something went wrong");
            });
    };
    const getHotels = () => {
        authAxios
            .get(
                `${apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN}?contractId=-1&requestId=2&pageSize=200`
            )
            .then((res) => {
                setHotelList(res.data.propertyDataList);
                setSelectedHotel(res.data.propertyDataList[0]);
            })
            .catch(() => {
                toastMessage("Error fecthing data", "error");
            });
    };

    useEffect(() => {
        if (hotelList && hotelList.length > 0) {
            setSelectedHotel(() =>
                hotelList.find((el) => el.id === selectedHotelId)
            );
        }
    }, [selectedHotelId]);

    const changeHotel = (id) => {
        setSelectedHotelId(id);
    };

    useEffect(() => {
        getHotels();
    }, []);

    useEffect(() => {
        setSelectedCategories([]);
        setCategories([]);
        if (selectedHotel) {
            getDealsByProperty();
            authAxios
                .get(
                    `${apiEndPoint.ADMIN_GET_CATEGORY_BY_PROPERTY}${selectedHotel.id}/category?requestId=2`
                )
                .then((res) => {
                    setCategories(res.data.roomCategoryList);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [selectedHotel]);

    const AddCategory = (cat) => {
        if (
            selectedCategories.findIndex(
                (el) => el.roomCategoryId === cat.roomCategoryId
            ) < 0
        ) {
            setSelectedCategories([...selectedCategories, cat]);
        }
    };

    const RemoveCategory = (catId) => {
        setSelectedCategories(
            selectedCategories.filter((el) => el.roomCategoryId != catId)
        );
    };

    const getDealsByProperty = () => {
        authAxios
            .get(
                `${apiEndPoint.ADMIN_GET_DEAL_BY_PROPERTY}/${selectedHotel.id}`
            )
            .then((res) => {
                setDeals(res.data.dealData);
            })
            .catch((err) => {});
    };

    const deleteDeal = (id) => {
        authAxios
            .delete(`${apiEndPoint.ADMIN_DELETE_DEAL}/deal/${id}`)
            .then((res) => {
                setDeals(deals.filter((el) => el.id !== id));
            })
            .catch((err) => {});
    };

    return (
        <div className="">
            <div className="text-3xl font-semibold pb-4 border-b border-[#e2e2e2] mb-4">
                Deal Tool
            </div>

            <div className="max-w-[1000px]">
                {hotelList && (
                    <div className="">
                        <SingleSelectParentCategoryReturn
                            warningView={false}
                            selectedValue={selectedHotel}
                            setSelectedValue={setSelectedHotel}
                            data={hotelList}
                            placeholder="Select Hotel"
                            displayField={"propertyName"}
                            selectedFieldName="id"
                        />
                    </div>
                )}

                {mode === "view" ? (
                    <div>
                        <button className="my-2 shadow px-4 py-2" onClick={() => setMode("add")}>
                            Create new
                        </button>
                        <div className="text-xl my-4">Existing Deals</div>
                        <hr/>
                        {deals.map((deal,index) => (
                            <div className="flex gap-2 py-4 items-center">
                                <div className="px-4 text-lg"> {index + 1}. {deal.title}</div>
                                <div>
                                    <button className="px-4 bg-btn-primary text-bg-primary rounded-md" onClick={() => {deleteDeal(deal.id)}}>Delete</button>
                                </div>
                            </div>
                        ))}
                        {deals.length === 0 ?
                        <div className="py-4 text-sm text-[#e2e2e2]">
                          No existing deal.
                        </div>
                        : null}
                    </div>
                ) : null}

                {mode === "add" ? (
                    <div>
                        <button
                            className="my-2 shadow px-4 py-2"
                            onClick={() => {
                                setMode("view");
                            }}
                        >
                            View All
                        </button>
                        <div className="flex gap-4">
                            <div className="w-1/2 py-2">
                                <InputComponent
                                    warningView={false}
                                    name={"title"}
                                    placeholder={"Title"}
                                    inputValue={title}
                                    setInputValue={setTitle}
                                />
                                <div className="pt-4 mt-3">
                                    <InputComponent
                                        warningView={false}
                                        placeholder={"Discount (%)"}
                                        inputValue={discountPercent}
                                        setInputValue={setDiscountPercent}
                                    />
                                </div>
                            </div>
                            <div className="w-1/2 pr-4">
                                <div className="flex gap-2 py-2">
                                    <div className="w-1/2 flex items-center gap-2">
                                        <DatePicker
                                            title="Deal Start Date"
                                            placeholderText="Deal Start Date"
                                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                                            selected={dealStartDate}
                                            onChange={(date) =>
                                                setDealStartDate(date)
                                            }
                                        />
                                    </div>
                                    <div className="w-1/2 flex items-center gap-2">
                                        <DatePicker
                                            placeholderText="Deal end Date"
                                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                                            selected={dealEndDate}
                                            onChange={(date) =>
                                                setDealEndDate(date)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-2 my-3 py-2">
                                    <div className="w-1/2 flex items-center gap-2">
                                        <DatePicker
                                            placeholderText="Booking Start Date"
                                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                                            selected={bookingStartDate}
                                            onChange={(date) =>
                                                setBookingStartDate(date)
                                            }
                                        />
                                    </div>
                                    <div className="w-1/2 flex items-center gap-2">
                                        <DatePicker
                                            placeholderText="Booking end Date"
                                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                                            selected={bookingEndDate}
                                            onChange={(date) =>
                                                setBookingEndDate(date)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex  gap-4"></div>

                        <div className="my-6">
                            <div className="my-2">
                                Add Categories ({categories.length}):{" "}
                            </div>
                            <div className="w-1/2 mb-4">
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        setShowDropDown(false);
                                    }}
                                >
                                    <div className="relative">
                                        <InputComponent
                                            name={"addCat"}
                                            warningView={false}
                                            // placeholder={`Add Categories (${categories.length})`}
                                            inputValue={featureName}
                                            setInputValue={(val) => {
                                                setFeatureName(val);
                                                setShowDropDown(true);
                                            }}
                                            clickHandler={() => {
                                                setShowDropDown(true);
                                            }}
                                            spanValue="+"
                                            addSpan={true}
                                        />
                                        {showDropDown && (
                                            <div className="absolute w-[100%] bg-bg-primary top-12 rounded-xl shadow-xl overflow-auto max-h-[400px] mt-1">
                                                {categories
                                                    .filter((el) =>
                                                        el.categoryName
                                                            .toLowerCase()
                                                            .includes(
                                                                featureName.toLowerCase()
                                                            )
                                                    )
                                                    .map((hotel, index) => (
                                                        <div
                                                            onClick={() => {
                                                                // AddFeature(hotel);
                                                                AddCategory(
                                                                    hotel
                                                                );
                                                                setShowDropDown(
                                                                    false
                                                                );
                                                                setFeatureName(
                                                                    ""
                                                                );
                                                            }}
                                                            key={
                                                                hotel.roomCategoryId
                                                            }
                                                            className="py-1 px-4 cursor-pointer hover:bg-btn-primary hover:text-bg-primary"
                                                        >
                                                            {hotel.categoryName}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </OutsideClickHandler>
                            </div>
                            <div className="flex flex-wrap gap-4">
                                {selectedCategories?.length === 0 && (
                                    <div className="text-txt-hint font-thin ml-10">
                                        No cateogry selected
                                    </div>
                                )}
                                {selectedCategories?.map((cat, index) => (
                                    <div
                                        key={cat.roomCategoryId}
                                        className="px-2 py-1 rounded-md shadow flex items-center gap-1"
                                    >
                                        {cat.categoryName}
                                        <span
                                            onClick={() => {
                                                RemoveCategory(
                                                    cat.roomCategoryId
                                                );
                                            }}
                                            className="cursor-pointer ml-1 border-l pl-2"
                                        >
                                            X{" "}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mt-4 pr-4">
                            Deal Sub-features
                            <div className="my-2">
                                <InputComponent
                                    warningView={false}
                                    name={"cat1"}
                                    placeholder={"1"}
                                    inputValue={subFeatures[0].title}
                                    setInputValue={(val) => {
                                        setSubFeatures(
                                            subFeatures.map((item, index) => {
                                                if (index === 0) {
                                                    return {
                                                        ...item,
                                                        title: val,
                                                    };
                                                } else {
                                                    return { ...item };
                                                }
                                            })
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-2">
                                <InputComponent
                                    warningView={false}
                                    name={"cat2"}
                                    placeholder={"2"}
                                    inputValue={subFeatures[1].title}
                                    setInputValue={(val) => {
                                        setSubFeatures(
                                            subFeatures.map((item, index) => {
                                                if (index === 1) {
                                                    return {
                                                        ...item,
                                                        title: val,
                                                    };
                                                } else {
                                                    return { ...item };
                                                }
                                            })
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-2">
                                <InputComponent
                                    warningView={false}
                                    name={"cat3"}
                                    placeholder={"3"}
                                    inputValue={subFeatures[2].title}
                                    setInputValue={(val) => {
                                        setSubFeatures(
                                            subFeatures.map((item, index) => {
                                                if (index === 2) {
                                                    return {
                                                        ...item,
                                                        title: val,
                                                    };
                                                } else {
                                                    return { ...item };
                                                }
                                            })
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-2">
                                <InputComponent
                                    warningView={false}
                                    name={"cat4"}
                                    placeholder={"4"}
                                    inputValue={subFeatures[3].title}
                                    setInputValue={(val) => {
                                        setSubFeatures(
                                            subFeatures.map((item, index) => {
                                                if (index === 3) {
                                                    return {
                                                        ...item,
                                                        title: val,
                                                    };
                                                } else {
                                                    return { ...item };
                                                }
                                            })
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-2">
                                <InputComponent
                                    warningView={false}
                                    name={"cat5"}
                                    placeholder={"5"}
                                    inputValue={subFeatures[4].title}
                                    setInputValue={(val) => {
                                        setSubFeatures(
                                            subFeatures.map((item, index) => {
                                                if (index === 4) {
                                                    return {
                                                        ...item,
                                                        title: val,
                                                    };
                                                } else {
                                                    return { ...item };
                                                }
                                            })
                                        );
                                    }}
                                />
                            </div>
                        </div>

                        <div className=" flex justify-center mt-4">
                            <div className="w-[450px]">
                                <ButtonFilled
                                    w={"100%"}
                                    buttonFilledHandle={handleSubmit}
                                    title={"Submit"}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
