import { useEffect } from "react";

export function TestComponent() {

    useEffect(()=>{

        const slider = document.querySelector('.divList');
        let isDown = false;
        let startX;
        let scrollLeft;
    
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            console.log(walk);
        });
    },[])

    return (
        <div className="">
            <div className="flex w-[800px] overflow-x-auto divList">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 0, 0, 0, 0, 0, 0, 0].map((item, index) => (
                    <div className="border min-w-[400px] h-[400px]">
                        Item {index + 1}
                    </div>
                ))}
            </div>
        </div>
    )
}