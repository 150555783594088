import React, { useState } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
import "../App.css"

function SingleSelect({ title, setTitle, width }) {
  const [isHidden, setIsHidden] = useState(true);

  const handleClick = (e) => {
    setIsHidden(!isHidden);
  };
  const titleArr = ["Mr", "Mrs"];
  return (
    <ReactOutsideClickHandler
      onOutsideClick={() => {
        setIsHidden(true);
        // alert('You clicked outside of this component!!!');
      }}
    >
      <div className="dropdown relative">
        <button
          onClick={handleClick}
          className="bg-btn-primary text-bg-primary py-3 px-6 rounded-[8px] inline-flex items-cente min-w-full hover:bg-btn-secondary duration-500 w-[100px]"
        >
          <span className="mr-[10px]">{title}</span>
          <div className="py-2">
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L5 5.5L9 1.5"
                stroke="#FFFBEF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
        <ul
          className={
            `text-left dropdown-menu absolute w-full text-gray-700 z-10 custom-shadow py-4 rounded-xl bg-bg-primary mt-2  ` +
            (isHidden ? "hidden" : "")
          }
        >
          {titleArr.map((each) => {
            return (
              <li>
                <a
                  onClick={() => setTitle(each)}
                  onMouseUp={() => handleClick()}
                  className="cursor-pointer text-txt-primary hover:bg-btn-secondary hover:text-txt-secondary py-2 px-4 block whitespace-no-wrap"
                  // href="#"
                >
                  {each}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </ReactOutsideClickHandler>
  );
}

export default SingleSelect;
