
export function DateFormatTranferToSixDigit(date){
    console.log(date);
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join("");
}

export function integerDateToValidDate(date){
    console.log(date);
    const year = date.toString().slice(0,4);
    const month = date.toString().slice(4,6);
    const day = date.toString().slice(6,8);

    return `${year}-${month}-${day}`;
}
