import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';

const Pagenation = ({ Registration }) => {
  const location = useLocation();

  const [paths, setPaths] = useState([]);

  useEffect(() => {
    console.log({ location });
    formatPaths();
  }, [])

  const formatPaths = ()=>{
    let url = '';
    const pathsFromUrl = location.pathname.split('/');
    const formattedPaths = [];
    for (let i = 0; i < pathsFromUrl.length; i++) {
      const path = pathsFromUrl[i];
      if (path) {
        url = url + `/${path}`;
        const obj ={
          path,
          url,
        }
        formattedPaths.push(obj);   
      }
      else{
        const obj ={
          path:"",
          url:"/",
        }
        formattedPaths.push(obj);   
      }
    }

    console.log({formattedPaths});

    setPaths(formattedPaths);
  }

  return (
    <div className="sticky top-20 z-40 bg-bg-primary">
      <div className="flex gap-4 px-6 xl:px-0 xl:container mx-auto py-4 mb-6">
        {paths.map((item, index) => (
          <Fragment key={index}>
            {index < (paths.length - 1) ?
              <Link to={`${item.url}`}>
                <span className={`${"text-txt-hint"} capitalize`}>{item.path || "Home"}</span>
              </Link>
              :
              <span className="capitalize" >{item.path || "Home"}</span>
            }
            {index < (paths.length - 1) &&
              <span className="mt-1">
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.25 1L1.25 7L7.25 13"
                    stroke="#868686"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            }
          </Fragment>
        ))}
        {/* <span>{Registration ? "Registration" : "Merchant Registration"}</span> */}
      </div>
    </div>
  );
};

export default Pagenation;
