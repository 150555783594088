import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { returnS3imageViewUsingUrlCutting } from "../../utils/returnS3imageViewUsingUrlCutting";

export const CarouselSlider = ({ imageList }) => {
    //   const [image, setImage] = useState();
    //   useEffect(() => {
    //     if (imageList.length) {
    //       setImage(String(imageList[0].url).split("?"));
    //     }
    //   }, [imageList]);
    //   console.log(image, "images");
    // let image = imageList[0].url;
    //   const returnS3imageViewUsingUrlCutting = (url) => {
    //     let img = url;
    //     let setImage = String(img).split("?");
    //     console.log(setImage[0], "setImage");
    //     return setImage[0];
    //   };
    return (
        <div>
            <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                showArrows={true}
            >
                {imageList &&
                    imageList.map((i) => {
                        return (
                            <div>
                                <img
                                    src={returnS3imageViewUsingUrlCutting(
                                        i.url
                                    )}
                                    className="w-full h-[397px] rounded-[8px] object-cover"
                                />
                            </div>
                        );
                    })}

                {/* <div>
          <img src="" />
        </div>
        <div>
          <img src="Hotelslider.png" />
        </div>
        <div>
          <img src="Hotelslider.png" />
        </div> */}
            </Carousel>
        </div>
    );
};
