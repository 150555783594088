import React from "react";
import ButtonFilled from "../components/ButtonFilled";
import ButtonOutlined from "../components/ButtonOutlined";
import { UserType } from "../contants/Constants";

export const BookingCard = ({
    onConfirm,
    onReject,
    bookingItem,
    onRefuseReject,
    showControl = true,
    type,
}) => {
    const buttonOutlinedHandle = () => {
        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
            ? onRefuseReject(bookingItem?.itemIndex, bookingItem?.sourceIndex)
            : onReject(bookingItem?.itemIndex, bookingItem?.sourceIndex);
    };
    const buttonFilledHandle = () => {
        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
            ? onReject(bookingItem?.itemIndex, bookingItem?.sourceIndex)
            : onConfirm(bookingItem?.itemIndex, bookingItem?.sourceIndex);
    };

    const parseDate = (dateValue) => {
        const day = dateValue[6].toString() + dateValue[7].toString();
        const month = dateValue[4].toString() + dateValue[5].toString();
        const year = dateValue.slice(0, 4);
        return `${day}  ${month} ${year}`;
    };

    // const gethotel
    return (
        <div>
            {bookingItem?.booking && (
                <div>
                    {type === "regular" && (
                        <React.Fragment>
                            <div className="grid grid-cols-3 gap-4 pb-4">
                                <div className="col-span-1">
                                    <div className="w-full overflow-y-auto h-[200px] bg-bg-primary px-4 py-6 drop-shadow-lg rounded-[18px]">
                                        <div>
                                            <h1 className="py-2">
                                                {bookingItem.booking
                                                    .bookingCategoryData[0]
                                                    .propertyName || "best"}
                                            </h1>
                                            <h3 className="py-2 font-[16px]">
                                                {
                                                    bookingItem.booking
                                                        .bookingCategoryData[0]
                                                        .propertyAddress
                                                }
                                            </h3>
                                        </div>
                                        <div className="flex gap-x-2">
                                            <p className="text-txt-hint">
                                                Categories:{" "}
                                            </p>
                                            <div className=" flex gap-2 w-[150px] flex-wrap">
                                                {bookingItem.booking.bookingCategoryData.map(
                                                    (cat) => (
                                                        <p
                                                            key={
                                                                cat.categoryName
                                                            }
                                                        >
                                                            {cat.categoryName}
                                                        </p>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="w-full h-[200px] bg-bg-primary px-4 py-6 drop-shadow-lg rounded-[18px] flex">
                                        <div className="px-3 text-txt-hint">
                                            <p className="py-2">
                                                Contact Name:
                                            </p>
                                            <p className="py-2">Quantity:</p>
                                            <p className="py-2">Email:</p>
                                            <p className="py-2">Number:</p>
                                        </div>
                                        <div>
                                            <p className="py-2">
                                                {bookingItem.booking.personName
                                                    ? bookingItem.booking
                                                          .personName
                                                    : "N/A"}
                                            </p>
                                            <p className="py-2">
                                                {bookingItem.booking
                                                    .bookingCategoryData
                                                    ?.length || 0}{" "}
                                            </p>
                                            <p className="py-2">
                                                {bookingItem.booking.personEmail
                                                    ? bookingItem.booking
                                                          .personEmail
                                                    : "N/A"}
                                            </p>
                                            <p className="py-2">
                                                {" "}
                                                {bookingItem.booking
                                                    .personNumber
                                                    ? bookingItem.booking
                                                          .personNumber
                                                    : "N/A"}{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="w-full h-[200px] bg-bg-primary px-4 py-6 drop-shadow-lg rounded-[18px] flex">
                                        <div className="px-3 text-txt-hint">
                                            <p className="py-2">Check In:</p>
                                            <p className="py-2">Check Out:</p>
                                            <p className="py-2">Duration:</p>
                                        </div>
                                        <div>
                                            <p className="py-2">
                                                {parseDate(
                                                    bookingItem?.booking?.bookingCategoryData[0].startDate.toString()
                                                )}
                                            </p>
                                            <p className="py-2">
                                                {" "}
                                                {parseDate(
                                                    bookingItem?.booking?.bookingCategoryData[0].endDate.toString()
                                                )}{" "}
                                            </p>
                                            <p className="py-2">
                                                {bookingItem?.booking?.duration}{" "}
                                                nights
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                                <div className="col-span-1">
                                    <div className="w-full h-[200px] bg-bg-primary px-4 py-6 drop-shadow-lg rounded-[18px]">
                                        <h3>Problem Statement</h3>
                                        <p>{bookingItem?.booking?.note}</p>
                                    </div>
                                </div>
                                <div className="col-span-1 whitespace-normal break-words">
                                    {/* {JSON.stringify(bookingItem?.booking)} */}
                                </div>
                                <div className="col-span-1">
                                    <div className="w-full h-[200px] bg-bg-primary px-4 py-6 drop-shadow-lg rounded-[18px] flex">
                                        <div className="px-3 ">
                                            <p className="py-2">
                                                {
                                                    Object.values(
                                                        bookingItem?.booking
                                                            ?.bookingCategoryData[0]
                                                            .amountSignature
                                                            .metaData
                                                    )[0]
                                                }{" "}
                                                {bookingItem?.booking?.currency}{" "}
                                                *{" "}
                                                {bookingItem?.booking?.duration}{" "}
                                                Nights:
                                            </p>
                                            <p className="py-2">
                                                Long Stay Discount:
                                            </p>
                                            <p className="py-2">Service Fee:</p>
                                            <p className="py-2">Total:</p>
                                        </div>
                                        <div>
                                            <p className="py-2">
                                                {bookingItem?.booking?.roomPrice.toFixed(
                                                    2
                                                )}{" "}
                                                {bookingItem?.booking?.currency}
                                            </p>
                                            <p className="py-2">
                                                {" "}
                                                {bookingItem?.booking
                                                    ?.campaignMeta
                                                    ?.discountAmount || 0}{" "}
                                                {bookingItem?.booking?.currency}
                                            </p>
                                            <p className="py-2">
                                                {bookingItem?.booking?.otherServiceAmount.toFixed(
                                                    2
                                                )}{" "}
                                                {bookingItem?.booking?.currency}
                                            </p>
                                            <p className="py-2">
                                                {" "}
                                                {bookingItem?.booking?.totalPayable.toFixed(
                                                    2
                                                )}{" "}
                                                {bookingItem?.booking?.currency}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    {/* {type === 'direct' && 
                        <React.Fragment>
                            <div className="whitespace-normal break-words">
                                {JSON.stringify(bookingItem)}
                            </div>
                        </React.Fragment>
                    } */}

                    {showControl &&
                        bookingItem?.booking.invoiceStatus !== "REJECTED" &&
                        process.env.REACT_APP_USER_TYPE === UserType.USER && (
                            <div className="flex mt-8 justify-end">
                                <ButtonOutlined
                                    title="Reject"
                                    buttonOutlinedHandle={buttonOutlinedHandle}
                                    w="176px"
                                    h="49px"
                                />
                                <ButtonFilled
                                    title="Confirm"
                                    buttonFilledHandle={buttonFilledHandle}
                                    w="291px"
                                    h="49px"
                                    arrow={true}
                                />
                            </div>
                        )}
                    {showControl &&
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN &&
                        bookingItem?.booking.invoiceStatus !== "INIT" && (
                            <div className="flex mt-8 justify-end">
                                <ButtonOutlined
                                    title={
                                        type === "regular"
                                            ? "Refuse Rejection"
                                            : "Reject"
                                    }
                                    buttonOutlinedHandle={buttonOutlinedHandle}
                                    w="176px"
                                    h="49px"
                                />
                                <ButtonFilled
                                    title={
                                        type === "regular"
                                            ? "Approve Rejection"
                                            : "Accept"
                                    }
                                    buttonFilledHandle={buttonFilledHandle}
                                    w="291px"
                                    h="49px"
                                    arrow={true}
                                />
                            </div>
                        )}
                </div>
            )}
        </div>
    );
};
