import React from 'react'
import { useLocation, useNavigate } from 'react-router';
import { adminRoute, merchantRoute, UserType } from '../../contants/Constants';
import { returnAdminOrUserBasedOnPath } from '../../utils/returnAdminOrUserBasedOnPath';

const BackButton = ({title}) => {
  const navigate = useNavigate();
  const location = useLocation();
    return (
        <div onClick={() => navigate(-1)}>
            <div className="flex pl-2">
              <svg
                width="39"
                height="39"
                viewBox="0 0 49 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
                // onClick={() => navigate(merchantRoute.HOTELS)}
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="48"
                  height="48"
                  rx="24"
                  fill="#FFFBEF"
                />
                <path
                  d="M25.5 21L21.5 25L25.5 29"
                  stroke="#376576"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="48"
                  height="48"
                  rx="24"
                  stroke="#376576"
                />
              </svg>
              <p
                className="h-full py-[2px] px-[10px] text-[27px] cursor-pointer"
              >
                {title?title:"Hotel"} list
              </p>
            </div>
        </div>
    )
}

export default BackButton
