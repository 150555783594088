import { DashboardRoutes, merchantRoute, UserType } from "../contants/Constants";

export const returnAdminOrUserBasedOnPath = (path) => {
  if (
    path == merchantRoute.OWNER ||
    path == merchantRoute.CONTRACT ||
    path == merchantRoute.DOCUMENT ||
    path == merchantRoute.HOTELS ||
    path.includes(merchantRoute.HOTEL) ||
    path.includes(merchantRoute.CATEGORY) ||
    path == merchantRoute.CATEGORIES ||
    path == DashboardRoutes.DASHBOARD ||
    path == DashboardRoutes.BOOKING ||
    path == DashboardRoutes.CATEGORIES ||
    path == DashboardRoutes.HOTELS ||
    path.includes(DashboardRoutes.HOTEL_DETAILS) ||
    path.includes(DashboardRoutes.CATEGORIES_DETAILS)
  ) {
    return UserType.USER;
  } else {
    return UserType.ADMIN;
  }
};
