import React, { useState } from "react";
import { CarouselSlider } from "./CarouselSlider";
import HotelCardWithoutBg from "./comp/HotelCard/HotelCardWithoutBg";
 
import HotelCardNext from "./comp/HotelCard/HotelCardNext";
import NextBlarImage from "./NextBlarImage";

const SliderCardWithDetails = ({ hotelList }) => {
  const [currentHotelId, setCurrentHotelId] = useState(0);
  const [nextHotelId, setNextHotelId] = useState(1);
  console.log(hotelList, "hotelListhotelList");
  const imageClickhandle = () => {
    if (hotelList && hotelList.length > 0) {
      if (hotelList.length - 1 == currentHotelId) {
        setCurrentHotelId(0);
        setNextHotelId(1);
      } else {
        setCurrentHotelId(currentHotelId + 1);
        if (hotelList.length - 1 == nextHotelId) {
          setNextHotelId(0);
        } else {
          setNextHotelId(currentHotelId + 2);
        }
      }
    }
  };
  console.log(nextHotelId, "nextHotelId", currentHotelId);
  return (
    <>
      <div className="grid grid-cols-3 gap-6 min-h-[398px]">
        <div className="col-span-2">
          <div>
            <HotelCardWithoutBg
              hotelList={hotelList}
              hotelShow={
                hotelList && hotelList.length > 0
                  ? hotelList[currentHotelId]
                  : ""
              }
            />
          </div>
        </div>
        <div className="col-span-1">
          <div onClick={imageClickhandle}>
            {/* <img
              src="Hotelslider.png"
              className="object-cover min-w-full min-h-full cursor-pointer blur-[2px] opacity-80"
              onClick={imageClickhandle}
            /> */}
            <NextBlarImage
              hotelShow={
                hotelList && hotelList.length > 0 ? hotelList[nextHotelId] : ""
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SliderCardWithDetails;
