import React, { useEffect } from "react";

function SingleSelectHotelReturn({
  item,
  setSelectedValue,
  selectedValue,
  displayField,
  setVisible,
  setSearchValue,
  selectedFieldName,
  // setDisplay,
}) {
  const handleSelect = () => {
    setSearchValue(displayField ? item[displayField] : item.label)
    console.log(item);
    setSelectedValue(item);
    setVisible(false);
  };
  return (
    <div className="py-2 hover:bg-btn-secondary hover:text-txt-secondary cursor-pointer px-4" onClick={handleSelect}>
      {displayField ? item[displayField] : item.label}
    </div>
  );
}

export default SingleSelectHotelReturn;
