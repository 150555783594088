import React from 'react'
import DashboardCategoryTable from '../../../components/table/DashboardCategoryTable'
import SecondaryNav from '../comp/SecondaryNav'

const MerchantDashboardCategories = () => {
    return (
        <div>
            <SecondaryNav />
            <div className='mt-6'>
                <DashboardCategoryTable />
            </div>
        </div>
    )
}

export default MerchantDashboardCategories
