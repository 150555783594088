const Privacy = () => {
    return (
      <div className=" bg-bg-primary">
        <div className=" md:w-full lg:max-w-[1024px] xl:max-w-[1280px] xxl:max-w-[1440px] mx-auto">
          <br />
          <br />
          <br />
          <br />
          <br />
          <p className="text-medium_title">Privacy Policy&nbsp;</p>
          <p>Ghuddy Consumers</p>
          <p>&nbsp;</p>
          <p>Effective Date: February 13, 2022</p>
          <p>&nbsp;</p>
          <p>Ghuddy is a company incorporated under the law of Bangladesh Companies Act, 1994. &nbsp;&nbsp;</p>
          <p>&nbsp;</p>
          <p>
            Ghuddy Limited (collectively, &ldquo;Ghuddy,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;)
            is committed to protecting your privacy. This Privacy Policy (&ldquo;Policy&rdquo;) explains how we collect,
            use, disclose and protect Personal Information (as it is defined below) of those that engage with our
            Services, as defined below, (&ldquo;Users&rdquo;) and Hotels and/or other businesses utilizing Ghuddy Limited
            (&ldquo;Merchants&rdquo;). It applies to all Personal Information collected by us on&nbsp;
            <a href="http://www.ghuddy.com">www.ghuddy.com</a> (the &ldquo;Site&rdquo;), the ghuddy.com and its mobile
            applications, and any other websites or mobile applications that link to this Policy, as well as during any
            written, electronic, and oral communications (collectively, the &ldquo;Services&rdquo;). Ghuddy.com may
            collect Personal Information to help you place and receive orders to improve Ghuddy Limited&rsquo;s service.
          </p>
          <p>&nbsp;</p>
          <p>
            If you are a Ghuddy.com User, your access to and use of our Services are subject to our terms and conditions,
            which defines some of the terms used throughout this Policy. Please make sure that you have carefully read and
            understood the Terms and Conditions before you use our Services. By using our Services, you accept the Terms
            and Conditions, and accept our privacy practices described in this Policy. If you do not feel comfortable with
            any part of this Policy or our Terms and Conditions, you must not use or access our Services.
          </p>
          <p>&nbsp;</p>
          <p>
            If you are a Ghuddy Limited Merchant, your access to and use of our Services are subject to the Merchant Terms
            of Service or other master services agreement entered between you and Ghuddy Limited.
          </p>
          <p>
            Ghuddy Limited uses a network of independent third-party contractors who provide &nbsp;Hotel Reservation
            services to our Users and Merchants. Those should refer to their privacy Policy for information on how
            ghuddy.com collects and uses their Personal Information.
          </p>
          <p>&nbsp;</p>
          <p>
            Our Services may change from time to time to be compliant with new regulations and market dynamics. As a
            result, we reserve the right to update or modify this Policy at any time. If we make any material changes to
            the way we process your Personal Information, we will notify you before such changes are effective. If you
            object to any changes, you must cease using our Services. We recommend you review this Policy periodically.
            This Policy became effective on the date indicated at the top of this page. Your continued use of our Services
            after any changes or revisions to this Policy indicates your agreement to the terms of the revised Policy.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p className="text-title">1. &nbsp; Collection of Personal Information</p>
          <p>&nbsp;</p>
          <p>
            When you use our Services, we may collect Personal Information about you. The types of Personal Information
            that we may collect about you are:
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">1a. Information You Provide to Bangladesh Directly</p>
          <p>&nbsp;</p>
          <p>
            You may provide your Personal Information to us when you use our Services, including when you register with
            Ghuddy , search within or place orders through our Services, contact us with inquiries, engage with our
            chatbot on the Site or Services, respond to surveys, or otherwise access or use our Services. This information
            may include:
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <p>
                Personal Identifiers and records information&nbsp;&ndash;&nbsp;We may collect information such as name,
                alias, email address, postal or delivery address, account name, phone number, signature, or other similar
                identifiers. &nbsp;
              </p>
            </li>
            <li>
              <p>
                Characteristics of protected classifications under Bangladesh or applicable national law and international
                law &ndash; We may collect your age, year or date of birth to enable us, for example, to determine if you
                are eligible to make purchases of alcohol.
              </p>
            </li>
            <li>
              <p>
                Biometric information &ndash; We may collect face photo, identity document, facial recognition, voice, or
                other biometric data. Any biometric information will be permanently deleted from the system after it is no
                longer necessary.
              </p>
            </li>
            <li>
              <p>
                Commercial Information &ndash; When you place an order through our Services, we collect information
                related to that order. This may include information such as items purchased, special instructions, date
                and time of order, subtotal, photos that you provide to Ghuddy, or other purchasing or consuming histories
                or tendencies. &nbsp;
              </p>
            </li>
            <li>
              <p>
                Payment Information &ndash; Payment information collected such as bank name, payment method, or other
                financial information. We use a payment processor that collects your payment information and processes
                payments made on or through the Services.&nbsp;
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p className="text-base20">1b. Information Received From You as Part of Your Communications</p>
          <p>&nbsp;</p>
          <p>
            When you use our Services (for example on our Site), complete electronic forms, communicate with Ghuddy or
            contact us, by online chat, email, phone or text, we may automatically collect and store certain information
            about you and the activity you engaged in, for example: your name and contact information; your order details;
            information that you voluntarily provide to us; the nature of your communication; the purpose of the
            interaction, and the action we took in response to your inquiry or request.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">1c. Information Collected Automatically</p>
          <p>&nbsp;</p>
          <p>
            We also may collect and store certain information about you and your device(s) automatically when you access
            or use our Site and Services. This information may include:
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <p>
                Technical Information &ndash; We collect technical information associated with your activity on our Site
                and may include information related to your browser and operating system, IP address (the Internet address
                of your computer), unique device identifiers, personal and online identifiers, and other information such
                as your device type and version of the app that is in use.
              </p>
            </li>
            <li>
              <p>
                Site Usage Information &ndash; This may include the webpage that you were visiting before accessing our
                Site or mobile application, the pages or features of our Site or mobile application that you browsed to
                inform us which part of our Site, app and Services you visit and how much time you spend there.&nbsp;
              </p>
            </li>
            <li>
              <p>
                Site Preferences &ndash; We collect information about your preferences to make your use of the Site more
                productive through the use of cookies.
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p className="text-base20">1d. Location Information</p>
          <p>&nbsp;</p>
          <p>
            When you use the Services, we may collect and store general location information (such as IP address, browser
            given geo coordinates, geo coordinates provided by the smartphone). We may also collect route information and
            reservation or drop-off information. If you permit the Services to access location services through the
            permission system used by your mobile operating system (&ldquo;Platform&rdquo;) or browser, we may also
            collect and store the precise location of your device when the Ghuddy website is running in the foreground or
            background of your device. This information may be used to help you select your hotel address, to provide more
            accurate reservation &nbsp;of your reservations, to provide recommendations to you, and to enhance your user
            experience and improve the services. You can choose whether to enable the location tracking feature through
            the settings on your device or Platform or when prompted by the Ghuddy mobile app. If you choose to disable
            the location feature, the ghuddy will not receive precise location information from your device, which may
            compromise the accuracy of reservations in some situations.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">1e. Information Related to the Referral Program or Gift Option</p>
          <p>&nbsp;</p>
          <p>
            Ghuddy allows users to send gifts using the Services and invite friends and family to sign up for our
            Services. Sometimes we offer a promotional value for every friend or family member who signs up and places an
            order through your referral link. To help you do this, we may request you to grant us access to your mobile
            device address book. You can then direct us to send referral emails to contacts you select in your address
            book. By sending a referral or gift, you consent to Ghuddy sending your public profile image to the contacts
            you select to receive a referral link or gift. By choosing to invite a friend or sending a gift to a
            recipient, you represent that you have received the friend&rsquo;s and/or recipient&rsquo;s consent to provide
            his or her name, mailing address, email address or other information with us. Ghuddy will use that information
            to send the requested gift or an email inviting him or her to visit the site and will store that information
            to track the success of the referral program and may send marketing emails. You can also send an SMS with a
            referral link to your contacts. If you use this option, you understand that Ghuddy does not control the
            timing, content, or recipients of these SMS messages.
          </p>
          <p>&nbsp;</p>
          <p>
            Users may not send, publish, or share any false, misleading, or deceptive communications in connection with
            participation in the Referral Program. Such actions may violate various applicable laws. Ghuddy users may not
            send any referral emails, SMS messages, or other communications to children under the age of 16.
          </p>
          <p>&nbsp;</p>
          <p>
            If you are referred to Ghuddy through one of our referral programs, or if someone attempts to refer you, we
            may share your name, whether you have already signed up for Ghuddy, whether you are a new customer, and
            whether you have placed a qualifying order with Ghuddy with the person(s) who referred you.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">1f. Personal Information from Publicly Available Sources and Third Parties</p>
          <p>&nbsp;</p>
          <p>
            We may collect Personal Information about you from publicly available sources, social network providers,
            marketing partners, and third parties. This may include:
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <p>
                Social Network Information &ndash; We may receive information about you from other services. For example,
                we receive certain information from Facebook when you use their service to login to Ghuddy.
              </p>
            </li>
            <li>
              <p>
                Demographic Information &ndash; This characteristic information could include information such as income,
                marital status, spending habits, and other information.
              </p>
            </li>
          </ul>
          <p>
            <br />
          </p>
          <p>&nbsp;</p>
          <p className="text-title">2. &nbsp; Use of Cookies and Other Tracking Technologies</p>
          <p>&nbsp;</p>
          <p>
            We use cookies, web beacons, pixels, session replay/screen capture, and similar technologies to collect
            information and personalize your experience with our Services. When we use session replay technology, we may
            collect on a real-time basis information on how you use and navigate the Services. This may include mouse
            movements and how you scroll through the Services, but we do not collect keystroke data. Additional
            information on other technologies we may use is set forth below.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">2a. Cookies</p>
          <p>&nbsp;</p>
          <p>
            Cookies are small web files that a site or its provider transfers to your device&rsquo;s hard drive through
            your web browser that enables the site&rsquo;s or provider&rsquo;s system to recognize your browser and
            remember certain information.
          </p>
          <p>&nbsp;</p>
          <p>How We Use Cookies&nbsp;&ndash; We use first-party and third-party cookies for the following purposes:</p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <p>to make our Services function properly;</p>
            </li>
            <li>
              <p>to improve our Services;</p>
            </li>
            <li>
              <p>
                to recognize you when you return to our Site and to remember information you have already provided, such
                as items already in your order cart;
              </p>
            </li>
            <li>
              <p>
                to collect information about your activities over time and across third party websites or other online
                services in order to deliver content and advertising tailored to your interests; and
              </p>
            </li>
            <li>
              <p>to provide a secure browsing experience during your use of our Services.</p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            How to Manage Cookies&nbsp;&ndash; You may disable the use of cookies by modifying your browser settings. If
            you choose to disable cookies you may not be able to fully experience the interactive features of our
            Services. For more information on cookies&nbsp;
          </p>
          <p>and how to disable cookies visit&nbsp;</p>
          <p>&nbsp;</p>
          <p className="text-base20">&nbsp;2b. Web Beacons</p>
          <p>&nbsp;</p>
          <p>
            Web beacons, also known as web bugs, pixel tags or clear GIFs, are tiny graphics with a unique identifier that
            may be included on our Site to deliver or communicate with cookies, in order to track and measure the
            performance of our Site and Services, monitor how many web visitors we have, and to monitor the effectiveness
            of our advertising. Unlike cookies, which are stored on the user&rsquo;s hard drive, web beacons are typically
            embedded invisibly on webpages (or in an email).
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">2c. Online Analytics and Advertising Technologies</p>
          <p>&nbsp;</p>
          <p>
            We and our third-party vendors may use automated technologies (including cookie identifiers on our Site),
            along with other collected information, to tailor ads or deliver content when you are on our Services or on
            other devices, apps or websites.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">2d. Targeted Advertising</p>
          <p>&nbsp;</p>
          <p>
            We (or our service providers) may use the information we collect, for instance, IP addresses and unique mobile
            device identifiers, to locate or try to locate the same unique users across multiple browsers or devices (such
            as smartphones, tablets, or computers), or work with providers that do this, in order to better tailor content
            and features and provide you with a seamless experience across devices. If you wish to opt -out of such cross
            device tracking for purposes of interest-based advertising, you may do so through your device settings. We may
            also use cross-device tracking to help identify our users and serve advertising. This type of advertising is
            often called &ldquo;interest-based&rdquo; or &ldquo;personalized&rdquo; advertising&mdash;and when it occurs
            on mobile apps, &ldquo;cross-app&rdquo; advertising.
          </p>
          <p>&nbsp;</p>
          <p>
            You can learn more about interest-based advertising and how to opt-out of receiving tailored advertising by
            visiting (i) the Network Advertising Initiative&rsquo;s Consumer&nbsp;
            <a href="http://www.networkadvertising.org/choices">Opt-Out link</a> or (ii) the Digital Advertising
            Alliance&rsquo;s Consumer&nbsp;<a href="http://www.aboutads.info/choices/">Opt-Out link</a>. To opt out of
            Google Analytics for display advertising or customize Google display network ads, you can visit the&nbsp;
            <a href="https://www.google.com/settings/ads">Google Ads Settings page</a>. International users can also
            opt-out of receiving interest-based advertising based on your browser by opting out using the Do Not Sell link
            on the&nbsp;<a href="https://ghuddy.com/">https://ghuddy.com/</a> homepage. That opt-out is only effective on
            the browser level and on&nbsp;<a href="https://ghuddy.com/">https://ghuddy.com/</a> . &nbsp;If you clear your
            cookies on this browser or use a different browser, you will need to renew your request.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            Please note that even if you opt-out, you may still receive advertisements from us. However, in that case, the
            advertising will not be tailored to your interests. Also, we do not control any of the above opt-out links or
            whether any particular company chooses to participate in these opt-out programs.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">2e. Mobile Applications</p>
          <p>&nbsp;</p>
          <p>
            Depending on your permissions, we may receive your Personal Information from your Internet service and mobile
            device providers. Users of mobile devices who do not want to receive interest-based advertising may opt-out in
            several ways. Learn more about your choices for mobile devices by visiting&nbsp;
            <a href="http://www.aboutads.info/appchoices">http://www.aboutads.info/appchoices</a>. Each operating system,
            (iOS for Apple phones, Android for Android devices, and Windows for Microsoft devices) provides its own
            instructions on how to prevent the delivery of tailored in-application advertisements. You should view your
            device or system &ldquo;Settings&rdquo; to determine how you can opt out of use of your device ID for
            &ldquo;cross-app&rdquo; personalized advertising.
          </p>
          <p>&nbsp;</p>
          <p className="text-title">3. &nbsp; Use of Your Personal Information</p>
          <p>&nbsp;</p>
          <p>We may use the information we collect about you for various purposes.</p>
          <p>&nbsp;</p>
          <p className="text-base20">3a. To Provide You with the Services</p>
          <p>&nbsp;</p>
          <p>
            We will use your information to provide you the Services. For example, we will use credit card information to
            complete a transaction or an address to allow the Ghuddy to fulfill your reservations (if you are a User), or
            your name and phone number to set up your business on the Services (if you are a Merchant). If the applicable
            information is to be provided or Service is to be performed, then we will disclose the applicable Personal
            Information to the third party providing the information for performing the applicable Services. Your
            information may be available or provided to third-party or service providers that are contractually obligated
            to protect your information as disclosed in this Policy. Payment information may be shared with your bank to
            validate your payment card.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">3b. To Maintain, Improve, and Personalize the Services</p>
          <p>&nbsp;</p>
          <p>
            We use your information for our everyday business operations such as auditing, administration of the Services,
            forum management, fulfillment, and analytics. Your information may also be used to improve the content and/or
            functionality of the Services. We may use your information to help us enhance offerings for Ghuddy and our
            Merchants, which may include improving marketing efforts for our large order delivering service, Ghuddy Drive.
            We also use your information to personalize your experience. For example, we may personalize the content and
            features you see when visiting our Services. In addition, we may personalize advertisements, marketing
            communications, and recommendations to make them more tailored to your interests.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">3c. To Communicate with You</p>
          <p>&nbsp;</p>
          <p>
            We use your information to communicate with you. For example, we may send you text messages or other
            notifications about the status of your reservations. We may also contact you with promotional offerings or
            other communications that may be of interest to you. If we send you marketing emails or texts about services
            that may interest you, each email or text will contain instructions permitting you to &ldquo;opt-out&rdquo; of
            receiving future email or text marketing communications.
          </p>
          <p>&nbsp;</p>
          <p>
            In the event you contact us, we use certain information to respond to your questions and assist you with any
            issues that may arise in your use of the Services. If you send direct messages, such as chat or text messages
            to Ghuddy, using the telephone number for that Ghuddy available on the Services, we may use a third-party
            service provider to monitor and analyze these messages. We monitor these messages for fraud prevention, to
            ensure appropriate charging of fees, to enforce our Terms and Conditions, and for quality and training
            purposes. As part of this process, Ghuddy and its service provider will receive in real time and store data
            about your direct messages, such as chat or text messages, including the date and time of the text message,
            your phone number, and the message. If you do not agree with the monitoring of direct messages you send to the
            Ghuddy support team.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">3d. For Account and Network Security Purposes</p>
          <p>&nbsp;</p>
          <p>
            We care about keeping you secure and safe while using our Services. Keeping you safe requires us to process
            your Personal Information, such as your device information, activity information and other relevant
            information. We use such information to combat spam, malware, malicious activity or security risks; improve
            and enforce our security measures; and to monitor and verify your identity so that unauthorized users do not
            gain access to your information.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">3e. To Maintain Legal and Regulatory Compliance</p>
          <p>&nbsp;</p>
          <p>
            Our Services are subject to certain laws and regulations which may require us to process your Personal
            Information. For example, we process your Personal Information to pay our taxes, to fulfill our business
            obligations, ensure compliance with employment and recruitment laws or as necessary to manage risk as required
            under applicable law.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">3f. To Enforce Compliance with Our Agreements and Policies</p>
          <p>&nbsp;</p>
          <p>
            When you access or use our Services, you are bound to our Terms and Conditions (if you are a User), the
            Merchant Terms of Service or other master services agreement entered into between you and Ghuddy (if you are a
            Merchant), and this Policy. To ensure you comply with them, we process your Personal Information by actively
            monitoring, investigating, preventing and mitigating any alleged or actual prohibited, illicit or illegal
            activities on our Services. We also process your Personal Information to: investigate, prevent or mitigate
            violations of our internal terms, agreements or policies; enforce our agreements with third parties and
            business partners.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">3g. To Protect You, Others, and Our Business</p>
          <p>&nbsp;</p>
          <p>
            We use your information to protect you, others, and our business, including, without limitation, using
            information for fraud prevention, for enforcement of our Terms of Service, to comply with the law, and to
            protect against security risks.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">3h. For Our Business Purposes</p>
          <p>&nbsp;</p>
          <p>
            We may use your information for any other purpose disclosed to you at the time we collect the information, or
            otherwise with your consent.
          </p>
          <p>&nbsp;</p>
          <p className="text-title">4. &nbsp; Provision of Personal Information to Third Parties</p>
          <p>&nbsp;</p>
          <p>We may provide your Personal Information to third parties as described below.</p>
          <p>&nbsp;</p>
          <p className="text-base20">4a. Service Providers or Third Parties</p>
          <p>&nbsp;</p>
          <p>
            We may provide your information to our service providers or third parties to help provide services to you for
            certain business purposes. This information is provided in order for them to provide us services such as
            payment processing, advertising services, marketing partners, web analytics, data processing, IT services,
            customer support and other services. These service providers or third parties may have access to your Personal
            Information to perform services on our behalf.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">4b. Ghuddy, Merchants or other users</p>
          <p>&nbsp;</p>
          <p>
            To help provide you with a high-quality delivery we also may provide your information, including your Personal
            Information and reservation details, to the Ghuddy who complete your requested reservations. Similarly, we may
            provide information to merchants to facilitate reservations such as your first name and last initial and
            reserve information related to your reservation with Merchants. We may also share information that you provide
            to Ghuddy with Merchants as part of a Merchant Loyalty Program when you connect your Ghuddy &nbsp;account with
            the applicable Merchant program.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p className="text-base20">4c. Social Media</p>
          <p>&nbsp;</p>
          <p>
            When you link a Social Network account to Ghuddy or log into our Services using your Social Network account,
            we may provide your information to the operator of the Social Network to facilitate or enhance delivery of
            that Social Network or other services to you. Please see &ldquo;8. &nbsp;Social Media Usage&rdquo; below for
            more details.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">4d. When Required or Permitted by Law</p>
          <p>&nbsp;</p>
          <p>
            We recognize that information related to your orders could contain private information. However, we may be
            required to provide your information to third parties including law enforcement agencies when required to
            protect and defend our legal rights, protect the safety and security of users of our Services, prevent fraud,
            comply with the law, respond to legal process, or a request for cooperation by a government entity, and the
            like.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">4e. Corporate Transactions</p>
          <p>&nbsp;</p>
          <p>
            In the event of a business transfer, merger and other reorganization, dissolution, or similar event we may
            provide your information to one or more third parties as part of that transaction, including during the
            negotiation of a transaction.
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">4f. With Your Consent</p>
          <p>&nbsp;</p>
          <p>We also may provide your information to third parties with your consent or at your direction.</p>
          <p>&nbsp;</p>
          <p className="text-title">5. &nbsp;Security</p>
          <p>&nbsp;</p>
          <p>
            Ghuddy has implemented administrative, organizational, technical, and physical security controls that are
            designed to safeguard Personal Information. However, no online activity is ever fully secure or error-free.
            While we strive to protect your information, we guarantee that your Personal Information is absolutely secure.
            Please keep this in mind when disclosing any information to Ghuddy.
          </p>
          <p>&nbsp;</p>
          <p>
            Please recognize that protecting your Personal Information is also your responsibility. We urge you to take
            every precaution to protect your information when you are on the Internet, or when you communicate with us and
            with others through the Internet. Change your passwords often, use a combination of letters and numbers, and
            make sure you use a secure browser. If you have reason to believe that your interaction with us is no longer
            secure (for example, if you feel that the security of your account might have been compromised), or if you
            suspect someone else is using your account, please&nbsp;
          </p>
          <p>
            let us know immediately by contacting us as indicated in the Contact (support@ghuddy.com) &nbsp;section below.
          </p>
          <p>&nbsp;</p>
          <p className="text-title">6. &nbsp;Data Retention</p>
          <p>&nbsp;</p>
          <p>
            We will retain your Personal Information for as long as your account is active or as needed to provide you
            services and to maintain a record of your transactions for financial reporting purposes. We will also retain
            and use your Personal Information as necessary to comply with our legal obligations, resolve disputes, and
            enforce our agreements.
          </p>
          <p>&nbsp;</p>
          <p className="text-title">7. &nbsp;Third-Party Sites</p>
          <p>&nbsp;</p>
          <p>
            Some of the functionality may be provided by third parties and the Services may link to other third-party
            websites that are not controlled by Ghuddy. For example, we may use services like Braintree for payment
            processing. These third parties are not under our control and Ghuddy is not responsible for their privacy
            policies or practices. If you provide any Personal Information to any third party or through any such
            third-party website, we recommend that you familiarize yourself with the privacy policies and practices of
            that third party.
          </p>
          <p>&nbsp;</p>
          <p className="text-title">8. &nbsp;Social Media Usage</p>
          <p>&nbsp;</p>
          <p>
            You may choose to enable or log in to our Services via various online services, including social networking
            services like Facebook and Google. Our Services also may enable you to access social networking services such
            as Facebook, Twitter, or Instagram (collectively, &ldquo;Social Network&rdquo;) directly or indirectly through
            our Services.
          </p>
          <p>&nbsp;</p>
          <p>
            When you link a Social Network account to Ghuddy or log into our Services using your Social Network account,
            we may collect relevant Personal Information necessary to enable our Services to access that Social Network
            and your information contained within that Social Network. We also may share your information with the
            operator of that Social Network to facilitate or enhance delivery of that Social Network or other services to
            you. A Social Network may provide us with access to certain information that you have provided to them, and we
            will use, store, and disclose such information in accordance with this Policy. Additionally, a Social Network
            may collect Personal Information and information about your use of our Site or Services automatically. The
            manner in which a Social Network collects, uses, stores, and discloses your information is governed by the
            policies of such third parties and DoorDash shall have no liability or responsibility for the privacy
            practices or other actions of any Social Network that may be enabled within our Services.
          </p>
          <p>&nbsp;</p>
          <p>
            You may also have the option of posting your activities on our Services and other content to a Social Network
            when you access content through our Services. For example, you may post to Facebook that you placed an order
            with Ghuddy. Keep in mind that your usage of Social Network features is governed by applicable Social Networks
            and not by Ghuddy and may be visible or accessible to the public.
          </p>
          <p>&nbsp;</p>
          <p className="text-title">9. &nbsp; Children&rsquo;s Information</p>
          <p>&nbsp;</p>
          <p>
            Our Services are not intended for children under 16 years of age, and we do not knowingly collect Personal
            Information from children under the age of 16. If you are a parent or guardian of a child under the age of 16
            and believe he or she has disclosed Personal Information to us, please contact us at&nbsp;
            <a href="mailto:support@ghuddy.com">support@ghuddy.com</a>. A parent or guardian of a child under age 16 may
            review and request deletion of the child&rsquo;s Personal Information.
          </p>
          <p>&nbsp;</p>
          <p className="text-title">10. &nbsp;Your Choices</p>
          <p>&nbsp;</p>
          <p>
            We provide users and their authorized agents with the ability to access and delete Personal Information. In
            order to exercise these rights, you must login to your account to confirm your identity, which helps us ensure
            that Personal Information is only made accessible to appropriate parties.Users will not receive discriminatory
            treatment for exercising their rights and can return to the service after deleting their information.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p className="text-base20">10a. Your Rights</p>
          <p>&nbsp;</p>
          <p>
            This section lists the privacy-related rights (&ldquo;Rights&rdquo;) we extend to Ghuddy users. Your rights
            are not absolute and are subject to certain exceptions. For instance, we cannot disclose specific pieces of
            Personal Information if the disclosure would create a substantial, articulable, and unreasonable risk to the
            security of the Personal Information, your account with us or the security of our systems.
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <p>
                Right to Know&nbsp;&ndash; You have the right to request that we disclose the Personal Information we
                retain about you.
              </p>
            </li>
            <li>
              <p>
                Right to Deletion&nbsp;&ndash; You have the right to request that we delete any Personal Information we
                have collected from you or maintain about you. We may save Personal Information when permitted by
                applicable law including, without limitation, when the information is needed for a legal purpose. If you
                are a international &nbsp;consumer, we may retain your Personal Information as necessary to: (1) complete
                the transaction for which the personal information was collected, provide a good or service requested by
                you, or reasonably anticipated within the context of our ongoing business relationship with you, or
                otherwise perform a contract between Ghuddy Limited and you; (2) detect security incidents, protect
                against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for that
                activity; (3) debug to identify and repair errors that impair existing intended functionality; (4)
                exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or
                exercise another right provided for by law; (5) &nbsp;Comply with the Digital security Act 2018 and
                relevant applicable law. (6) engage in public or peer-reviewed scientific, historical, or statistical
                research in the public interest that adheres to all other applicable ethics and privacy laws, when Ghuddy
                deletion of the information is likely to render impossible or seriously impair the achievement of such
                research, if you have provided informed consent; (7) to enable solely internal uses that are reasonably
                aligned with the expectations of you based on your relationship with Ghuddy ; (8) to comply with a legal
                obligation; &nbsp;(9) to otherwise use your personal information, internally, in a lawful manner that is
                compatible with the context in which you provided the information.
              </p>
            </li>
            <li>
              <p>
                Right to Rectification&nbsp;&ndash; If you are a registered Ghuddy user you may modify certain of your
                Personal Information by logging into our Site using your username and password and visiting the
                &ldquo;Account&rdquo; page. Registered Ghuddy users may also access the &ldquo;Account&rdquo; page to
                manage their subscriptions.
              </p>
            </li>
            <li>
              <p>
                Right to Opt-Out of the Sale of Your Personal Information&nbsp;&ndash; You have the right to opt-out of
                having your Personal Information sold. &nbsp;The Digital Security Act, 2018, The Consumers&rsquo; Rights
                Protection Act, 2009 and applicable laws which are relevant to solve the legal claim or dispute if they
                arise.
              </p>
            </li>
            <li>
              <p>
                Right to Non-Discrimination&nbsp;&ndash; Ghuddy will not discriminate against those who exercise their
                Rights. Specifically, if you exercise your Rights, we will not deny providing you with Reservations or
                Services, charge you different prices or rates for accommodations or Services or provide you a different
                level or quality of reservations or Services.
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p className="text-base20">10b. Asserting Your Rights</p>
          <p>&nbsp;</p>
          <p>
            If you do not have a Ghuddy account, you may exercise your right to know or your right to deletion by emailing
            your request to us at&nbsp;Support@ghuddy.com&nbsp;from the email address that you believe is associated with
            Ghuddy, with the subject line &ldquo;Non-user Data Request&rdquo; or calling us at +8801717855156. For safety
            and to otherwise prevent fraud, we may ask that you provide us with certain personal information that&rsquo;s
            reasonably necessary for us to confirm your identity.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p className="text-title">13. &nbsp; International Users</p>
          <p>&nbsp;</p>
          <p>
            Regardless of where you use our Services, the information collected as part of that use will be transferred to
            and maintained on servers located in the Germany . By using our Services, you consent to this collection,
            transfer, storage, and processing of information to and in the Germany.
          </p>
          <p>&nbsp;</p>
          <p>
            Where we transfer, store and process your personal information outside of bangladesh, we have ensure that
            appropriate safeguards are in place to ensure an adequate level of data protection.We rely on standard
            contractual clauses approved by the international law, international customary law, to transfer the data
            internationally, outside where you live. You may request a copy of a standard contractual clause by
            contradicting helpline on&nbsp;<a href="mailto:Support@ghuddy.com">Support@ghuddy.com</a> &nbsp;
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p className="text-title">14. &nbsp;Contact Us</p>
          <p>&nbsp;</p>
          <p>
            If you have any questions or concerns relating to this Policy or our privacy practices, or would like to
            opt-out, please contact us at&nbsp;support@ghuddy.com&nbsp;or :
          </p>
          <p>&nbsp;</p>
          <p>Ghuddy Limited</p>
          <p>Rahman Villa</p>
          <p>Ka-18, Baridhara North Road</p>
          <p>Gulshan, Dhaka-1212</p>
          <p>
            <br />
          </p>
        </div>
      </div>
    );
  };
  
  export default Privacy;
  