import React, { useState, useEffect, useRef } from "react";
import SingleSelectSimpleArray from "../singleSelect/SingleSelectSimpleArray";
import ReactOutsideClickHandler from "react-outside-click-handler";
import RootComponent from "../rootComponent/RootComponent";
import { customValidationSingle } from "../../utils/customValidation";

function YearPicker({
  selectedValue,
  setSelectedValue,
  placeholder,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  rejectionArray,
  setRejectionArray,
  name,
  required=false,
}) {
  let date = new Date();
  let year = date.getFullYear();
  let yearList = [];
  const [searchValue, SetSearchValue] = useState("");

  for (let i = year - 100; i <= year; i++) {
    yearList.push(i);
  }

  const [visible, setVisible] = React.useState(false);
  const [display, setDisplay] = React.useState(selectedValue);

  const handleSearchChange = (e) => {
    SetSearchValue(e.target.value);
    setSelectedValue(e.target.value);
  };
  // state for ref width support
  const [width, setWidth] = React.useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  const component = (
    <ReactOutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div className="w-full  rounded-lg">
        <div id={name} className={`${required && 'required'}`}>
          <div
            className="w-full rounded-[8px] h-[49px] flex validation outline-none"
            ref={ref}
            onClick={() => setVisible(!visible)}
          >
            <div className="cursor-auto text-[#9e9989] md:text-xs text-left validation h-full rounded-[8px] pl-3 flex items-center justify-center border-[#9e9989] border border-r-0 rounded-r-none">
              <span className="w-full whitespace-nowrap">{placeholder}{required ? "*" : null}: </span>
            </div>
            <input
              autoComplete="off"
              id={`input${name}`}
              value={selectedValue ? selectedValue : searchValue}
              onClick={(e) => e.preventDefault()}
              className="w-full text-left pl-4 h-full  bg-[transparent] validation focus:outline-none active:outline-none border-[#9e9989] border border-l-0 rounded-l-none border-r-0"
              onChange={handleSearchChange}
              onBlur={()=>{required && customValidationSingle(name)}}
            />
            <button className="validation bg-btn-primary ml-auto py-4 w-[49px] h-full rounded-r-[8px] rounded-l-none border border-l-0 border-[#314747] px-5 hover:bg-btn-secondary duration-500">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.5L6 8.5L10 4.5"
                  stroke="#FFFBEF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="errorMessage validationError">
            
          </div>
        </div>
        {visible && (
          <div
            className="pt-4 rounded-[8px] bg-bg-primary custom-shadow z-50 absolute max-h-[200px] h-[200px] overflow-scroll"
            style={{ width: width }}
          >
            <div className="">
              {yearList && yearList.length
                ? yearList
                  .reverse()
                  .filter((list) => list.toString().includes(searchValue))
                  .map((item, index) => {
                    return (
                      // <p>{item}</p>
                      <SingleSelectSimpleArray
                        item={item}
                        setSelectedValue={setSelectedValue}
                        setVisible={setVisible}
                        setDisplay={setDisplay}
                        validation={required}
                        name={name}
                      />

                    );
                  })
                : null}
            </div>
          </div>
        )}
      </div>
    </ReactOutsideClickHandler>
  );
  let readOnlyViewComponent = (
    <p>{selectedValue ? selectedValue : searchValue}</p>
  );
  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        name={name}
      />
    </div>
  );
}

export default YearPicker;
