// import { toast } from "@mobiscroll/react";

import { toast } from "react-toastify";

export const toastMessage = (message, type) => {
  // toast({
  //     message: message ? message : "Submitted",
  //     type: type ? type : "success",
  //     position: "top",
  //     icon: type ? type : "success",
  //   })
  if (type === 'error') {
    
    toast.error(message ? message : "Submitted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }
  else{
    toast.success(message ? message : "Submitted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }
};
