import React, { useEffect, useRef } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
// import SingleSelect from "./SingleSelect";
 
import RootComponent from "../../rootComponent/RootComponent";
import SingleSelectHotelReturn from "./SingleSelectHotelReturn";

function SingleSelectParentCategoryReturnCalender({
  data,
  selectedValue,
  setSelectedValue,
  placeholder,
  displayField,
  selectedFieldName,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  // rejectionArray,
  setRejectionArray,
  name,
  warningView
}) {
  const [visible, setVisible] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  const [width, setWidth] = React.useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  console.log(width, "refref", ref)

  console.log(selectedValue.categoryName, "selectedValue")

  let component = (
    <ReactOutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div className="w-full flex flex-col rounded-lg relative">
        <div
          ref={ref}
          className="rounded-[70px] border-btn-secondary min-h-10 text-txt-black w-full border-2 flex items-center justify-between py-2 pl-4"
          onClick={() => setVisible(!visible)}
        >
          {/* <p className={` bg-bg-primary text-btn-secondary text-xs`}>
            {placeholder}:
          </p> */}
          <p className=" text-btn-secondary text-xs pl-2">{(selectedValue && selectedValue.categoryName)
            ? `${selectedValue.categoryName?.length > 15 ? selectedValue.categoryName.slice(0,12)+'...' : selectedValue.categoryName}`
            : placeholder}
          </p>
          <div className="cursor-pointer w-[49px] rounded-[8px] ml-auto h-full">
            <svg
              width="18"
              height="18"
              viewBox="0 0 12 12"
              fill="#376576"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 4.5L6 8.5L10 4.5"
                stroke="#FFFBEF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {visible && (
          <div
            className=" pt-4 rounded-2xl mt-16 bg-bg-primary custom-shadow z-70 absolute max-h-[200px] h-[200px] overflow-scroll w-full"
          >
            <div className="">
              {data && data.length
                ? data.map((item, index) => {
                  return (
                    <SingleSelectHotelReturn
                      displayField={displayField}
                      key={index}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      item={item}
                      setVisible={setVisible}
                      selectedFieldName={selectedFieldName}
                      setSearchValue={setSearchValue}
                    />
                    // <p>{item.categoryName}</p>
                  );
                })
                : null}
            </div>
          </div>
        )}
      </div>
    </ReactOutsideClickHandler>
  )
  let readOnlyViewComponent = <p>{selectedValue.propertyName}</p>;
  return (
    <div className="w-full max-w-[390px]">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        // rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        name={name}
        warningView={warningView}
      />
    </div>
  );
}

export default SingleSelectParentCategoryReturnCalender;
