import React from "react";
//ml-[${ml ? ml : "20px"}]
const ButtonFilled = ({
    disable,
    buttonFilledHandle,
    title,
    h,
    w,
    tw,
    ml,
    round,
    arrow,
    type = "button",
}) => {
    return (
        <button
            tabIndex={"-1"}
            type={type}
            onClick={buttonFilledHandle}
            disabled={disable === "yes" ? true : false}
            // disabled={true}
            style={{ width: w ? w : 50, height: h ? h : 50 }}
            className={
                (disable === "yes"
                    ? "bg-txt-hint text-txt-secondary border-bg-semiblack"
                    : "bg-btn-primary hover:bg-btn-secondary transition duration-300 hover:text-bg-white ") +
                `px-10 ${ml ? ml : 'ml-[20px]'}  h-[46px] ${
                    round ? round : "rounded-full"
                } text-xl flex justify-center items-center leading-small text-[18px] text-txt-secondary active:bg-txt-secondary active:border active:scale-90 active:border-btn-primary active:text-btn-primary` +
                (tw ? ` ${tw}` : "")
            }
        >
            {title ? title : "Submit"}
            {arrow ? null : (
                <div className="px-2">
                    <svg
                        width="6"
                        height="10"
                        viewBox="0 0 9 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.75 13.5L7.75 7.5L1.75 1.5"
                            stroke="#FFFBEF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            )}
        </button>
    );
};

export default ButtonFilled;
