import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dual } from "./comp/Dual";
import ButtonFilled from "../../components/ButtonFilled";
import ButtonOutlined from "../../components/ButtonOutlined";
import InputComponent from "../../components/InputComponent";
import ModalGlobal from "../../components/ModalGlobal";
import {
  createFunction,
  createRole,
  deleteFunction,
  deleteRole,
  editFunction,
  editRole,
  fetchFunctions,
  fetchRoles,
} from "../../store/user/api";

function Roles() {
  const [openAddNew, setOpenAddNew] = useState(false);
  const [authority, setAuthority] = useState("");
  const [editable, setEditable] = useState(false);
  const [id, setId] = useState(0);
  const roles = useSelector((state) => state.fetchRoleReducer.data);

  // some helper state
  const [flag, setFlag] = useState(0)

  useEffect(() => {
    dispatch(fetchRoles(0, 100));
  }, [flag]);
  console.log(roles, "roles");

  const editRoles = (authority, editable, id) => {
    console.log("editRole");
    setId(id);
    setAuthority(authority);
    setEditable(editable);
    setOpenAddNew(true);
  };

  const createOrEditRole = () => {
    console.log("createRole");
    if (id > 0) {
      dispatch(editRole(id, { authority }, setFlag));
    } else {
      dispatch(createRole({ authority }, setFlag));
    }
  };

  const createNewModal = () => {
    setId(0);
    setAuthority("");
    // setEditable(true);
    setOpenAddNew(true);
  };

  const deleteRoles = (id) => {
    console.log("deleteRole", id);
    dispatch(deleteRole(id, setFlag));
  };

  const createRoleComponent = () => {
    return (
      <div className="flex flex-col px-12">
        <InputComponent
          inputValue={authority}
          setInputValue={setAuthority}
          placeholder="Authority"
          modal={true}
        />
        <div className="flex justify-center">
          <ButtonFilled
            title="Submit"
            buttonFilledHandle={createOrEditRole}
            w="176px"
            h="49px"
          />
        </div>
      </div>
    );
  };
  const dispatch = useDispatch();

  return (
    <div className="">
      <div className="min-h-screen ">
        <ModalGlobal
          header="Create/update Role"
          body={createRoleComponent()}
          openDefault={openAddNew}
          setOpenDefault={setOpenAddNew}
          // navigatepass="/admin/role"
        />
        <h1 className="text-2xl py-4 border-b-2">Role</h1>
        <div className=" flex content-end justify-items-end py-4 ">
          <ButtonOutlined
            title="Add New Role"
            buttonOutlinedHandle={createNewModal}
            w="176px"
            h="49px"
          />
        </div>
        <div>

          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="text-2xl">ID</th>
                <th className="text-2xl">Authority</th>
                <th className="text-2xl">Editable</th>
                {/* <th className="text-2xl">Edit</th> */}
                <th className="text-2xl">Delete</th>
              </tr>
            </thead>
            <tbody className="pt-8">
              {roles.roles && roles.roles.map((role, index) => {
                return (
                  <tr>
                    <td>
                      <div className="flex justify-center">
                        <h2 className="">{role.id}</h2>
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <h2 className="">{role.authority}</h2>
                      </div>
                    </td>
                    {/* <td>
                      <div className="flex justify-center">
                        <h2 className="">{role.editable}</h2>
                      </div>
                    </td> */}
                    <td>
                      <div className="flex justify-center">
                        <ButtonFilled
                          title="Edit"
                          buttonFilledHandle={() => {
                            editRoles(role.authority, role.editable, role.id);
                          }}
                          w="176px"
                          h="49px"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <ButtonFilled
                          title="Delete"
                          buttonFilledHandle={() => {
                            deleteRoles(role.id);
                          }}
                          w="176px"
                          h="49px"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Roles;
