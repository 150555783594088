import React, { useEffect } from "react";
import { customValidationSingle } from "../../utils/customValidation";

function SingleSelect({
  item,
  setSelectedValue,
  selectedValue,
  displayField,
  setVisible,
  selectedFieldName,
  validation=null,
  name,
  // setDisplay
}) {
  const handleSelect = () => {
    setSelectedValue(item[selectedFieldName]);
    console.log(item[displayField], "item[displayField]")
    setVisible(false);
    if (validation) {
      setTimeout(()=>{customValidationSingle(name)},200)
    }
  };
  return (
    <div className="py-2 hover:bg-btn-secondary hover:text-txt-secondary cursor-pointer px-6 " onClick={handleSelect}>
      {displayField ? item[displayField] : item.label}
    </div>
  );
}

export default SingleSelect;
