import React from 'react'

function Loading() {
    return (
        <div className="py-12">
            <svg className="animate-spin" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.1" d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60ZM18 60C18 83.196 36.804 102 60 102C83.196 102 102 83.196 102 60C102 36.804 83.196 18 60 18C36.804 18 18 36.804 18 60Z" fill="#4E90A8" />
                <path d="M60 -2.62268e-06C52.1207 -2.27827e-06 44.3185 1.55194 37.039 4.56723C29.7595 7.58251 23.1451 12.0021 17.5736 17.5736C12.0021 23.1451 7.58251 29.7595 4.56722 37.039C1.55194 44.3185 -3.31151e-06 52.1207 -2.62268e-06 60L18 60C18 54.4845 19.0864 49.023 21.1971 43.9273C23.3078 38.8316 26.4015 34.2016 30.3015 30.3015C34.2016 26.4015 38.8316 23.3078 43.9273 21.1971C49.023 19.0864 54.4845 18 60 18L60 -2.62268e-06Z" fill="#4E90A8" />
            </svg>
        </div>
    )
}

export default Loading