import React, { useEffect, useRef } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";

const RoundedDropdownButton = ({ buttonOutlinedHandle,type, title, h, w, dropdownData, selectHandler, selector }) => {
  const [visible, setVisible] = React.useState(false);
  const [width, setWidth] = React.useState(0);


  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  const handleButtonClick = () => {
    // if (dropdownData) {
    setVisible(!visible);
    // }
  };

  return (
    <ReactOutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div className=" relative rounded-lg drop-shadow-md z-40">
        <button
          ref={ref}
          onClick={handleButtonClick}
          className={`w-${w ? w : "[348px]"} h-[50px] border border-btn-primary flex justify-center items-center rounded-[300px] leading-
          bg-bg-primary text-[12px] text-btn-primary mr-[20px]  active:bg-txt-secondary active:border active:border-btn-primary active:text-btn-primary`}
        >
          {title ? title : "Footer"}
          <div className="px-2">
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#376576"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
        {visible && (
          <div
            className={`pt-4 rounded-2xl top-14 bg-bg-primary custom-shadow z-[500] absolute`}
            style={{ width: width }}
          >
            <div className="h-auto max-h-[190px] px-6 overflow-scroll ">
              {[...dropdownData].map((hotel, index) => (
                <p
                  key={ type==="contract" ? hotel.contractId : type === "category" ? hotel.roomCategoryId : hotel.id}
                  className="py-2 cursor-pointer hover:bg-bg-semiwhite"
                  onClick={e => { selectHandler( type==="contract" ? hotel.contractId :  type === "category" ? hotel.roomCategoryId : hotel.id); setVisible(false) }}>
                  {hotel[selector]}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </ReactOutsideClickHandler>
  );
};

export default RoundedDropdownButton;
