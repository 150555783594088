import React, { useEffect, useState } from "react";
import { returnS3imageViewUsingUrlCutting } from "../../utils/returnS3imageViewUsingUrlCutting";

const NextBlarImage = ({ hotelShow }) => {
  let imageList = hotelShow && hotelShow.images ? hotelShow.images : [];
  const [image, setImage] = useState();
  useEffect(() => {
    if (imageList.length) {
      setImage(returnS3imageViewUsingUrlCutting(imageList[0].url));
    }
  }, [imageList]);
  console.log(image, "imageimageimage");
  return (
    <div>
      <img
        src={image ? image : "Hotelslider.png"}
        className="w-full h-[397px] rounded-[8px] object-cover cursor-pointer blur-[2px] opacity-80"
      />
    </div>
  );
};

export default NextBlarImage;
