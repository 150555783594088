import React from "react";
import ReactTooltip from "react-tooltip";
 

const WarningIcon = ({ warningType, warningText,rejectionArray,name }) => {
  let fooRef;
  return (
    <div className="flex justify-center place-items-center">
      {/* <p ref={ref => fooRef = ref} data-tip={warningText} data-type="warning" data-place="right">hi</p> */}
      {/* <button onClick={() => { ReactTooltip.hide(fooRef) }}>there</button>
            <ReactTooltip /> */}
      {warningType == "warning" ? (
        <div className="w-[49px] cursor-pointer">
          <p
            ref={(ref) => (fooRef = ref)}
            data-tip={rejectionArray[name]}
            // data-tip={warningText}
            data-effect="solid"
            data-type="warning"
            data-place="right"
          >
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="49" height="49" rx="24.5" fill="#FEE29A" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8968 35.2058C25.4227 35.2058 25.927 34.9969 26.2988 34.625C26.6707 34.2532 26.8796 33.7489 26.8796 33.223C26.8796 32.6971 26.6707 32.1928 26.2988 31.821C25.927 31.4491 25.4227 31.2402 24.8968 31.2402C24.371 31.2402 23.8666 31.4491 23.4948 31.821C23.123 32.1928 22.9141 32.6971 22.9141 33.223C22.9141 33.7489 23.123 34.2532 23.4948 34.625C23.8666 34.9969 24.371 35.2058 24.8968 35.2058Z"
                fill="#FFFBEF"
              />
              <path
                d="M24.8984 15.3789V28.0686"
                stroke="#FFFBEF"
                strokeWidth="3.17241"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </p>
          <ReactTooltip />
        </div>
      ) : (
        <div className="w-[49px] cursor-pointer">
          <p
            ref={(ref) => (fooRef = ref)}
            data-type="success"
            data-tip={rejectionArray[name]}
            // data-tip={warningText}
            data-effect="solid"
            data-place="right"
          >
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="49" height="49" rx="24.5" fill="#4E90A8" />
              <path
                d="M23.5396 31.9987C23.0393 31.9987 22.5655 31.7957 22.2619 31.4457L18.153 26.7247C18.026 26.5789 17.9332 26.4128 17.8801 26.2358C17.8269 26.0588 17.8144 25.8744 17.8432 25.6932C17.8721 25.512 17.9417 25.3376 18.0481 25.1798C18.1546 25.022 18.2957 24.884 18.4635 24.7737C18.6312 24.6629 18.8225 24.582 19.0262 24.5356C19.23 24.4892 19.4423 24.4783 19.6509 24.5033C19.8596 24.5284 20.0605 24.5891 20.2421 24.6818C20.4238 24.7745 20.5826 24.8975 20.7094 25.0437L23.4131 28.1477L30.2107 18.6557C30.4362 18.3423 30.7954 18.1194 31.2095 18.036C31.6237 17.9526 32.0589 18.0154 32.4199 18.2107C33.1708 18.6167 33.402 19.4767 32.9328 20.1307L24.9012 31.3407C24.7641 31.5329 24.5751 31.6931 24.3507 31.8075C24.1262 31.9218 23.8731 31.9867 23.6132 31.9967C23.5879 31.9987 23.5649 31.9987 23.5396 31.9987Z"
                fill="#FFFBEF"
              />
            </svg>
          </p>
          <ReactTooltip />
        </div>
      )}
    </div>
  );
};

export default WarningIcon;
