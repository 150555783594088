/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
// import SingleSelect from "./SingleSelect";

import RootComponent from "../../rootComponent/RootComponent";
import SingleSelectHotelReturn from "./SingleSelectHotelReturn";

function SingleSelectParentCategoryReturn({
    data,
    selectedValue,
    setSelectedValue,
    placeholder,
    displayField,
    selectedFieldName,
    warningText,
    isAdmin,
    isReadOnly,
    warningType,
    // rejectionArray,
    setRejectionArray,
    name,
    warningView,
}) {
    const [visible, setVisible] = React.useState(false);

    const [width, setWidth] = React.useState(0);
    const [searchValue, setSearchValue] = React.useState("");
    const [selectionValue, setSelectionValue] = React.useState("");
    const ref = useRef(null);
    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);

    useEffect(() => {
        if (selectedValue) {
            setSelectionValue(selectedValue[displayField]);
        }
    }, [selectedValue]);

    useEffect(()=>{
        if(!data){
            setSelectedValue(null);
        }
    },[data])

    let component = (
        <ReactOutsideClickHandler
            onOutsideClick={() => {
                setVisible(false);
            }}
        >
            <div className="w-full flex flex-col rounded-lg relative cursor-pointer">
                <div className=" bg-bg-primary pb-2 pt-2">
                    <div
                        ref={ref}
                        className="w-full rounded-[8px] outline-custom black_box h-[49px] flex items-center outline-[1px] outline-offset-[-1px] outline-none"
                        onClick={() => {
                            setVisible(!visible);
                            setSearchValue("");
                        }}
                    >
                        <p className={`pl-10 py-3 bg-bg-primary min-w-[200px]`}>
                            {placeholder}:
                        </p>

                        <input
                            className="w-full h-full bg-[transparent] outline-none"
                            onClick={() => {
                                setSelectionValue("");
                                setSearchValue("");
                                setVisible(true);
                            }}
                            value={
                                selectionValue ? selectionValue : searchValue
                            }
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                                setVisible(true);
                            }}
                        ></input>
                        <button className="bg-btn-primary w-[49px] rounded-[8px] px-5 ml-auto h-full">
                            <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2 4.5L6 8.5L10 4.5"
                                    stroke="#FFFBEF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                {visible && (
                    <div className=" pt-4 rounded-2xl mt-16 bg-bg-primary custom-shadow z-70 absolute max-h-[200px] w-full overflow-y-scroll z-50">
                        <div className="">
                            {data && data.length > 0 ? (
                                data
                                    .filter((parent) =>
                                        parent[displayField]
                                            ?.toLowerCase()
                                            .includes(searchValue)
                                    )
                                    .map((item, index) => {
                                        return (
                                            <SingleSelectHotelReturn
                                                displayField={displayField}
                                                key={index}
                                                selectedValue={selectedValue}
                                                setSelectedValue={
                                                    setSelectedValue
                                                }
                                                setSearchValue={setSearchValue}
                                                item={item}
                                                setVisible={setVisible}
                                                selectedFieldName={
                                                    selectedFieldName
                                                }
                                            />
                                        );
                                    })
                            ) : (
                                <div className="py-2 hover:bg-btn-secondary hover:text-txt-secondary cursor-pointer px-4">
                                    No data
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </ReactOutsideClickHandler>
    );
    let readOnlyViewComponent = <p>{selectedValue?.propertyName}</p>;
    return (
        <div className="w-full">
            <RootComponent
                component={component}
                readOnlyViewComponent={readOnlyViewComponent}
                warningText={warningText}
                warningType={warningType}
                isAdmin={isAdmin}
                isReadOnly={isReadOnly}
                // rejectionArray={rejectionArray}
                setRejectionArray={setRejectionArray}
                name={name}
                warningView={warningView}
            />
        </div>
    );
}

export default SingleSelectParentCategoryReturn;
