// import axios from "axios";
// import { baseUrl } from "../contants/Endpoints";
// import jwt_decode from "jwt-decode";
// import { noAuthAxios } from './axiosWrapperOpen';
// import { asyncLocalStorage } from "./asyncLocalStorage";

import axios from "axios";
import { baseUrl } from "../contants/Endpoints";
import { asyncLocalStorage } from "./asyncLocalStorage";
import { noAuthAxios } from "./axiosWrapperOpen";
import jwt_decode from "jwt-decode";

// export const authAxios = axios.create({
//   baseURL: baseUrl,
//   headers: {
//     Authorization: {
//       toString() {
//         const token = localStorage.getItem("jwt");
//         const decidedToken = isTokenExpired(token);
//         console.log("decidedToken", decidedToken);
//         return `Bearer ${decidedToken}`;
//       },
//     },
//     Accept: "application/json",
//     "Content-Type": "application/json",
//   },
// });

// function isTokenExpired(token){
//   try {
//     const decoded = jwt_decode(token);
//     if (decoded.exp < Date.now()/1000) {
//       return noAuthAxios.post(`${baseUrl}open/refresh_token`, {
//         refreshToken: localStorage.getItem("refreshToken"),
//       })
//       .then(async function (res) {
//           asyncLocalStorage.setItem("jwt", res.data.accessToken);
//           asyncLocalStorage.setItem("refreshToken", res.data.refreshToken);
//           return 'token';
//         });
//     } else {
//       console.log('token',"token not expired");
//       return token;
//     };
//   } catch (err) {
//     console.log(err, "err");
//     return false;
//   }
// }

export const authAxios = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Request interceptor for API calls
authAxios.interceptors.request.use(
  async (config) => {
    const value = await asyncLocalStorage.getItem("jwt");
    let token = "default";
    const decoded = jwt_decode(value);
    // console.log("auth", decoded);
    if (decoded.exp < Date.now() / 1000) {
      // console.log("token expired", "auth");
      await noAuthAxios
        .post(`${baseUrl}open/refresh_token`, {
          refreshToken: localStorage.getItem("refreshToken"),
        })
        .then(async function (res) {
          await asyncLocalStorage.setItem("jwt", res.data.accessToken);
          await asyncLocalStorage.setItem(
            "refreshToken",
            res.data.refreshToken
          );
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.accessToken;
          token = res.data.accessToken;
          // return "token";
        });
    } else {
      // console.log("token not expired", "auth");
      token = value;
    }
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
// authAxios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 403 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       // const access_token = await refreshAccessToken();
//       noAuthAxios
//         .post(`${baseUrl}open/refresh_token`, {
//           refreshToken: localStorage.getItem("refreshToken"),
//         })
//         .then(async function (res) {
//           asyncLocalStorage.setItem("jwt", res.data.accessToken);
//           asyncLocalStorage.setItem("refreshToken", res.data.refreshToken);
//           axios.defaults.headers.common["Authorization"] =
//             "Bearer " + res.data.accessToken;

//           // return "token";
//         });
//       return authAxios(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );
// export default authAxios;
