import React from "react";
import { returnLastTwoDigitFromANumber } from "../../utils/returnLastTwoDigitFromANumber";

const EachCalenderDateCard = ({ data, index, selectedCategory }) => {
  return (
    <div className="h-[120px] relative">
      {data.blackPrice ? (
        <div className="">
          <div className="flex text-[10px]">
            {/* <div className="grow text-xl text-btn-secondary">{`${returnLastTwoDigitFromANumber(data.dayInt ? data.dayInt : index )}`}</div> */}
            <div className="grow text-xl text-btn-secondary">
              {`${data ? returnLastTwoDigitFromANumber(data.dayInt) : "10"
                }`}
            </div>
            {
              selectedCategory ?
                <div className="bg-btn-secondary flex items-center text-xs text-txt-secondary px-3 h-6 rounded-full self-center">
                  {`${data ? data?.blackPrice.toFixed(2) : "0"}$`}
                </div>
                :
                <div className="bg-btn-secondary flex items-center text-xs text-txt-secondary px-3 h-6 rounded-full self-center">
                  {`${data ? data?.blackPrice.toFixed(2) : "0"}$`}
                </div>
            }
          </div>
          <div className="flex mt-2">
            <div className="grow">{``}</div>
            {(data && data.discount > 0) && (
              <div className="bg-btn-primary text-txt-secondary flex items-center pl-2 rounded-full h-6">
                {selectedCategory ? (
                  <div className="h-full flex items-center gap-x-1">
                    <span className="text-xs">
                      {`${data?.discount}% `} off {" "}
                    </span>
                    <span className="bg-btn-pink rounded-full px-2 text-xs h-full flex items-center">
                      {data ? data?.redPrice.toFixed(2) : ""}$
                    </span>
                  </div>
                ) : (
                  <div className="h-full flex items-center gap-x-1">
                    <span className="text-xs">
                      up to - {`${data?.discount}% `}
                    </span>
                    <span className="bg-btn-pink rounded-full px-2 text-xs h-full flex items-center">
                      {data ? data?.redPrice.toFixed(2) : ""}$
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="pb-2 pt-1 flex text-xs">
            <span className=" mr-1">Total: </span>{"  "}
            <span className="text-btn-primary text-xs">
              {(data && data.totalRoom) ? data.totalRoom : "0"}
            </span>
          </div>
          <div className="pb-4 flex text-xs">
            <span className=" mr-1">Available: </span>
            {` `}
            <span className="text-btn-primary text-xs">
              {(data && data.availableRoom) ? data.availableRoom : "0"}
            </span>
          </div>
        </div>
      ) : (
        <div >
          <div className=" flex flex-col">
            <div className="text-xl text-center text-btn-secondary">
              {returnLastTwoDigitFromANumber(data.dayInt)}
            </div>
            <div className="text-center text-xs text-[#FE9A9A]"> No data found </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default EachCalenderDateCard;
