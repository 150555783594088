import React, { useState } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
// import "./selectscrollbar.css";

import RootComponent from "../rootComponent/RootComponent";

function SingleSelectPhone({
    inputValue,
    setInputValue,
    title,
    setTitle,
    titleArr,
    id,
    type,
    warningView,
    warningText,
    isAdmin,
    isReadOnly,
    warningType,
    rejectionArray,
    setRejectionArray,
    name,
    required = false,
}) {
    const [isHidden, setIsHidden] = useState(true);
    const [searchValue, SetSearchValue] = useState("");

    const handleClick = (e) => {
        e.preventDefault();
        if (isHidden) {
            try {
                const input = document.getElementById(id ? id : "input_focus");
                input.focus();
            } catch (e) {
                console.log(e);
            }
        } else {
            try {
                const input = document.getElementById(id ? id : "input_focus");
                input.focus("none");
            } catch (e) {
                console.log(e);
            }
        }
        setIsHidden(!isHidden);
    };

    const handleSelect = (val) => {
        setTitle(val);
        setIsHidden(!isHidden);
    };
    const handleSearchChange = (e) => {
        SetSearchValue(e.target.value);
        setTitle(e.target.value);
    };
    let component = (
        <div id={name} className={`${required && "required"}`}>
            <div className="rounded-[8px] validation  h-[49px] w-full flex  outline-none">
                <ReactOutsideClickHandler
                    onOutsideClick={() => {
                        setIsHidden(true);
                    }}
                >
                    <div className="h-full">
                        <button
                            onClick={handleClick}
                            className={`flex justify-center items-center h-full w-[103px] bg-btn-primary text-bg-white hover:bg-btn-secondary validation rounded-l-[8px] border-r-0 border validation border-[#314747]`}
                        >
                            <div>
                                <input
                                    name="phoneCode"
                                    id={id ? id : "input_focus"}
                                    value={title ? title : searchValue}
                                    placeholder="..."
                                    onClick={(e) => e.preventDefault()}
                                    className="w-full h-full text-center  text-bg-primary appearance-none focus:outline-none hover:bg-btn-secondary duration-500"
                                    onChange={handleSearchChange}
                                    style={{
                                        background: "rgba(0,0,0,0)",
                                        outline: "none",
                                    }}
                                    autoComplete="off"
                                    role="presentation"
                                />
                            </div>
                            <div className=" pr-2 h-full flex justify-center items-center">
                                <svg
                                    width="10"
                                    height="7"
                                    viewBox="0 0 10 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L5 5.5L9 1.5"
                                        stroke="#FFFBEF"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </button>
                        <ul
                            id="scroll-x"
                            className={`max-h-[200px] w-[103px] h-auto overflow-scroll custom-shadow dropdown-menu absolute text-gray-700 bg-bg-primary mt-2 rounded-[8px] custom-shadow z-50 ${
                                isHidden && "hidden"
                            } `}
                        >
                            {titleArr
                                ?.filter(
                                    (code) =>
                                        code.phoneCode.includes(searchValue) ||
                                        code.country
                                            .toLowerCase()
                                            .includes(searchValue) ||
                                        code.countryCode3
                                            .toLowerCase()
                                            .includes(searchValue) ||
                                        code.countryCode
                                            .toLowerCase()
                                            .includes(searchValue)
                                )
                                .map((each) => {
                                    return (
                                        <li>
                                            <a
                                                onClick={() =>
                                                    handleSelect(each.phoneCode)
                                                }
                                                onMouseUp={() => handleClick()}
                                                className="cursor-pointer text-txt-primary hover:bg-btn-secondary hover:text-txt-secondary py-2 px-4 flex items-center gap-1 whitespace-no-wrap"
                                                // href="#"
                                            >
                                                <img
                                                    src={`https://ghuddy-dev-attachments.s3.ap-southeast-2.amazonaws.com/flags/4x3/${each.countryCode.toLowerCase()}.svg`}
                                                    width={"20px"}
                                                    height={"15px"}
                                                />
                                                {each.phoneCode}
                                            </a>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </ReactOutsideClickHandler>
                <input
                    type="tel"
                    value={inputValue}
                    onChange={(e) => {
                        !isNaN(
                            e.target.value
                                .replace(/\s/g, "")
                                .replace(/-/g, "")
                                .replace(/\(/g, "")
                                .replace(/\)/g, "")
                        ) &&
                            setInputValue(
                                e.target.value
                                    .replace(/\s/g, "")
                                    .replace(/-/g, "")
                                    .replace(/\(/g, "")
                                    .replace(/\)/g, "")
                            );
                    }}
                    set
                    name={name}
                    id={`input${name}`}
                    className={`h-full w-full bg-[transparent] pl-6 outline-0 rounded-tr-md rounded-br-md validation focus:outline-0  border border-l-0 border-[#2a220e73] md:text-xs`}
                    placeholder="Contact Number"
                />
            </div>
            <div className="errorMessage validationError"></div>
        </div>
    );
    let readOnlyViewComponent = <p>{inputValue}</p>;
    return (
        <div className="w-full">
            <RootComponent
                component={component}
                readOnlyViewComponent={readOnlyViewComponent}
                warningText={warningText}
                warningType={warningType}
                isAdmin={isAdmin}
                isReadOnly={isReadOnly}
                rejectionArray={rejectionArray}
                setRejectionArray={setRejectionArray}
                name={name}
                warningView={warningView}
            />
        </div>
    );
}

export default SingleSelectPhone;
