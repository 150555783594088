import React from "react";
import PrivateRoute from "../../utils/privateRoute";
import Sidebar from "../common/Sidebar";

const AdminLayout = ({ Component }) => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="pl-[300px] w-full">
        <PrivateRoute
          Component={Component}
        />
      </div>
    </div>
  );
};

export default AdminLayout;
 