import React, { useEffect, useRef, useState } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
import ButtonFilled from "../ButtonFilled";
import MultiSelectObject from "./MultiSelectObject";

import RootComponent from "../rootComponent/RootComponent";
import { customValidationSingle } from "../../utils/customValidation";

function MultiSelectParentObjectReturn({
    data,
    selectedArray,
    setSelectedArray,
    placeholder,
    displayField,
    propertyName,
    type,
    warningText,
    isAdmin,
    isReadOnly,
    warningType,
    // rejectionArray,
    setRejectionArray,
    name,
    required = false,
    selectionKey="value",
}) {
    const [selectedString, setSelectedString] = React.useState("");
    const [visible, setVisible] = React.useState(false);
    const [width, setWidth] = React.useState(0);
    const [touched, setTouched] = React.useState(false);
    let temp;

    // hwlper state
    const [colorChange, setColorChange] = useState(false);
    // console.log(selectedArray.join(","), "selectedArray");

    const [inputFieldValue, setInputFieldValue] = useState("");
    const onchangeHandler = (e) => {
        setInputFieldValue(e.target.value);
    };
    const [sortedArray, setSortedArray] = useState([]);
    useEffect(() => {
        if (!visible) {
            setInputFieldValue("");
        }
    }, [visible]);
    // useEffect(() => {
    //  console.log(inputFieldValue, "inputFieldValue")
    //   if (inputFieldValue.length > 0) {
    //     console.log(data, "data");
    //     console.log(inputFieldValue, "inputFieldValue");
    //     const temp = data.filter((item) => {
    //       console.log(displayField ?(item[displayField].toLowerCase()).includes(inputFieldValue.toLowerCase()):[]);
    //       return (item[displayField?displayField:"label"].toLowerCase()).includes(inputFieldValue.toLowerCase());
    //     }//arr.filter(element => element.includes("+8"))
    //     );
    //     setSortedArray(temp);
    //   } else {
    //     setSortedArray(data);
    //   }
    // }, [inputFieldValue]);
    useEffect(() => {
        if (selectedArray.length == 0) {
            // setSelectedString(placeholder);
            setSelectedString("");
            setColorChange(false);
        }
        if (selectedArray && selectedArray.length > 0) {
            temp = selectedArray.map(function (item) {
                return displayField
                    ? ` ${item[displayField]}`
                    : ` ${item["label"]}`;
            });
            setSelectedString(temp.join(",").slice(0, 40));
            setColorChange(true);
        }
    }, [selectedArray]);
    // console.log(selectedArray, "dataerte");

    const submitValues = () => {
        if (selectedArray.length == 0) {
            // setSelectedString(placeholder);
            setSelectedString("");
            setColorChange(false);
        }
        if (selectedArray && selectedArray.length > 0) {
            temp = selectedArray.map(function (item) {
                return displayField
                    ? ` ${item[displayField]}`
                    : ` ${item["label"]}`;
            });
            setSelectedString(temp.join(",").slice(0, 40));
            setColorChange(true);
        }
        setVisible(false);
        required &&
            setTimeout(() => {
                customValidationSingle(name);
            }, 200);
    };

    const ref = useRef(null);
    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);

    const focusHandler = () => {
        const input = document.getElementById(`input${name}`);
        input.focus();
    };
    let component = (
        <ReactOutsideClickHandler
            onOutsideClick={() => {
                setVisible(false);
                touched && submitValues();
            }}
        >
            <div className="w-full flex flex-col  rounded-lg">
                <div
                    id={name}
                    className={`rounded-[8px] overflow-hidden inline-block pb-2 pt-2 ${
                        required && "required"
                    }`}
                >
                    <button
                        ref={ref}
                        onClick={() => {
                            setTouched(true);
                            setVisible(!visible);
                            focusHandler();
                        }}
                        className="w-full rounded-[8px] outline-custom black_box h-[49px] flex outline-[1px] outline-offset-[-1px] outline-none"
                        onKeyUp={(e) => {
                            if (e.keyCode == 32 || e.keyCode == 13) {
                                e.preventDefault();
                            }
                        }}
                    >
                        <div className="cursor-auto text-txt-hint validation text-left  py-3 h-full rounded-[8px] pl-3 flex items-center md:text-xs ">
                            <span className="w-full whitespace-nowrap">
                                {placeholder}{required ? "*" : null}:{" "}
                            </span>
                        </div>
                        <input
                            id={`input${name}`}
                            className={`w-full text-xs text-left pl-4 h-full  bg-[transparent] focus:outline-none active:outline-none ${
                                colorChange
                                    ? "text-txt-primary"
                                    : "text-txt-hint"
                            } `}
                            value={visible ? inputFieldValue : selectedString}
                            onClick={(e) => e.preventDefault()}
                            onChange={(e) => {
                                setInputFieldValue(e.target.value);
                                required &&
                                    setTimeout(() => {
                                        customValidationSingle(name);
                                    }, 200);
                            }}
                            onKeyUp={(e) => {
                                if (e.keyCode == 32 || e.keyCode == 13) {
                                    e.preventDefault();
                                }
                            }}
                        />

                        <div className="bg-btn-primary ml-auto py-4 w-[49px] h-full rounded-[8px] px-5 hover:bg-btn-secondary duration-500">
                            <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2 4.5L6 8.5L10 4.5"
                                    stroke="#FFFBEF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </button>
                    <div className="errorMessage validationError"></div>
                </div>
                {visible && (
                    <div
                        className={`pt-4 rounded-[32px] overflow-hidden mt-16 bg-bg-primary custom-shadow z-50 absolute`}
                        style={{ width: width }}
                    >
                        <div className=" px-6 h-[190px] overflow-scroll">
                            {data && data.length
                                ? data
                                      .filter((item) => {
                                          return item[
                                              displayField
                                                  ? displayField
                                                  : "label"
                                          ]
                                              .toLowerCase()
                                              .includes(
                                                  inputFieldValue.toLowerCase()
                                              );
                                      })
                                      .map((item, index) => {
                                          return (
                                              <MultiSelectObject
                                                  setInputFieldValue={
                                                      setInputFieldValue
                                                  }
                                                  displayField={displayField}
                                                  selectionKey={selectionKey}
                                                  key={index}
                                                  selectedArray={selectedArray}
                                                  setSelectedArray={
                                                      setSelectedArray
                                                  }
                                                  item={item}
                                                  data={data}
                                                  focusHandler={focusHandler}
                                              />
                                          );
                                      })
                                : null}
                        </div>
                        {visible && (
                            <button
                                onClick={() => submitValues()}
                                className="w-full py-2 bg-btn-primary rounded-[32px] h-[65px] text-bg-white hover:bg-btn-secondary active:bg-txt-secondary active:border active:border-btn-primary active:text-btn-primary"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                )}
            </div>
        </ReactOutsideClickHandler>
    );
    let readOnlyViewComponent = <p>{inputFieldValue}</p>;
    return (
        <div className="w-full">
            <RootComponent
                component={component}
                readOnlyViewComponent={readOnlyViewComponent}
                warningText={warningText}
                warningType={warningType}
                isAdmin={isAdmin}
                isReadOnly={isReadOnly}
                // rejectionArray={rejectionArray}
                setRejectionArray={setRejectionArray}
                name={name}
            />
        </div>
    );
}

export default MultiSelectParentObjectReturn;
