import React, { useState } from "react";
import ModalWithSingleInput from "../modal/ModalWithSingleInput";
import WarningIcon from "../warning/WarningIcon";

function RootComponentTable({
  isAdmin,
  isReadOnly,
  component,
  readOnlyViewComponent,
  warningText,
  warningType,
  rejectionArray,
  setRejectionArray,
  name,
}) {
  console.log("rejectionArray from root", rejectionArray);
  // for modal call
  const [modalTrigger, setModalTrigger] = useState(false);
  const handleRegistrationSubmit = () => {
    setModalTrigger(true);
  };
  function decideComponent() {
    if (isAdmin === true) {
      if (isReadOnly === true) {
        return readOnlyViewComponent;
      } else {
        return rejectionArray && rejectionArray[name] ? (
          <tr>
            {component}
            <div onClick={handleRegistrationSubmit}>
              <WarningIcon
                rejectionArray={rejectionArray}
                warningText={rejectionArray[name]}
                warningType={warningType ? warningType : "warning"}
                name={name}
              />
            </div>

            <ModalWithSingleInput
              setOpenDefault={setModalTrigger}
              openDefault={modalTrigger}
              name={name}
              setRejectionArray={setRejectionArray}
              rejectionArray={rejectionArray}
            />
          </tr>
        ) : (
          <tr>
            {component}

            <button
              onClick={handleRegistrationSubmit}
              className="bg-btn-warning rounded-[8px] h-[49px] px-4 my-2 col-span-1"
            >
              Reject
            </button>
            <ModalWithSingleInput
              setOpenDefault={setModalTrigger}
              openDefault={modalTrigger}
              name={name}
              setRejectionArray={setRejectionArray}
              rejectionArray={rejectionArray}
            />
          </tr>
        );
      }
    } else {
      return (
        <tr>
          {component}
          {rejectionArray && rejectionArray[name] && (
            <td>
              {" "}
              <WarningIcon
                rejectionArray={rejectionArray}
                warningText={rejectionArray[name]}
                warningType={warningType ? warningType : "warning"}
                name={name}
              />
            </td>
          )}
        </tr>
      );
    }
  }
  return <>{decideComponent()}</>;
}

export default RootComponentTable;
