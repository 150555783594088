/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { toastMessage } from "../../utils/toast";
import SingleSelectParentCategoryReturn from "../../components/singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturn";
import TopShortcutItem from "./comp/TopShortcutItem";
import { AiOutlineMail } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { BsGlobe, BsFillMapFill } from "react-icons/bs";

export default function AdminHotelDetails() {
    const [propertyInfo, setPropertyInfo] = useState();
    const [searchValue, setSearchValue] = useState("");

    const [hotelList, setHotelList] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState(null);

    const [arrivals, setArrivals] = useState([]);
    const [properties, setProperties] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [issues, setIssues] = useState([]);

    useEffect(() => {
        authAxios
            .get(`${apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN}?requestId=2`)
            .then((res) => {
                setHotelList(res.data.propertyDataList);
                setSelectedHotel(res.data.propertyDataList[0]);
            })
            .catch((err) => {
                toastMessage("Error fetching data", "error");
            });
    }, []);

    useEffect(() => {
        if (selectedHotel) {
            getPropertyDetails();
        }
    }, [selectedHotel]);

    const getPropertyDetails = () => {
        authAxios
            .get(
                `${apiEndPoint.ADMIN_BOOKING_DAHSBOARD}?propertyId=${selectedHotel?.id}`
            )
            .then((res) => {
                console.log(res.data);
                setArrivals(res.data.arrivals);
                setProperties(res.data.properties);
                setBookings(res.data.bookings);
                setIssues(res.data.issues);
            })
            .catch((err) => {});
    };

    return (
        <div className="container mx-auto">
            <div className="mt-20">
                <div className="relative w-full">
                    <SingleSelectParentCategoryReturn
                        warningView={false}
                        selectedValue={selectedHotel}
                        setSelectedValue={setSelectedHotel}
                        data={hotelList}
                        placeholder="Select Hotel"
                        displayField={"propertyName"}
                        selectedFieldName="id"
                    />
                </div>
                <div className="flex gap-2 w-full mt-20">
                    <div className="min-w-[500px]">
                        <div className="w-full custom-shadow rounded-[32px] py-6 px-6">
                            <p className="text-[35px]">
                                {selectedHotel?.propertyName}
                            </p>
                            <div className="flex flex-col gap-y-1 mt-4">
                                <div className="flex gap-2 items-center">
                                    <MdLocationOn />
                                    <div>{selectedHotel?.address?.street},</div>
                                    <div>{selectedHotel?.address?.admin1},</div>
                                    {selectedHotel?.address?.admin2}-
                                    {selectedHotel?.address?.zip}
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsFillMapFill />
                                    <a
                                        href={` https://www.google.com/maps/search/?api=1&query=${
                                            selectedHotel?.address?.latitude ||
                                            "23.8103"
                                        },${
                                            selectedHotel?.address?.longitude ||
                                            "90.4125"
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-[blue]"
                                    >
                                        Open in Google Map
                                    </a>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <AiOutlineMail />
                                    {selectedHotel?.email}
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsGlobe />
                                    <a
                                        href={`https://${selectedHotel?.website}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-[blue]"
                                    >
                                        {selectedHotel?.website}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <TopShortcutItem title={"Arrivals"} list={arrivals} /> */}
                    <TopShortcutItem
                        title={"Property  Info"}
                        list={properties}
                    />
                    <TopShortcutItem title={"Bookings"} list={bookings} />
                    <TopShortcutItem title={"Issues"} list={issues} />
                </div>
            </div>
        </div>
    );
}
