import React from "react";
import {
    SvgCross,
    SvgMail,
    SvgPDF,
    SvgPlus,
    SvgPrint,
} from "../components/common/AllSVGImport";
import RoundedDropdownButton from "../components/common/RoundedDropdownButton";
import { BookingCard } from "./BookingCard";
import addImage from "../assets/images/add_plus.png";
import SingleSelectParentCategoryReturnCalender from "../components/singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturnCalendar";
import Loading from "../components/Loader/Loading";

const invoiceStatusTypes = {
    PENDING_PAYMENT: "Payment Pending",
    BOOKING_REQUESTED: "New",
    INIT: "New",
    MERCHANT_CANCELED: "Merchant Cancelled",
    BOOKING_IN_PROGRESS: "In Progress",
    BOOKED: "Booked",
    CANCELED: "Cancelled",
    REJECTED: "Rejected",
};
const invoiceStatusColor = {
    PENDING_PAYMENT: "Payment Pending",
    BOOKING_REQUESTED: "New",
    MERCHANT_CANCELED: "Merchant Cancelled",
    BOOKING_IN_PROGRESS: "In Progress",
    BOOKED: "Booked",
    CANCELED: "Cancelled",
    REJECTED: "Rejected",
};

const DashboardBooking = ({
    setCalenderOverlay,
    onRefuseReject,
    removeOverlay,
    onConfirm,
    onReject,
    bookingItem,
    hotel,
    loading,
    showControl,
    type,
}) => {
    return (
        <div className="px-14 py-8 h-[768px] w-full">
            {loading ? (
                <div className="h-full w-full flex items-center justify-center">
                    <Loading />
                </div>
            ) : (
                <React.Fragment>
                    <div className="flex justify-between py-4">
                        <div className="text-[24px] font-normal">
                            Booking Details - <span className="text-bg-semiblack font-extralight">{ type === 'regular' ? bookingItem?.booking?.invoiceUuId : bookingItem?.booking?.invoiceUuid}</span> 
                        </div>
                        <div
                            onClick={() => removeOverlay()}
                            className="cursor-pointer"
                        >
                            <SvgCross />
                        </div>

                    </div>
                    <div className="grid grid-cols-4 gap-4 pb-4">
                        <div className="col-span-4">
                            <div className="flex justify-between">
                                <div className="">
                                    <p className="text-sm mb-2">Assign To:</p>
                                    <div className="">
                                        <img src={addImage} alt="" />
                                    </div>
                                </div>
                                <div className="flex gap-8 px-2 items-center z-[100]">
                                    <SvgPDF />
                                    <SvgPrint />
                                    <SvgMail />
                                    <div className="w-[300px]">
                                        <p className="text-lg text-bg-semiblack mb-2 capitalize">
                                            Status:{" "}
                                            {
                                                invoiceStatusTypes[
                                                    bookingItem?.booking
                                                        ?.invoiceStatus || bookingItem?.booking.status 
                                                ]
                                            }
                                        </p>
                                        {/* <RoundedDropdownButton
                                            title={
                                                bookingItem?.booking
                                                    ?.invoiceStatus
                                            }
                                            w="full"
                                            h="40px"
                                            dropdownData={[
                                                {
                                                    id: 1,
                                                    value: "BOOKED",
                                                    propertyName: "Booked",
                                                },
                                                {
                                                    id: 2,
                                                    value: "PENDING_PAYMENT",
                                                    propertyName: "In progress",
                                                },
                                                {
                                                    id: 3,
                                                    value: "REJECTED",
                                                    propertyName: "Rejected",
                                                },
                                                {
                                                    id: 4,
                                                    value: "INIT",
                                                    propertyName: "Other",
                                                },
                                            ]}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BookingCard
                        bookingItem={bookingItem}
                        onConfirm={onConfirm}
                        onReject={onReject}
                        onRefuseReject={onRefuseReject}
                        hotel={hotel}
                        removeOverlay={removeOverlay}
                        showControl={showControl}
                        type={type}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

export default DashboardBooking;
