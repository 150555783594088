/* eslint-disable react-hooks/exhaustive-deps */
import { format, parse } from "date-fns";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
// import ReactQuill from "react-quill";
import ButtonFilled from "../../components/ButtonFilled";
import { SvgCross } from "../../components/common/AllSVGImport";
import InputComponent from "../../components/InputComponent";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { DateFormatTranferToSixDigit } from "../../utils/DateFormatTranferToSixDigit";

function AdminInvoiceTool() {
    const [invoiceNr, setInvoiceNr] = useState("");
    const [orgName, setOrgName] = useState("");
    const [orgPhone, setOrgPhone] = useState("");
    const [orgEmail, setOrgEmail] = useState("");
    const [signature, setSignature] = useState("");
    const [reservation, setReservation] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [saved, setSaved] = useState(true);
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [dueDate, setDueDate] = useState(new Date());
    const [address, setaddress] = useState("");
    const [mode, setMode] = useState("create");
    const [paidAmount, setPaidAmount] = useState(0.0);
    const [discount, setDiscount] = useState(0.0);
    const [discountPercent, setDiscountPercent] = useState(0);
    const [subTotal, setSubtotal] = useState(0.0);
    const [invoiceTotal, setinvoiceTotal] = useState(0.0);
    const [items, setItems] = useState([]);
    const demoItem = {
        id: items.length + 1,
        description: "",
        price: 0.0,
        qty: 0,
        totalPrice: 0.0,
    };
    const [selectedItem, setSelectedItem] = useState(demoItem);

    // function createMarkup() {
    //     return { __html: address };
    // }

    useEffect(() => {
        setSaved(false);
        if (selectedItem.price && selectedItem.qty) {
            setSelectedItem({
                ...selectedItem,
                totalPrice: parseFloat(
                    selectedItem.qty * selectedItem.price
                ).toFixed(2),
            });
        }
    }, [selectedItem.price, selectedItem.qty]);

    useEffect(() => {
        setSaved(false);
        let total = 0;
        if (items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                total = total + parseFloat(element.totalPrice);
            }
        }
        setSubtotal(parseFloat(total).toFixed(2));
        setinvoiceTotal(parseFloat(total - discount - paidAmount).toFixed(2));
    }, [items]);

    useEffect(() => {
        const data = localStorage.getItem("invoiceData");
        if (data) {
            const parsed = JSON.parse(data);
            setOrgName(parsed.orgName);
            setOrgEmail(parsed.orgEmail);
            setOrgPhone(parsed.orgPhone);
            setSignature(parsed.signature);
            setReservation(parsed.reservation);
            setInvoiceDate(new Date(parsed.invoiceDate));
            setDueDate(new Date(parsed.dueDate));
            setaddress(parsed.address);
            setPaidAmount(parsed.paidAmount);
            setDiscount(parsed.discount);
            setDiscountPercent(parsed.discountPercent);
            setSubtotal(parsed.subTotal);
            setinvoiceTotal(parsed.invoiceTotal);
            setItems(parsed.items);
            localStorage.removeItem("invoiceData");
        }
    }, []);

    const printDiv = (divName) => {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        const data = {
            invoiceNr,
            orgName,
            orgPhone,
            orgEmail,
            signature,
            reservation,
            invoiceDate,
            dueDate,
            address,
            paidAmount,
            discount,
            discountPercent,
            subTotal,
            invoiceTotal,
            items,
        };
        localStorage.setItem("invoiceData", JSON.stringify(data));
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    const handleDiscount = (type, val) => {
        if (type === "amount") {
            setDiscount(val);
            setDiscountPercent(parseFloat((val / subTotal) * 100).toFixed(2));
        } else {
            setDiscountPercent(val);
            setDiscount(parseFloat(subTotal * (val / 100)).toFixed(2));
        }
    };

    useEffect(() => {
        setSaved(false);
        if (subTotal > 0) {
            setinvoiceTotal(
                parseFloat(subTotal - discount - paidAmount).toFixed(2)
            );
        }
    }, [discount, paidAmount]);

    const SaveInvoice = () => {
        const obj = {
            address: address,
            discountAmount: discount,
            dueDate: DateFormatTranferToSixDigit(dueDate),
            number: orgPhone,
            orgName: orgName,
            email: orgEmail,
            reservationNo:reservation,
            requestId: "string",
            tourServiceData: items.map((item) => {
                return {
                    description: item.description,
                    perQuantityPrice: item.price,
                    quantity: item.qty,
                };
            }),
        };
        authAxios
            .post(`${apiEndPoint.ADMIN_ADD_TOUR_SERVICE}`, obj)
            .then((res) => {
                setSaved(true);
                toast.success("Save successful!");
                setInvoiceNr(res.data.responseData.referenceId);
            })
            .catch((err) => {});
    };

    return (
        <div className="max-w-[110rem] mx-auto ">
            <div className="flex w-full gap-4">
                <div className="w-1/2 p-6 flex flex-col gap-6">
                    <div>
                        <div className="my-2">Invoice Date</div>
                        <ReactDatePicker
                            title="Invoice Date"
                            placeholderText="Invoice Date"
                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                            selected={invoiceDate}
                            onChange={(date) => setInvoiceDate(date)}
                        />
                        <div className="my-2">Invoice Due Date</div>
                        <ReactDatePicker
                            title="Invoice Due Date"
                            placeholderText="Invoice Due Date"
                            className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                            selected={dueDate}
                            onChange={(date) => setDueDate(date)}
                        />
                    </div>
                    <div>
                        <div className="font-bold text-xl">Bill To:</div>
                        <div className="py-2 flex flex-col gap-4">
                            <InputComponent
                                name={"orgName"}
                                inputValue={orgName}
                                setInputValue={(val) => setOrgName(val)}
                                warningView={false}
                                placeholder="Name"
                            />
                            <InputComponent
                                name={"orgPhone"}
                                inputValue={orgPhone}
                                setInputValue={(val) => {
                                    setOrgPhone(val);
                                }}
                                warningView={false}
                                placeholder="Phone"
                            />
                            <InputComponent
                                name={"orgEmail"}
                                inputValue={orgEmail}
                                setInputValue={(val) => setOrgEmail(val)}
                                warningView={false}
                                placeholder="Email"
                            />
                            <InputComponent
                                name={"address"}
                                inputValue={address}
                                setInputValue={(val) => setaddress(val)}
                                warningView={false}
                                placeholder="Address"
                            />
                            {/* <ReactQuill
                                theme="snow"
                                defaultValue={""}
                                value={address}
                                onChange={setaddress}
                                placeholder={"Adress..."}
                            /> */}
                        </div>
                    </div>
                    <div>
                        <div className="font-bold text-base">Services:</div>
                        <div className="text-txt-primary font-extralight py-2">
                            {items.map((item, index) => (
                                <div
                                    key={item.description + index}
                                    className="p-4 flex items-center gap-2 justify-between my-2 border border-[#e2e2e2] rounded-lg"
                                >
                                    <div className="w-[300px]">
                                        {item.description}
                                        <span className="ml-2">
                                            {item.price} x {item.qty} =
                                            {item.totalPrice}
                                        </span>
                                    </div>
                                    <div>
                                        <button
                                            className="shadow px-4 py-2 rounded-lg hover:bg-bg-semiwhite active:scale-95"
                                            onClick={() => {
                                                setMode("edit");
                                                setSelectedItem(item);
                                                setShowModal(true);
                                            }}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="shadow ml-2 text-[red] px-4 py-2 rounded-lg hover:bg-bg-semiwhite active:scale-95"
                                            onClick={() => {
                                                setItems(
                                                    items.filter(
                                                        (el) =>
                                                            el.id !== item.id
                                                    )
                                                );
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                            {items.length === 0 ? (
                                <div className="border border-[#e2e2e2] rounded-lg py-2 px-4 my-2">
                                    No services
                                </div>
                            ) : null}
                            <div className="flex justify-end">
                                <button
                                    className="shadow px-4 py-2 rounded-lg hover:bg-bg-semiwhite active:scale-95 "
                                    onClick={() => {
                                        setMode("create");
                                        setSelectedItem(demoItem);
                                        setShowModal(true);
                                    }}
                                >
                                    Add new
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className=" text-base mb-2">Invoice Signature</div>
                        <InputComponent
                            name={"sign"}
                            warningView={false}
                            inputValue={signature}
                            setInputValue={(val) => {
                                setSignature(val);
                            }}
                        />
                    </div>
                    <div>
                        <div className=" text-base mb-2">
                            Reservation Number
                        </div>
                        <InputComponent
                            name={"reservation"}
                            warningView={false}
                            inputValue={reservation}
                            setInputValue={(val) => {
                                setReservation(val);
                            }}
                        />
                    </div>
                    <div>
                        <div className="text-base mb-2">Discount</div>
                        <div className="flex gap-2 items-center">
                            <InputComponent
                                name={"discount"}
                                warningView={false}
                                inputValue={discount}
                                setInputValue={(val) => {
                                    // setDiscount(val);
                                    handleDiscount("amount", val);
                                }}
                                addSpan={true}
                                spanValue="BDT"
                            />
                            or
                            <InputComponent
                                name={"discountPercent"}
                                warningView={false}
                                inputValue={discountPercent}
                                setInputValue={(val) => {
                                    // setDiscountPercent(val);
                                    handleDiscount("percent", val);
                                }}
                                addSpan={true}
                                spanValue="%"
                            />
                        </div>
                    </div>

                    <div>
                        <div className=" text-base mb-2">Paid Amount</div>
                        <InputComponent
                            name={"paid"}
                            warningView={false}
                            inputValue={paidAmount}
                            setInputValue={(val) => {
                                setPaidAmount(val);
                            }}
                        /> 
                    </div>
                    <hr className="mt-8" />
                    <div className="flex justify-center gap-2 ">
                        {saved ? (
                            <button
                                className="bg-btn-primary hover:bg-btn-secondary px-4 py-1 rounded-lg text-bg-primary w-56"
                                onClick={() => printDiv("printable")}
                            >
                                Export
                            </button>
                        ) : null}

                        
                        {!saved ? (
                            <button
                                className="bg-btn-primary hover:bg-btn-secondary px-4 py-1 rounded-lg text-bg-primary w-56"
                                onClick={() => SaveInvoice()}
                            >
                                Save
                            </button>
                        ) : null}
                    </div>
                </div>
                <div
                    style={{ fontSize: "12px", lineHeight: "16px" }}
                    className="w-2/3 py-6"
                >
                    <div
                        className="border px-12 py-20 w-full bg-bg-white"
                        id="printable"
                    >
                        <div className="flex justify-between items-center">
                            <div className="text-3xl font-black uppercase tracking-[0.9rem]">
                                INVOICE
                            </div>
                            <div className="w-1/2 flex justify-end">
                                <div>
                                    <img src="/logo.svg" alt="logo" />
                                </div>
                            </div>
                        </div>
                        <hr className="my-2" />

                        <div className=" mt-4 flex flex-col gap-8">
                            <div className="flex justify-between gap-2">
                                <div className="w-1/2">
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Invoice NR:
                                        </div>
                                        <div className=""> {invoiceNr} </div>
                                    </div>
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Date:
                                        </div>
                                        <div className="">
                                            {format(
                                                new Date(invoiceDate),
                                                "d/M/yyyy"
                                            )}
                                        </div>
                                    </div>
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Due Date:
                                        </div>
                                        <div className="">
                                            {format(
                                                new Date(dueDate),
                                                "d/M/yyyy"
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <div className="flex gap-2 items-end">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Reservation no.:
                                        </div>
                                        <div className=""> {reservation} </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between gap-2 text-sm">
                                <div className="w-1/2 border p-2">
                                    <div className="uppercase font-bold tracking-widest">
                                        Bill to:
                                    </div>
                                    <div className="py-4">
                                        <div> {orgName} </div>
                                        <div>
                                            {address.length > 0
                                                ? "Address:"
                                                : null}{" "}
                                            {address}{" "}
                                        </div>
                                        <div>
                                            {orgPhone.length > 0
                                                ? "Phone:"
                                                : null}{" "}
                                            {orgPhone}{" "}
                                        </div>
                                        <div>
                                            {orgEmail.length > 0
                                                ? "Email:"
                                                : null}{" "}
                                            {orgEmail}{" "}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 flex justify-center p-2">
                                    <div>
                                        <div className="uppercase font-bold tracking-widest">
                                            Payable to:
                                        </div>
                                        <div className="py-4 tracking-wider">
                                            Ghuddy Limited <br />
                                            Rahman Villa <br />
                                            Ka-18, Baridhara North Road
                                            <br />
                                            Gulshan, Dhaka-1212
                                            <br />
                                            Bangladesh
                                            <br />
                                            +880 1797078379
                                            <br />
                                            support@ghuddy.com
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <table className="w-full">
                                    <thead>
                                        <tr>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                No.
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Description
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Price
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Qty.
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Total Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {items.map((item, index) => (
                                            <tr key={index} className="border">
                                                <td className="border p-2 text-center">
                                                    {index + 1}.
                                                </td>
                                                <td className="border p-2 text-start">
                                                    {item.description}
                                                </td>
                                                <td className="border p-2 text-center">
                                                    {item.price}
                                                </td>
                                                <td className="border p-2 text-center">
                                                    {item.qty}
                                                </td>
                                                <td className="border p-2 text-end px-4">
                                                    {item.totalPrice}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="flex justify-between gap-2">
                                <div>*All price VAT inclusive</div>
                                <div className="w-1/2">
                                    <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                        <div className="lowercase tracking-wider">
                                            subtotal:
                                        </div>
                                        <div className="text-end px-4">
                                            {subTotal}
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                        <div className="lowercase tracking-wider">
                                            discount:
                                        </div>
                                        <div className="text-end px-4">
                                            {discount}
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                        <div className="capitalize tracking-wider">
                                            paid:
                                        </div>
                                        <div className="text-end px-4">
                                            {parseFloat(paidAmount).toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b py-2 font-bold uppercase tracking-wider">
                                        <div className="">
                                            total payable amount:
                                        </div>
                                        <div className="text-end px-4">
                                            {invoiceTotal}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between gap-2">
                                <div className="w-1/2">
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            Account Name:
                                        </div>
                                        <div className=""> Ghuddy Limited </div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            bank name:
                                        </div>
                                        <div className="">Brac bank</div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            account:
                                        </div>
                                        <div className="">2301204588732001</div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            branch:
                                        </div>
                                        <div className="">Jamalpur branch</div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            Bkash merchant:
                                        </div>
                                        <div className="">+8801797078379</div>
                                    </div>
                                </div>
                                <div className="w-1/2 text-center flex flex-col justify-end">
                                    <div className="py-2"> {signature} </div>
                                    <div className="px-2 border-t py-2 ">
                                        <div className="font-bold uppercase tracking-wider">
                                            Thank you for your purchase
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ReactModal
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <div className="min-h-[200px] mx-auto min-w-[800px] py-8 break-words relative">
                    <div
                        onClick={() => {
                            setShowModal(false);
                        }}
                        className="cursor-pointer absolute right-8 top-4"
                    >
                        <SvgCross />
                    </div>

                    <div className="flex flex-col gap-4">
                        <div className="text-xl text-center">
                            {mode === "create" ? "Add" : "Edit"} Item
                        </div>
                        <InputComponent
                            name={"desc"}
                            warningView={false}
                            inputValue={selectedItem.description}
                            setInputValue={(val) => {
                                setSelectedItem({
                                    ...selectedItem,
                                    description: val,
                                });
                            }}
                            placeholder="Item description"
                        />
                        <InputComponent
                            name={"qty"}
                            warningView={false}
                            inputValue={selectedItem.qty}
                            setInputValue={(val) => {
                                setSelectedItem({
                                    ...selectedItem,
                                    qty: val,
                                });
                            }}
                            placeholder="Item quantity"
                        />
                        <InputComponent
                            name={"price"}
                            warningView={false}
                            inputValue={selectedItem.price}
                            setInputValue={(val) => {
                                setSelectedItem({
                                    ...selectedItem,
                                    price: val,
                                });
                            }}
                            placeholder="Item price"
                        />
                        <div className="flex justify-center">
                            <ButtonFilled
                                title={mode === "create" ? "Add" : "Edit"}
                                w="200px"
                                arrow={"no"}
                                buttonFilledHandle={() => {
                                    if (mode === "create") {
                                        setItems([...items, selectedItem]);
                                    } else {
                                        const index = items.findIndex(
                                            (el) => el.id === selectedItem.id
                                        );
                                        if (index > -1) {
                                            setItems(
                                                items.map((item, subIndex) => {
                                                    if (index === subIndex) {
                                                        return {
                                                            ...selectedItem,
                                                        };
                                                    } else {
                                                        return { ...item };
                                                    }
                                                })
                                            );
                                        }
                                    }
                                    setShowModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ReactModal>
        </div>
    );
}

export default AdminInvoiceTool;
