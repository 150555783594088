import React from "react";

const Checkbox = ({title,checked=true,handleChange}) => {
  return (
    <div className="">
      <div className="flex w-full items-center gap-2">
        <input
          className=""
          type="checkbox"
          id="flexCheckChecked"
          checked={checked}
          onChange={handleChange}
        />
        <label
          className="cursor-pointer w-full hover:text-txt-hint text-[18px] text-left"
          for="flexCheckChecked"
        >
          {title}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
