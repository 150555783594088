/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router";
import ButtonFilled from "../../../components/ButtonFilled";
import ButtonOutlined from "../../../components/ButtonOutlined";
import Loading from "../../../components/Loader/Loading";
import { adminRoute } from "../../../contants/Constants";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import { integerDateToValidDate } from "../../../utils/DateFormatTranferToSixDigit";
import Paginations from "../Paginations/Paginations";

function AdminInvoiceRecords() {
    // const [pageSize, setPageSize] = useState(5);
    let pageSize = 3;
    const [totalElements, setTotalElements] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [invoiceList, setInvoiceList] = useState([]);

    useEffect(() => {
        getInvoices(' ');
    }, [currentPage, pageSize]);

    useEffect(() => {
        getInvoices(' ');
    }, []);

    const navigate = useNavigate();


    const getInvoices = (phoneNumber) => {
        setLoading(true);
        authAxios
            .get(
                `${baseUrl + apiEndPoint.ADMIN_GET_INVOICE_BY_PHONE}/${phoneNumber}?pageNo=${currentPage}&pageSize=${pageSize}&requestId=11`
            )
            .then((res) => {
                //console.log(res.data,'data test');
                setInvoiceList(() => [...res.data.invoices]);
                setTotalElements(res.data.totalPages );
                console.log("as",res?.data)
                setLoading(false);
            })
            .catch((err) => {
                setInvoiceList([]);
                setLoading(false);
            });
    };

    const printDiv = (divName) => {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    console.log(invoiceList,totalElements)

    return (
        <div className="container mx-auto">
            <div className="flex gap-10 justify-between items-center">
                <div className="text-2xl">Invoices ({totalElements})</div>
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    getInvoices(searchValue);
                }}
                className="my-4 flex gap-2 items-center max-w-[750px]"
            >
                {/* <div> Search : </div> */}
                <input
                    className="px-4 flex-grow outline-none rounded-lg shadow-md bg-[transparent] focus:shadow-lg h-[50px]"
                    placeholder="Enter Mobile Number"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onFocus={()=>{setSearchValue("")}}
                />
                <ButtonFilled
                    title={"Search"}
                    type="submit"
                    w={"220px"}
                    arrow="no"
                />
            </form>
            <div className="pb-20">
                <div className="h-[550px] overflow-y-auto w-full">
                    {!loading ? (
                        <table
                            id="students"
                            className="min-w-full leading-normal"
                        >
                            <thead>
                                <tr className="sticky h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
                                    {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider"></th> */}
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                       Invoice Number DB
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Date
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                       Customer Name
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Email
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Phone Number
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Total Payable
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Total Paid
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        status
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Action
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                        Documents
                                    </th>

                                </tr>
                            </thead>
                            <tbody className="">
                                {invoiceList &&
                                    !loading &&
                                    invoiceList.map((booking, index) => {
                                        const {
                                            id,
                                            invoiceDate,
                                            customerName,
                                            email,
                                            phoneNumber,
                                            totalPayAble,
                                            paidAmount,
                                            status
                                        } = booking; //destructuring
                                        return (
                                            <tr
                                                key={id || index}
                                                className="border-b"
                                            >
                                                {/* <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                                    {currentPage * pageSize +
                                                        (index + 1)}
                                                    .
                                                </td> */}
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                                    {id}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                    {new Date(
                                                       invoiceDate
                                                    ).toDateString()}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                    {customerName}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                   {email}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                   {phoneNumber}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                                  {totalPayAble}  
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                                  {paidAmount}  
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                   {status}
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-[12px] text-left">
                                                  <ButtonFilled 
                                                  title={"Go to payment"}
                                                  w="100%"
                                                  buttonFilledHandle={() => {
                                                      // setSelectedInvoice(
                                                      //     invoiceList[1]
                                                      // );
                                                      // setShowModal(true);
                                                      navigate(`${adminRoute.ADMIN_CREATE_PAYMENT_SLIP}${id}`)
                                                  }}
                                                  />
                                                </td>
                                                <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                                <ButtonOutlined
                                                        title={"See details"}
                                                        w="100%"
                                                        buttonOutlinedHandle={() => {
                                                            // setSelectedInvoice(
                                                            //     invoiceList[1]
                                                            // );
                                                            // setShowModal(true);
                                                            navigate(`${adminRoute.ADMIN_SINGLE_INVOICE_DETAILS}${id}`)
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {invoiceList.length === 0 && !loading && (
                                    <tr className="border">
                                        <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                            No data
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <div className="w-full flex justify-center items-center">
                            <div>
                                <Loading />
                            </div>
                        </div>
                    )}
                </div>
                {/* <PaginationComponent
                    totalElements={totalElements}
                    currentPage={currentPage}
                    dataSize={invoiceList.length}
                    pageSize={pageSize}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    showCount={true}
                /> */}
                <Paginations
                currentPage={currentPage}
                totalCount={totalElements}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
                />
                {/* {loading && (

                )} */}
                <ReactModal
                    isOpen={showModal}
                    onRequestClose={() => {
                        setShowModal(false);
                        setSelectedInvoice(null);
                    }}
                    ariaHideApp={false}
                >
                    <div className="w-[1000px]">
                        <div
                            className="border px-12 py-20 w-full bg-bg-white"
                            id="printable"
                        >
                            <div className="flex justify-between">
                                <div className="text-4xl font-black uppercase tracking-[0.9rem]">
                                    INVOICE
                                </div>
                            </div>
                            <hr className="my-2" />

                            <div className="text-sm mt-4 flex flex-col gap-8">
                                <div className="flex justify-between">
                                    <div className="w-1/2">
                                        <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                            <div className="w-1/3 font-bold uppercase tracking-wider">
                                                Invoice NR:
                                            </div>
                                            <div className="">
                                                {selectedInvoice?.id}
                                            </div>
                                        </div>
                                        <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                            <div className="w-1/3 font-bold uppercase tracking-wider">
                                                Date:
                                            </div>
                                            <div className="">
                                                {selectedInvoice
                                                    ? format(
                                                          new Date(
                                                              selectedInvoice?.invoiceDate
                                                          ),
                                                          "d/M/yyyy"
                                                      )
                                                    : null}
                                            </div>
                                        </div>
                                        <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                            <div className="w-1/3 font-bold uppercase tracking-wider">
                                                Due Date:
                                            </div>
                                            <div className="">
                                                {selectedInvoice
                                                    ? format(
                                                          new Date(
                                                              integerDateToValidDate(
                                                                  selectedInvoice?.dueDate
                                                              )
                                                          ),
                                                          "d/M/yyyy"
                                                      )
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between gap-2">
                                    <div className="w-1/2 border p-2">
                                        <div className="uppercase font-bold tracking-widest">
                                            Bill to:
                                        </div>
                                        <div className="py-4">
                                            <div>
                                                {selectedInvoice?.customerName}
                                            </div>
                                            <div>
                                                {selectedInvoice?.address}
                                            </div>
                                            <div>{selectedInvoice?.phoneNumber}</div>
                                            <div>{selectedInvoice?.email}</div>
                                        </div>
                                    </div>
                                    <div className="w-1/2 flex justify-center p-2">
                                        <div>
                                            <div className="uppercase font-bold tracking-widest">
                                                Payable to:
                                            </div>
                                            <div className="py-4 tracking-wider">
                                                Ghuddy Limited <br />
                                                Rahman Villa <br />
                                                Ka-18, Baridhara North Road
                                                <br />
                                                Gulshan, Dhaka-1212
                                                <br />
                                                Bangladesh
                                                <br />
                                                +880 1797078379
                                                <br />
                                                support@ghuddy.com
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div>
                                    <table className="w-full">
                                        <thead>
                                            <tr>
                                                <th className="p-2 font-bold uppercase tracking-widest">
                                                    No.
                                                </th>
                                                <th className="p-2 font-bold uppercase tracking-widest">
                                                    Description
                                                </th>
                                                <th className="p-2 font-bold uppercase tracking-widest">
                                                    Price
                                                </th>
                                                <th className="p-2 font-bold uppercase tracking-widest">
                                                    Qty.
                                                </th>
                                                <th className="p-2 font-bold uppercase tracking-widest">
                                                    Total Price
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {selectedInvoice?.serviceMeta?.tourServiceMeta.map(
                                                (item, index) => (
                                                    <tr
                                                        key={index}
                                                        className="border"
                                                    >
                                                        <td className="border p-2 text-center">
                                                            {index + 1}.
                                                        </td>
                                                        <td className="border p-2 text-start">
                                                            {item.description}
                                                        </td>
                                                        <td className="border p-2 text-center">
                                                            {
                                                                item.perQuantityPrice
                                                            }
                                                        </td>
                                                        <td className="border p-2 text-center">
                                                            {item.quantity}
                                                        </td>
                                                        <td className="border p-2 text-end px-4">
                                                            {parseFloat(
                                                                item.perQuantityPrice *
                                                                    item.quantity
                                                            ).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div> */}

                                <div className="flex justify-between gap-2">
                                    <div>*All price VAT inclusive</div>
                                    <div className="w-1/2">
                                        <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                            <div className="lowercase tracking-wider">
                                                subtotal:
                                            </div>
                                            <div className="text-end px-4">
                                                {selectedInvoice?.subTotal}
                                            </div>
                                        </div>
                                        <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                            <div className="lowercase tracking-wider">
                                                discount:
                                            </div>
                                            <div className="text-end px-4">
                                                {
                                                    selectedInvoice?.discountAmount
                                                }
                                            </div>
                                        </div>
                                        <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                            <div className="capitalize tracking-wider">
                                                paid:
                                            </div>
                                            <div className="text-end px-4">
                                                {selectedInvoice?.paidAmount
                                                    ? parseFloat(
                                                          selectedInvoice?.paidAmount
                                                      ).toFixed(2)
                                                    : 0.0}
                                            </div>
                                        </div>
                                        <div className="flex justify-between border-b py-2 font-bold uppercase tracking-wider">
                                            <div className="">
                                                total amount:
                                            </div>
                                            <div className="text-end px-4">
                                                {selectedInvoice?.invoiceTotal}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between gap-2">
                                    <div className="w-1/2">
                                        <div className="px-2 flex gap-2 py-1 ">
                                            <div className="font-bold uppercase w-1/3 tracking-wider">
                                                Account Name:
                                            </div>
                                            <div className="">
                                                Ghuddy Limited
                                            </div>
                                        </div>
                                        <div className="px-2 flex gap-2 py-1 ">
                                            <div className="font-bold uppercase w-1/3 tracking-wider">
                                                bank name:
                                            </div>
                                            <div className="">Brac bank</div>
                                        </div>
                                        <div className="px-2 flex gap-2 py-1 ">
                                            <div className="font-bold uppercase w-1/3 tracking-wider">
                                                account:
                                            </div>
                                            <div className="">
                                                2301204588732001
                                            </div>
                                        </div>
                                        <div className="px-2 flex gap-2 py-1 ">
                                            <div className="font-bold uppercase w-1/3 tracking-wider">
                                                branch:
                                            </div>
                                            <div className="">
                                                Jamalpur branch
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/2 text-center flex flex-col justify-end">
                                        <div className="py-2">
                                            {selectedInvoice?.signature}
                                        </div>
                                        <div className="px-2 border-t py-2 ">
                                            <div className="font-bold uppercase tracking-wider">
                                                Thank you for your purchase
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center my-2">
                            <button
                                className="bg-btn-primary hover:bg-btn-secondary px-4 py-1 rounded-lg text-bg-primary w-56"
                                onClick={() => printDiv("printable")}
                            >
                                Export
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        </div>
    );
}

export default AdminInvoiceRecords;
