import { parseISO } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { format } from 'util';
import ButtonFilled from '../../../components/ButtonFilled';
import Loading from '../../../components/Loader/Loading';
import { apiEndPoint, baseUrl } from '../../../contants/Endpoints';
import { authAxios } from '../../../utils/axiosWrapper';
import DateFormatterSlash from '../../../utils/DateFormatterSlash';
import AdminPaymentSlipPrinted from './AdminPaymentSlipPrinted';
import ReactModal from 'react-modal';

const AdminInvoiceDetails = () => {
    const [invoiceDetails,setInvoiceDetails] =useState([]);
    const [loading,setLoading]= useState(true);
    const [showSlip,setShowSlip] = useState(false);
    const [selectedSlip,setSelectedSlip] = useState();

    const {id} =useParams();

    const printDiv = (divName) => {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        // const data = {
        //     invoiceNr,
        //     orgName,
        //     orgPhone,
        //     orgEmail,
        //     signature,
        //     reservation,
        //     invoiceDate,
        //     dueDate,
        //     address,
        //     paidAmount,
        //     discount,
        //     discountPercent,
        //     subTotal,
        //     invoiceTotal,
        //     items,
        // };
        // localStorage.setItem("invoiceData", JSON.stringify(data));
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    const printDiv2 =(divName)=>{
        const printDiv =  document.getElementById(divName);
        document.body.style.visibility = "hidden";
        document.getElementById(divName).style.visibility = "visible";
        document.getElementById(divName).style.position = "absolute";
        document.getElementById(divName).style.left = "0";
        document.getElementById(divName).style.top = "0";
        window.print();
        navigateFunc();

        // var divContents = document.getElementById(divName).innerHTML;
        //       var a = window.open('', '', 'height=500, width=500');
        //       a.document.write('<html>');
        //       a.document.write('<body > <h1>Div contents are <br>');
        //       a.document.write(divContents);
        //       a.document.write('</body></html>');
        //       a.document.close();
        //       a.print();
      }


      // const printDiv = (divName) => {
      //   var printContents = document.getElementById(divName).innerHTML;
      //   var originalContents = document.body.innerHTML;
      //   document.body.innerHTML = printContents;
      //   window.print();
      //   document.body.innerHTML = originalContents;
      //   navigateFunc()

      // };
    const navigateFunc =()=>{
      document.body.style.visibility = "visible";
      window.location.reload();
      setShowSlip(false);
    }



    useEffect(()=>{
        authAxios
        .get(
            `${baseUrl + apiEndPoint.ADMIN_GET_SINGLE_INVOICE_DETAILS}/${id}?requestId=11`
        )
        .then((res) => {
            console.log(res.data?.payments[1]?.paymentDate,'data res');
            setInvoiceDetails(res.data);
            setLoading(false);
        })
        .catch((err) => {
            setInvoiceDetails([]);
            setLoading(false);
        });
    },[])





    return (
        <div className="px-[16px]  min-h-screen pb-[20px] ">
        <div className="max-w-[1100px]  mx-auto">
          <h2 className="text-center text-[22px] pt-[30px] underline">
            Invoice Details
          </h2>

          <div>
            {loading ?
                <div className="w-full flex justify-center items-center ">
                <div>
                    <Loading />
                </div>
                </div>

            :
            <div className=''>

            <h2 className='text-[20px] mb-2'>Payments history : </h2>
            <div className='w-full flex flex-wrap gap-8'>
                {invoiceDetails?.payments?.length >0 ?
                (invoiceDetails?.payments?.map((paymentSlip)=>
                    <div className='shadow-lg  bg-[#FFFFFF] w-[330px]   '>
                         <div className='bg-btn-secondary text-bg-primary text-center flex justify-between items-center pl-[10px] '>
                            <div>{DateFormatterSlash(paymentSlip?.paymentDate)}</div>
                            <div onClick={()=>{
                                setSelectedSlip(paymentSlip);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                setShowSlip(true);
                            }} className='px-[10px]  bg-btn-primary py-[4px] cursor-pointer hover:border-txt-hint hover:shadow-lg  border border-btn-secondary'>Download</div>
                        </div>


                        <div className="w-full text-[12px] px-[10px] py-[14px] ">
                                    <div className=" flex gap-2 border-[#e2e2e2] py-[8px]">
                                        <div className="w-1/2 font-bold  tracking-wider">
                                           External Reference:
                                        </div>
                                        <div className=""> {paymentSlip?.externalReference} </div>
                                    </div>
                                    <div className=" flex gap-2 border-t border-[#e2e2e2] py-[8px]">
                                        <div className="w-1/2 font-bold  tracking-wider">
                                           Payment Method
                                        </div>
                                        <div className="">
                                        {paymentSlip?.paymentMethod}
                                        </div>
                                    </div>
                                    <div className="flex gap-2 border-t border-[#e2e2e2]  py-[8px]">
                                        <div className="w-1/2 font-bold  tracking-wider">
                                           Paid Amount
                                        </div>
                                        <div className="">
                                           {paymentSlip?.paidAmount}
                                        </div>
                                    </div>
                                    <div className=" flex gap-2 border-t border-[#e2e2e2]  py-[8px]">
                                        <div className="w-1/2 font-bold  tracking-wider">
                                            Received Amount
                                        </div>
                                        <div className="">
                                        {paymentSlip?.receivedAmount}
                                        </div>
                                    </div>
                                    <div className=" flex gap-2 border-t border-[#e2e2e2] py-[8px] ">
                                        <div className="w-1/2 font-bold  tracking-wider">
                                            Fee
                                        </div>
                                        <div className="">
                                        {paymentSlip?.fee}
                                        </div>
                                    </div>
                        </div>
                    </div>
                )) :
                <div className='text center text-[28px] text-btn-warning'>
                    No successful payment received yet.
                </div>

            }
            </div>
              <div className='w-full flex justify-end pt-[2rem]'>
              <button className='border py-[8px] w-full bg-btn-primary hover:bg-btn-secondary text-bg-primary cursor-pointer ' onClick={()=>{
               printDiv("printableInvoice")

              }}>Click here to Print Invoice</button>
              </div>


             {/* review data */}
                <div
                    style={{ fontSize: "12px", lineHeight: "16px" }}
                    className="w-full pb-6"
                >
                    <div
                        className=" px-12 py-20 w-full bg-bg-white"
                        id="printableInvoice"
                    >
                        <div className="flex justify-between items-center">

                            <div className="w-1/2 ">
                                <div>
                                    <img src="/logo.svg" alt="logo" />
                                </div>
                            </div>
                            <div className="text-xl font-black uppercase tracking-[0.5rem]">
                                INVOICE
                            </div>
                        </div>
                        <hr className="my-2" />

                        <div className=" mt-4 flex flex-col gap-8">
                            <div className="flex justify-between gap-2">
                                <div className="w-full">
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Invoice NR:
                                        </div>
                                        <div className=""> {invoiceDetails?.invoiceNr} </div>
                                    </div>
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Booking Code:
                                        </div>
                                        <div className="">
                                        {invoiceDetails?.bookingCode}
                                        </div>
                                    </div>
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                           Invoice Date:
                                        </div>
                                        <div className="">
                                            {DateFormatterSlash(invoiceDetails?.invoiceDate)}
                                        </div>
                                    </div>
                                    <div className="px-2 flex gap-2 border-b border-[#e2e2e2] py-2 ">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Due Date:
                                        </div>
                                        <div className="">
                                        {DateFormatterSlash(invoiceDetails?.dueDate)}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-1/2">
                                    <div className="flex gap-2 items-end">
                                        <div className="w-1/3 font-bold uppercase tracking-wider">
                                            Reservation no.:
                                        </div>
                                        <div className=""> {reservation} </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className="flex justify-between gap-2 text-sm">
                                <div className="w-1/2 border  p-2">
                                    <div className="uppercase font-bold tracking-widest text-btn-primary">
                                    Bill to:
                                    </div>
                                    <div className="py-4 space-y-[5px]">
                                        <div>Customer Name : {invoiceDetails?.customerName} </div>
                                        <div>
                                            Address : {invoiceDetails?.address}
                                        </div>
                                        <div>
                                            Phone : {invoiceDetails?.phoneNumber}
                                        </div>
                                        <div>
                                            Email : {invoiceDetails?.email}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 flex justify-center p-2 ">
                                    <div>
                                    <div className="uppercase font-bold tracking-widest text-btn-primary">
                                            Payable to:
                                        </div>
                                        <div className="py-4 tracking-wider">
                                            Ghuddy Limited <br />
                                            Rahman Villa, Ka-18, Baridhara North Road
                                            <br />
                                            Gulshan, Dhaka-1212, Bangladesh
                                            <br />
                                            +880 1797078379
                                            <br />
                                            support@ghuddy.com
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='p-2'>
                            <div className="uppercase font-bold tracking-widest text-btn-primary text-sm">
                                        Order details:
                                    </div>
                                <table className="w-full">
                                    <thead>
                                        <tr>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                No.
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Description
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Price
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Qty.
                                            </th>
                                            <th className="p-2 font-bold uppercase tracking-widest">
                                                Total Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {invoiceDetails?.purchasedServices?.map((item, index) => (
                                            <tr key={index} className="border">
                                                <td className="border p-2 text-center">
                                                    {index + 1}.
                                                </td>
                                                <td className="border p-2 text-start">
                                                    {item.description}
                                                </td>
                                                <td className="border p-2 text-center">
                                                    {item.perQuantityPriceAftDiscount}
                                                </td>
                                                <td className="border p-2 text-center">
                                                    {item.quantity}
                                                </td>
                                                <td className="border p-2 text-end px-4">
                                                    {item.perQuantityPriceAftDiscount * item.quantity}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="flex justify-between gap-2">
                                <div>*All price VAT inclusive</div>
                                <div className="w-1/2">
                                    <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                        <div className="lowercase tracking-wider">
                                            subtotal:
                                        </div>
                                        <div className="text-end px-4">
                                            {invoiceDetails?.totalAmount}
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                        <div className="lowercase tracking-wider">
                                            discount:
                                        </div>
                                        <div className="text-end px-4">
                                            {invoiceDetails?.discountAmount}
                                        </div>
                                    </div>
                                    {/* <div className="flex justify-between border-b border-[#e2e2e2] py-2 ">
                                        <div className="capitalize tracking-wider">
                                            paid:
                                        </div>
                                        <div className="text-end px-4">
                                            {parseFloat(paidAmount).toFixed(2)}
                                        </div>
                                    </div> */}
                                    <div className="flex justify-between border-b py-2 font-bold uppercase tracking-wider">
                                        <div className="">
                                            total payable amount:
                                        </div>
                                        <div className="text-end px-4">
                                            {invoiceDetails?.totalPayAble}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between gap-2">
                                <div className="w-1/2">
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            Account Name:
                                        </div>
                                        <div className=""> Ghuddy Limited </div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            bank name:
                                        </div>
                                        <div className="">Brac bank</div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            account:
                                        </div>
                                        <div className="">2301204588732001</div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            branch:
                                        </div>
                                        <div className="">Jamalpur branch</div>
                                    </div>
                                    <div className="px-2 flex gap-2 py-1 ">
                                        <div className="font-bold uppercase w-1/3 tracking-wider">
                                            Bkash merchant:
                                        </div>
                                        <div className="">+8801797078379</div>
                                    </div>
                                </div>
                                <div className="w-1/2 text-center flex flex-col justify-end">
                                    <div className="py-2"> {invoiceDetails?.signedBy} </div>
                                    <div className="px-2 border-t py-2 ">
                                        <div className="font-bold uppercase tracking-wider">
                                            Thank you for your purchase
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            }



          </div>



          </div>

        {showSlip &&
        // <ReactModal
        // style={{ content: { width: "600px" , height: "100vh" } }}
        // isOpen={showSlip}
        // onRequestClose={() => {
        //  setShowSlip(false);
        // }}
         //>
         <div className='absolute top-0 left-0 flex justify-center items-center w-full bg-bg-semiwhite'>

        <div className='shadow-xl'>
            <AdminPaymentSlipPrinted
            receiptNr={selectedSlip?.slipNr}
            paymentDate={selectedSlip?.paymentDate}
            externalReference={selectedSlip?.externalReference}
            invoiceDetails={invoiceDetails}
            paidAmount={selectedSlip?.paidAmount}
            receivedAmount={selectedSlip?.receivedAmount}
            fee={selectedSlip?.fee}
            />
            <div className='flex justify-between '>
            <button onClick={()=>{

                printDiv2("printable")

            }} className='w-1/2 bg-[#81B622] py-[10px]'>
                Download Now
            </button>
            <button onClick={()=>{
                setShowSlip(false);
            }} className='w-1/2 bg-[#f54b42] py-[10px]'>Cancel</button>
            </div>
        </div>



        </div>

    //   </ReactModal>



        }
        </div>
    );
};

export default AdminInvoiceDetails;
