import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "../../../../App.css";
import AvailabilityModal from "../../../../components/modal/EditPriceModal";
import AvailablityCalendar from "../../../AvailablityCalendar";
import { CarouselSlider } from "../../CarouselSlider";
import { Link } from "react-router-dom";
import { DashboardRoutes, merchantRoute } from "../../../../contants/Constants";
import { format, addDays } from "date-fns";

const HotelCardWithoutBg = ({ hotelShow, hotelList }) => {
    const [overlay, setOverlay] = useState(false);
    const [calenderOverlay, setCalenderOverlay] = useState(false);
    // const [selectedHotel, setSelectedHotel] = useState()

    const showSideMenu = () => {
        const menuSide = document.getElementById("hotelSideMenu");
        menuSide.classList.toggle("translate-x-[101%]");
    };

    useEffect(() => {
        if (calenderOverlay === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [calenderOverlay]);

    return (
        <>
            <div className="grid grid-cols-2 gap-4 custom-shadow rounded-[24px]">
                <div className="h-[100%]">
                    <CarouselSlider
                        imageList={
                            hotelShow && hotelShow.images
                                ? hotelShow.images
                                : []
                        }
                    />
                </div>
                <div className=" h-full rounded-r-3xl overflow-hidden relative">
                    <div className="flex justify-between h-full">
                        <div className="pt-4 pl-4">
                            <p className="text-xl">
                                {hotelShow ? hotelShow.propertyName : ""}
                            </p>
                            <p className="text-xs pb-8">
                                {hotelShow &&
                                hotelShow.address &&
                                hotelShow.address.place
                                    ? hotelShow.address.place
                                    : ""}
                                ,{" "}
                                {hotelShow &&
                                hotelShow.address &&
                                hotelShow.address.admin2
                                    ? hotelShow.address.admin2
                                    : ""}
                                -
                                {hotelShow &&
                                hotelShow.address &&
                                hotelShow.address.zip
                                    ? hotelShow.address.zip
                                    : ""}
                            </p>
                            <div className="flex text-xs pb-10">
                                <div className="pr-1">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M21.2831 8.27657L15.3323 7.41173L12.6722 2.01876C12.5995 1.8711 12.48 1.75157 12.3323 1.67891C11.962 1.4961 11.512 1.64845 11.3268 2.01876L8.66669 7.41173L2.7159 8.27657C2.55184 8.30001 2.40184 8.37735 2.28699 8.49454C2.14815 8.63724 2.07165 8.82923 2.07428 9.02831C2.07692 9.22739 2.15849 9.41728 2.30106 9.55626L6.60653 13.7539L5.58934 19.6813C5.56549 19.8191 5.58075 19.961 5.63338 20.0906C5.68602 20.2203 5.77394 20.3326 5.88716 20.4148C6.00038 20.497 6.13437 20.5459 6.27395 20.5558C6.41353 20.5658 6.5531 20.5364 6.67684 20.4711L11.9995 17.6727L17.3222 20.4711C17.4675 20.5484 17.6362 20.5742 17.7979 20.5461C18.2058 20.4758 18.48 20.0891 18.4097 19.6813L17.3925 13.7539L21.6979 9.55626C21.8151 9.44142 21.8925 9.29142 21.9159 9.12735C21.9792 8.7172 21.6933 8.33751 21.2831 8.27657Z"
                                            fill="#2A220E"
                                        />
                                    </svg>
                                </div>
                                <p className="pr-2">4.9</p>
                                <p>(144 Reviews)</p>
                            </div>
                            <div className="flex text-xs pb-2">
                                <p>
                                    {hotelShow && hotelShow.numberOfRooms
                                        ? hotelShow.numberOfRooms
                                        : "0"}{" "}
                                    Rooms
                                </p>
                                <div>
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.48781 7C5.07317 7 4.71951 6.85714 4.42683 6.57143C4.14228 6.27755 4 5.92245 4 5.50612C4 5.08163 4.14228 4.72653 4.42683 4.44082C4.71951 4.14694 5.07317 4 5.48781 4C5.91057 4 6.26829 4.14694 6.56098 4.44082C6.85366 4.72653 7 5.08163 7 5.50612C7 5.92245 6.85366 6.27755 6.56098 6.57143C6.26829 6.85714 5.91057 7 5.48781 7Z"
                                            fill="#2A220E"
                                        />
                                    </svg>
                                </div>
                                <p>Up to 6 Guests</p>
                                <div>
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.48781 7C5.07317 7 4.71951 6.85714 4.42683 6.57143C4.14228 6.27755 4 5.92245 4 5.50612C4 5.08163 4.14228 4.72653 4.42683 4.44082C4.71951 4.14694 5.07317 4 5.48781 4C5.91057 4 6.26829 4.14694 6.56098 4.44082C6.85366 4.72653 7 5.08163 7 5.50612C7 5.92245 6.85366 6.27755 6.56098 6.57143C6.26829 6.85714 5.91057 7 5.48781 7Z"
                                            fill="#2A220E"
                                        />
                                    </svg>
                                </div>
                                <p>45 sq.</p>
                            </div>
                            <div className="flex text-xs pb-2">
                                <p>Breakfast</p>
                                <div>
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.48781 7C5.07317 7 4.71951 6.85714 4.42683 6.57143C4.14228 6.27755 4 5.92245 4 5.50612C4 5.08163 4.14228 4.72653 4.42683 4.44082C4.71951 4.14694 5.07317 4 5.48781 4C5.91057 4 6.26829 4.14694 6.56098 4.44082C6.85366 4.72653 7 5.08163 7 5.50612C7 5.92245 6.85366 6.27755 6.56098 6.57143C6.26829 6.85714 5.91057 7 5.48781 7Z"
                                            fill="#2A220E"
                                        />
                                    </svg>
                                </div>
                                <p>Pet Friendly</p>
                                <div>
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.48781 7C5.07317 7 4.71951 6.85714 4.42683 6.57143C4.14228 6.27755 4 5.92245 4 5.50612C4 5.08163 4.14228 4.72653 4.42683 4.44082C4.71951 4.14694 5.07317 4 5.48781 4C5.91057 4 6.26829 4.14694 6.56098 4.44082C6.85366 4.72653 7 5.08163 7 5.50612C7 5.92245 6.85366 6.27755 6.56098 6.57143C6.26829 6.85714 5.91057 7 5.48781 7Z"
                                            fill="#2A220E"
                                        />
                                    </svg>
                                </div>
                                <p>No Smoking</p>
                            </div>
                            <div className="pt-8 text-xs">
                                <p>Flexible Cancellation Policy</p>
                            </div>
                            <div className="pt-4 text-xs">
                                <p>
                                    Starting from{" "}
                                    <span className="text-sm">120 USD</span> per
                                    Night
                                </p>
                            </div>
                        </div>

                        <div
                            onClick={() => showSideMenu()}
                            className="p-4 cursor-pointer"
                        >
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26C17.1046 26 18 25.1046 18 24Z"
                                    fill="#376576"
                                />
                                <path
                                    d="M18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16Z"
                                    fill="#376576"
                                />
                                <path
                                    d="M18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8Z"
                                    fill="#376576"
                                />
                            </svg>
                        </div>
                        <div
                            id="hotelSideMenu"
                            className="bg-btn-primary absolute right-0 h-full transform translate-x-[101%] duration-300"
                        >
                            <div className="px-4 flex flex-col justify-start pt-12 gap-4 h-full relative">
                                <div
                                    onClick={() => showSideMenu()}
                                    className="flex justify-start absolute left-2 top-2"
                                >
                                    <svg
                                        className="fill-bg-white cursor-pointer"
                                        width="36"
                                        height="36"
                                        viewBox="0 0 36 36"
                                        // fill="none"
                                        color="white"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.4109 17.9993L26.701 10.7093C26.8648 10.518 26.9504 10.2719 26.9407 10.0202C26.9309 9.76856 26.8266 9.52982 26.6485 9.35172C26.4704 9.17363 26.2317 9.06929 25.98 9.05957C25.7283 9.04985 25.4823 9.13546 25.291 9.29928L18.0009 16.5893L10.711 9.28928C10.5196 9.12546 10.2736 9.03985 10.0219 9.04957C9.77022 9.0593 9.53148 9.16363 9.35339 9.34172C9.17529 9.51982 9.07096 9.75855 9.06124 10.0102C9.05152 10.2619 9.13712 10.508 9.30095 10.6993L16.591 17.9993L9.29095 25.2893C9.18627 25.3789 9.10125 25.4893 9.04122 25.6133C8.9812 25.7374 8.94747 25.8725 8.94215 26.0102C8.93683 26.1479 8.96004 26.2853 9.01031 26.4136C9.06059 26.5419 9.13685 26.6585 9.2343 26.7559C9.33175 26.8534 9.4483 26.9296 9.57663 26.9799C9.70495 27.0302 9.84228 27.0534 9.98 27.0481C10.1177 27.0428 10.2529 27.009 10.3769 26.949C10.501 26.889 10.6113 26.804 10.701 26.6993L18.0009 19.4093L25.291 26.6993C25.4823 26.8631 25.7283 26.9487 25.98 26.939C26.2317 26.9293 26.4704 26.8249 26.6485 26.6468C26.8266 26.4688 26.9309 26.23 26.9407 25.9783C26.9504 25.7267 26.8648 25.4806 26.701 25.2893L19.4109 17.9993Z"
                                            fill="#eec"
                                        />
                                    </svg>
                                </div>
                                <p className="text-txt-secondary cursor-pointer">
                                    <Link
                                        to={
                                            DashboardRoutes.HOTEL_DETAILS +
                                            hotelShow.id
                                        }
                                    >
                                        Edit Hotel
                                    </Link>
                                </p>
                                <p
                                    className="text-txt-secondary cursor-pointer"
                                    onClick={() => {
                                        setOverlay(true);
                                        showSideMenu();
                                    }}
                                >
                                    Edit Price{" "}
                                </p>
                                <p
                                    className="text-txt-secondary cursor-pointer"
                                    onClick={() => {
                                        setCalenderOverlay(true);
                                        showSideMenu();
                                    }}
                                >
                                    Availablity
                                </p>
                                <p
                                    className="text-txt-secondary cursor-pointer"
                                    onClick={() => {
                                        showSideMenu();
                                        window.open(
                                            `${
                                                process.env.REACT_APP_FRONT_END
                                            }details?hotel=${
                                                hotelShow.propertyName
                                            }&location=dhaka&start=${format(
                                                addDays(new Date(), 1),
                                                "dd MMM, Y"
                                            )}&end=${format(
                                                addDays(new Date(), 3),
                                                "dd MMM, Y"
                                            )}&adult=2&child=0&propertyId=${
                                                hotelShow.id
                                            }`,
                                            "_blank"
                                        );
                                    }}
                                >
                                    Preview
                                </p>
                                {/* <p className="text-txt-secondary cursor-pointer">Price </p> */}
                                <p className="text-txt-secondary cursor-pointer">
                                    <Link to={DashboardRoutes.CATEGORIES}>
                                        Edit Categories
                                    </Link>
                                </p>
                            </div>

                            {overlay && (
                                <AvailabilityModal
                                    openDefault={overlay}
                                    setOpenDefault={setOverlay}
                                    hotelToShow={hotelShow}
                                    hotelList={hotelList}
                                />
                            )}
                            {calenderOverlay && (
                                <ReactModal
                                    isOpen={calenderOverlay}
                                    onRequestClose={() =>
                                        setCalenderOverlay(false)
                                    }
                                >
                                    <AvailablityCalendar
                                        hotelShow={hotelShow}
                                        setCalenderOverlay={setCalenderOverlay}
                                        key={Math.random}
                                    ></AvailablityCalendar>
                                </ReactModal>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HotelCardWithoutBg;
