import React, { useEffect } from "react";
import { customValidationSingle } from "../../utils/customValidation";

function SingleSelectSimpleArray({
  item,
  setSelectedValue,
  setVisible,
  setDisplay,
  validation=null,
  name,
}) {
  const handleSelect = () => {
    setSelectedValue(item);
    setDisplay(item);
    setVisible(false);
    if (validation) {
      setTimeout(()=>{
        customValidationSingle(name);
      },200)
    }
  };
  return (
    <div className="py-2 hover:bg-btn-secondary hover:text-txt-secondary cursor-pointer px-4 simple-items" onClick={handleSelect}>
      {item}
    </div>
  );
}

export default SingleSelectSimpleArray;
