import React, { useEffect, useState } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
import { customValidationSingle } from "../../utils/customValidation";
function SingleSelectPhoneTwo({
    inputValue,
    setInputValue,
    title,
    setTitle,
    dataArray,
    id,
    confirmed = false,
    showOtpModal,
    register = false,
    minDigit = 9,
    name,
    required = false,
}) {
    const [isHidden, setIsHidden] = useState(true);
    const [searchValue, SetSearchValue] = useState("");
    const handleClick = (e) => {
        e.preventDefault();
        if (isHidden) {
            try {
                const input = document.getElementById(id ? id : "input_focus");
                input.focus();
            } catch (e) {}
        } else {
            try {
                const input = document.getElementById(id ? id : "input_focus");
                input.focus("none");
            } catch (e) {}
        }
        setIsHidden(!isHidden);
    };
    const handleSelect = (val) => {
        setTitle(val);
        setIsHidden(!isHidden);
    };
    const handleSearchChange = (e) => {
        SetSearchValue(e.target.value);
        setTitle(e.target.value);
        setIsHidden(false);
    };

    return (
        <div>
            <ReactOutsideClickHandler
                onOutsideClick={() => {
                    setIsHidden(true);
                }}
            >
                <div id={name} className={`h-[49px] ${required && "required"}`}>
                    <div
                        className={`flex items-center justify-center rounded-[8px] h-full `}
                    >
                        <div
                            onClick={handleClick}
                            className="flex justify-center items-center h-full max-w-[6.4rem] bg-btn-primary text-bg-white hover:bg-btn-secondary  rounded-l-[8px]  border-r-0 border border-[#314747] validation"
                        >
                            <div className="">
                                <input
                                    name="phoneCode"
                                    id={id ? id : "input_focus"}
                                    value={title ? title : searchValue}
                                    placeholder="..."
                                    onClick={(e) => e.preventDefault()}
                                    className="w-full h-full text-center text-bg-primary appearance-none focus:outline-none hover:bg-btn-secondary  duration-500  "
                                    onChange={handleSearchChange}
                                    style={{
                                        background: "rgba(0,0,0,0)",
                                        outline: "none",
                                    }}
                                    autoComplete="off"
                                    role="presentation"
                                />
                            </div>
                            <div className=" pr-2 h-full flex justify-center items-center">
                                <svg
                                    width="10"
                                    height="7"
                                    viewBox="0 0 10 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L5 5.5L9 1.5"
                                        stroke="#FFFBEF"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                        <input
                            id={`input${name}`}
                            type="tel"
                            name="phone"
                            value={inputValue}
                            onChange={(e) => {
                                setInputValue(
                                    e.target.value
                                        .replace(/\s/g, "")
                                        .replace(/-/g, "")
                                        .replace(/\(/g, "")
                                        .replace(/\)/g, "")
                                );
                                required && customValidationSingle(name);
                            }}
                            className={`h-full w-full bg-[transparent] validation md:text-xs pl-6 outline-none rounded-tr-md rounded-br-md focus:outline-none  border border-l-0 border-[#2a220e73] rounded-l-0`}
                            placeholder="Contact Number"
                            autoComplete="off"
                            onBlur={() => {
                                required && customValidationSingle(name);
                            }}
                        />

                    </div>
                    <div className="errorMessage validationError text-left"></div>
                    <ul
                        id="scroll-x"
                        className={`max-h-[200px] customScroll w-[103px] h-auto overflow-scroll shadow-lg dropdown-menu absolute text-gray-700 bg-bg-primary mt-2 rounded-[8px] custom-shadow z-10 ${
                            isHidden && "hidden"
                        } `}
                    >
                        {dataArray
                            ?.filter(
                                (code) =>
                                    code.phoneCode.includes(searchValue) ||
                                    code.country
                                        .toLowerCase()
                                        .includes(searchValue) ||
                                    code.countryCode3
                                        .toLowerCase()
                                        .includes(searchValue) ||
                                    code.countryCode
                                        .toLowerCase()
                                        .includes(searchValue)
                            )
                            .map((each) => {
                                return (
                                    <li key={each.countryCode}>
                                        <div
                                            onClick={() =>
                                                handleSelect(each.phoneCode)
                                            }
                                            className="cursor-pointer flex items-center text-txt-primary hover:bg-btn-secondary hover:text-txt-secondary py-2 px-4 whitespace-no-wrap gap-x-1"
                                        >
                                            <img
                                                src={`https://ghuddy-dev-attachments.s3.ap-southeast-2.amazonaws.com/flags/4x3/${each.countryCode.toLowerCase()}.svg`}
                                                width={"20px"}
                                                height={"15px"}
                                                alt=""
                                            />
                                            {each.phoneCode}
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </ReactOutsideClickHandler>
        </div>
    );
}
export default SingleSelectPhoneTwo;
