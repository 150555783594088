import React, { useEffect, useState } from "react";
import ButtonFilled from "../../../components/ButtonFilled";
import ModalGlobal from "../../../components/ModalGlobal";
import SecondaryNav from "../comp/SecondaryNav";
import Modal from "react-modal";
import InputComponent from "../../../components/InputComponent";
import SingleSelect from "../../../components/SingleSelect";
import SingleSelectPhoneTwo from "../../../components/dropdown/SearchPhoneTwo";
import SingleSelectParentReturnObject from "../../../components/singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject";
import ButtonOutlined from "../../../components/ButtonOutlined";
import { useDropzone } from "react-dropzone";
import SingleSelectParent from "../../../components/singleSelect/SingleSelectParent";

function CrewManagement() {
    const initialFormValues = {
        firstName: "",
        title: "Mr.",
        lastName: "",
        phone: "",
        phoneCode: "+880",
        email: "",
        role_id: "",
        hotel_id: "",
        image: "",
        building: "",
        flat: "",
        id: 0,
    }
    const [roles, setRoles] = useState([
        {
            id: 1,
            label: "Accountant",
            permissions: [1, 4, 6],
        },
        {
            id: 2,
            label: "Assistant Manager",
            permissions: [1, 2, 3],
        },
        {
            id: 3,
            label: "Tech Support",
            permissions: [4, 5, 6],
        },
        {
            id: 4,
            label: "Tech Lead",
            permissions: [1, 4, 6],
        },
        {
            id: 5,
            label: "Finance Consultant",
            permissions: [1, 4, 6],
        },
    ])

    const [hotels, setHotels] = useState([
        {
            id: 1,
            label: "Hotel 1",
        },
        {
            id: 2,
            label: "Pan Pacific",
        },
    ])

    const [showAddModal, setShowAddModal] = useState(false);
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [title, setTitle] = useState("Mr");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedManagerId, setSelectedManagerId] = useState(null);
    const [fileIsUploading, setFileIsUploading] = useState(false);
    const [selectedRole, setSelectedRole] = useState(roles[0]);
    const [selectedHotel, setSelectedHotel] = useState(hotels[0]);
    const [mode, setMode] = useState('create');

    const [managers, setManagers] = useState([
        {
            id: 1,
            firstName: "John",
            lastName: "Doe",
            phoneCode: "+123",
            phone: "456 789 123",
            email: "manageremail1@domain.com",
            role_id: 2,
            hotel_id: 1,
            title: 'Mr.',
            image: "https://picsum.photos/200?random=1"
        }
    ])

    const [crews, setCrews] = useState([

    ])

    const groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const permissions = groupBy([
        {
            id: 1,
            category: "booking",
            name: 'edit',
        },
        {
            id: 2,
            category: "booking",
            name: 'view',
        },
        {
            id: 3,
            category: "booking",
            name: 'delete',
        },
        {
            id: 4,
            category: "payment",
            name: 'edit',
        },
        {
            id: 5,
            category: "payment",
            name: 'view',
        },
        {
            id: 6,
            category: "payment",
            name: 'delete',
        },
    ], 'category')

    const [formValues, setFormValues] = useState({ ...initialFormValues });

    const [modalType, setModalType] = useState("manager");

    useEffect(() => {
        if (!selectedFile) {
            setFormValues({ ...formValues, image: "" })
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setFormValues({ ...formValues, image: objectUrl })

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    const handleSubmit = () => {

        if (mode === 'create') {
            const tmp = formValues;
            tmp.role_id = selectedRole.id;
            tmp.hotel_id = selectedHotel.id;
            tmp.building = "Building no. 1";
            tmp.flat = "7";
            if (tmp.image.length === 0) {
                tmp.image = `https://picsum.photos/200?random=${managers.length + 1}`;
            }
            if (modalType === 'manager') {
                setManagers([...managers, tmp]);
            }
            else {
                setCrews([...crews, tmp]);
            }
        }
        else {
            formValues.role_id = selectedRole.id;
            formValues.hotel_id = selectedHotel.id;
            if (modalType === 'manager') {
                const temp = managers;
                temp[temp.findIndex(el => el.id === formValues.id)] = formValues;
                setManagers([...temp]);
            }
            else {
                const temp = crews;
                temp[temp.findIndex(el => el.id === formValues.id)] = formValues;
                setCrews([...temp]);
            }
        }
        setFormValues({ ...initialFormValues });
        setSelectedHotel(hotels[0]);
        setSelectedRole(roles[0]);
        setShowAddModal(false);
    }

    const handleRoleSubmit = () => {
        const tmp = roles;
        tmp[roles.findIndex(el => el.id === selectedRole.id)] = selectedRole;
        setRoles(() => [...tmp]);
        setShowRoleModal(false);
    }

    const handleEditItemModal = (index, type) => {
        setMode('edit');
        let data;
        if (type === 'manager') {
            data = managers[index];
            setModalType('manager');
        }
        else {
            data = crews[index];
            setModalType('crew');
        }
        setFormValues(prev => prev = data);
        setSelectedRole(roles[roles.findIndex(el => el.id === data.role_id)]);
        setSelectedHotel(hotels[hotels.findIndex(el => el.id === data.hotel_id)]);
        setShowAddModal(true);
    }

    const handleOpenModal = () => {
        setMode('create');
        setFormValues({ ...initialFormValues });
        setShowAddModal(true);
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        multiple: false,
        onDrop: (acceptedFiles) => {
            setFileIsUploading(true);
            // acceptedFiles.forEach((file) => {
            //     uploadFile(file, acceptedFiles.length);
            // });
            setSelectedFile(acceptedFiles[0]);
            // console.log("acceptedFiles", acceptedFiles);
        },
    });

    const handleCheck = (e, value) => {
        console.log(value);
        if (e.target.checked) {
            setSelectedRole({ ...selectedRole, permissions: [...selectedRole.permissions, value] })
        }
        else {
            setSelectedRole({ ...selectedRole, permissions: [...selectedRole.permissions.filter(el => el !== value)] })
        }
    }

    const handleDelete = (deleteId) => {
        if (modalType === 'manager') {
            setManagers(() => [...managers.filter(el => el.id !== deleteId)]);
        }
        else {
            setManagers(() => [...crews.filter(el => el.id !== deleteId)]);
        }
        setShowAddModal(false);
    }

    const handleRoleDelete = (deleteId) => {
        setRoles(() => [...roles.filter(el => el.id !== deleteId)]);
        setSelectedRole(roles[0]);
        setShowRoleModal(false);
    }

    useEffect(() => {
        console.log(managers)
        console.log(crews)
    }, [managers, crews])

    return (
        <div>
            <SecondaryNav />
            <div className="container mx-auto mt-14">
                <div className="">
                    <div className="flex gap-x-4 items-center">
                        <h1>Create Role</h1>
                        <h2 onClick={() => setShowRoleModal(true)} className="bg-btn-secondary cursor-pointer text-bg-white rounded-full w-[35px] h-[35px] flex justify-center items-center p-2">+</h2>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="flex gap-x-44">
                        <div className=" w-1/2">
                            <div className="flex gap-x-4 items-center justify-start">
                                <h1>Manager Information</h1>
                                <button onClick={() => { setModalType('manager'); handleOpenModal(); }}>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26C17.1046 26 18 25.1046 18 24Z" fill="#2A220E"></path><path d="M18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16Z" fill="#2A220E"></path><path d="M18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8Z" fill="#2A220E"></path></svg>
                                </button>
                            </div>
                            <div className="pr-4 pt-4">
                                {managers.map((manager, index) => (
                                    <div key={Math.random()} className="rounded-[15px] shadow-lg p-6 h-[150px] mb-10">
                                        <div className="flex gap-x-10 items-center">
                                            <div>
                                                <div className="bg-white rounded-[50%] w-[80px] h-[80px] overflow-hidden">
                                                    <img src={manager.image} alt="profile_image" />
                                                </div>
                                            </div>
                                            <div className="text-[18px] flex-grow flex flex-col gap-y-2">
                                                <div className="flex items-center gap-x-4">
                                                    <div className="">
                                                        {manager.firstName} {manager.lastName}
                                                    </div >
                                                    <div className="text-2xl font-bold">.</div>
                                                    <div className="text-bg-semiblack">
                                                        {roles[roles.findIndex(el => el.id === manager.role_id)]?.label}
                                                    </div>
                                                </div>

                                                <div>
                                                    ({manager.phoneCode}) {manager.phone}
                                                </div>
                                                <div>
                                                    {manager.email}
                                                </div>
                                            </div>
                                            <button className="align-top" onClick={() => { handleEditItemModal(index, 'manager') }} >
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26C17.1046 26 18 25.1046 18 24Z" fill="#2A220E"></path><path d="M18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16Z" fill="#2A220E"></path><path d="M18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8Z" fill="#2A220E"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="w-1/2">
                            <div className="flex gap-x-4 items-center justify-start">
                                <h1>Crew Information</h1>
                                <button onClick={() => { setModalType('crew'); handleOpenModal(); }}>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26C17.1046 26 18 25.1046 18 24Z" fill="#2A220E"></path><path d="M18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16Z" fill="#2A220E"></path><path d="M18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8Z" fill="#2A220E"></path></svg>
                                </button>
                            </div>
                            <div className="pr-4 pt-4">
                                {crews.length === 0 &&
                                    <div className="rounded-[15px] shadow-lg p-6 h-[150px]">
                                        <div className="flex items-center justify-center">
                                            <div className="text-center">
                                                <div className="mb-2">No Information to display</div>
                                                <ButtonFilled title={"Add a Member"} w={"100%"} buttonFilledHandle={() => { setModalType('crew'); handleOpenModal(); }} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {crews.map((crew, index) => (
                                    <div key={Math.random()} className="rounded-[15px] shadow-lg p-6 h-[150px]">
                                        <div className="flex gap-x-10 items-center">
                                            <div>
                                                <div className="bg-white rounded-[50%] w-[80px] h-[80px] overflow-hidden">
                                                    <img src={crew.image} alt="profile_image" />
                                                </div>
                                            </div>
                                            <div className="text-[18px] flex-grow flex flex-col gap-y-2">
                                                <div className="flex items-center gap-x-4">
                                                    <div className="">
                                                        {crew.firstName} {crew.lastName}
                                                    </div >
                                                    <div className="text-2xl font-bold">.</div>
                                                    <div className="text-bg-semiblack">
                                                        {roles[roles.findIndex(el => el.id === crew.role_id)]?.label}
                                                    </div>
                                                </div>

                                                <div>
                                                    ({crew.phoneCode}) {crew.phone}
                                                </div>
                                                <div>
                                                    {crew.email}
                                                </div>
                                            </div>
                                            <button className="align-top" onClick={() => handleEditItemModal(index, 'crew')}>
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26C17.1046 26 18 25.1046 18 24Z" fill="#2A220E"></path><path d="M18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16Z" fill="#2A220E"></path><path d="M18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8Z" fill="#2A220E"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={showAddModal} onRequestClose={() => setShowAddModal(false)} >
                <div className="w-[1200px] relative px-2 py-8">
                    <div className="flex justify-end absolute right-4 top-4 ">
                        <button onClick={() => setShowAddModal(false)}>
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                                    fill="#2A220E"
                                    fillOpacity="0.45"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="">
                        <div className="grid grid-cols-2 pb-4">
                            <div className="flex flex-col gap-y-8 col-span-1 px-16">
                                <h1>Add  {modalType === 'manager' && "Manager"} {modalType === 'crew' && "Assistant"} </h1>
                                <div className="flex gap-1 md:gap-5 min-w-full items-center h-[50px]" >
                                    <div className="w-[103px] h-[50px]">
                                        <SingleSelect
                                            title={title}
                                            setTitle={setTitle}
                                        />
                                    </div>
                                    <InputComponent
                                        warningView={false}
                                        placeholder={"First Name"}
                                        inputValue={formValues.firstName}
                                        setInputValue={(e) => { setFormValues({ ...formValues, firstName: e }) }} />
                                </div>
                                <InputComponent
                                    warningView={false}
                                    placeholder={"Last Name"}
                                    inputValue={formValues.lastName}
                                    setInputValue={(e) => { setFormValues({ ...formValues, lastName: e }) }} />

                                <SingleSelectPhoneTwo
                                    warningView={false}
                                    title={"+880"} key={Math.random()}
                                    setTitle={(e) => { setFormValues({ ...formValues, phoneCode: e }) }}
                                    titleArr={[]}
                                    inputValue={formValues.phone}
                                    setInputValue={(e) => { setFormValues({ ...formValues, phone: e }) }} />

                                <InputComponent
                                    warningView={false}
                                    placeholder={"Email"}
                                    inputValue={formValues.email}
                                    setInputValue={(e) => { setFormValues({ ...formValues, email: e }) }} />

                                <SingleSelectParentReturnObject
                                    warningView={false}
                                    placeholder={"Hotels"}
                                    data={hotels}
                                    displayField={"label"}
                                    selectedFieldName="label"
                                    selectedValue={selectedHotel}
                                    setSelectedValue={setSelectedHotel}
                                    setSelectedObject={() => { }}
                                    name="hotel"
                                />
                                {modalType === 'crew' &&
                                    <div className="grid grid-cols-2 gap-3">
                                        <div className="col-span-1">
                                            <SingleSelectParentReturnObject
                                                warningView={false}
                                                placeholder={"Building"}
                                                data={roles}
                                                selectedFieldName={formValues.building}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <SingleSelectParentReturnObject
                                                warningView={false}
                                                placeholder={"Flat"}
                                                data={roles}
                                                selectedFieldName={formValues.flat}
                                            />
                                        </div>
                                    </div>
                                }

                                <SingleSelectParentReturnObject
                                    warningView={false}
                                    placeholder={"Role Name"}
                                    data={roles}
                                    displayField={"label"}
                                    selectedFieldName="label"
                                    selectedValue={selectedRole}
                                    setSelectedValue={setSelectedRole}
                                    setSelectedObject={() => { }}
                                    name="role"
                                />
                                <div className="flex justify-start">
                                    <ButtonOutlined buttonOutlinedHandle={() => handleDelete(formValues.id)} title={"Delete"} w={"30%"} disable={formValues.id === 0 ? 'yes' : "no"} />
                                    <div className="flex-grow">
                                        <ButtonFilled buttonFilledHandle={handleSubmit} title={"Submit"} w={"100%"} />
                                    </div>
                                </div>

                            </div>

                            {/* </div> */}
                            <div className="flex items-center justify-center col-span-1">
                                <div {...getRootProps({ className: "dropzone" })} className="border border-dashed rounded-[50%] w-[500px] overflow-hidden h-[500px] ">
                                    <input {...getInputProps()} />
                                    {formValues.image.length > 0 &&
                                        <div className="relative w-full h-full imageHolder cursor-pointer">
                                            <img src={formValues.image} alt="Profile" className="coverFittedImage" />
                                            <div className="absolute w-full h-full top-0 flex items-center justify-center primaryTransparentColor imageOption">
                                                <ButtonFilled title={"Browse Image"} w={"100%"} arrow="no" />
                                            </div>
                                        </div>
                                    }
                                    {formValues.image.length === 0 &&
                                        <div className=" imageHolder2 relative cursor-pointer w-full h-full flex items-center justify-center ">
                                            <div className="text-center flex-col gap-y-4 flex ">
                                                <h1>Drag the Photo here</h1>
                                                <h1> Or </h1>
                                                <ButtonFilled title={"Browse Image"} w={"100%"} />
                                                <div className="flex-grow"></div>
                                                <div className="text-bg-semiblack"> Accepted file formats: PNG, JPG  </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={showRoleModal} onRequestClose={() => setShowRoleModal(false)}>
                <div className="w-[50vw] relative py-8 px-36">
                    <div className="flex justify-end absolute right-4 top-4 ">
                        <button onClick={() => setShowRoleModal(false)}>
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                                    fill="#2A220E"
                                    fillOpacity="0.45"
                                />
                            </svg>
                        </button>
                    </div>

                    <div className="">
                        <div className="text-2xl mb-8">Add Role </div>
                        <SingleSelectParentReturnObject
                            placeholder={"Role Name"}
                            data={roles}
                            displayField={"label"}
                            selectedFieldName="label"
                            selectedValue={selectedRole}
                            setSelectedValue={setSelectedRole}
                            setSelectedObject={() => { }}
                            name="role"
                        />

                        <div className="border border-bg-semiblack my-10 p-6 rounded-[8px] h-[250px] overflow-auto">
                            {Object.keys(permissions).map((permissionKey) => (
                                <div key={Math.random()}>
                                    <div className="text-xl capitalize mb-2"> {permissionKey} </div>
                                    {permissions[permissionKey]?.map((permission) => (
                                        <div key={Math.random()} className="pb-3">
                                            <input
                                                id={`${permission.name + permission.category}`}
                                                type="checkbox"
                                                checked={selectedRole?.permissions.findIndex(el => el === permission.id) > -1}
                                                onChange={(e) => {
                                                    handleCheck(e, permission.id);
                                                }}
                                                className="mr-[1rem] "
                                            />
                                            <label htmlFor={`${permission.name + permission.category}`} className="text-sm capitalize cursor-pointer">{permission.name} {permission.category}</label>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-start ">
                            <ButtonOutlined buttonOutlinedHandle={() => handleRoleDelete(selectedRole.id)} title={"Delete"} w={"30%"} disable={'no'} />
                            <div className="flex-grow">
                                <ButtonFilled buttonFilledHandle={handleRoleSubmit} arrow={"no"} title={"Save"} w={"100%"} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CrewManagement