import React, { useState, useEffect } from "react";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";


export default function AdminPayments() {
    const [paymentsData, setPaymentsData] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        authAxios
            .get(`${apiEndPoint.ADMIN_PAYMENTS}?requestId=werwer`)
            .then((response) => {
                console.log("payment data: ", response.data.payments);
                setPaymentsData(response.data.payments);
                setTotal(response.data.totalElements);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const changePaymentStatus = (paymentObject, status, index) => {
        authAxios.patch(`${apiEndPoint.ADMIN_PAYMENT_VERIFY}${paymentObject.id}`, {
            amountReceived: paymentObject.paidAmount,
            newStatus:status,
            "requestId": "string"
        })
            .then(res => {
                const tmp = paymentsData;
                tmp[index] = { ...paymentObject, "status": { ...paymentObject.status, paymentStatus: status } }
                setPaymentsData([...tmp]);
            })
            .catch(err => { })
    }

    const changeInvoiceStatus = (paymentObject, status, index) =>{
        authAxios.patch(`${apiEndPoint.ADMIN_BOOKING_INVOICE_STATUS}${paymentObject.invoiceUuid}`,{
            "invoiceStatus": status,
            "rejectionDetails": "string",
            "rejectionReason": "string",
            "requestId": "string"
        })
        .then(res => {
            const tmp = paymentsData;
            tmp[index] = { ...paymentObject, "invoiceStatus": { ...paymentObject.invoiceStatus, invoiceStatus: status } }
            setPaymentsData([...tmp]);
        })
        .catch(err => { })
    } 

    return (
        <div className="container mx-auto">
            <h2 className="text-2xl my-6">Payments: {total}</h2>
            <table id="students" className="min-w-full leading-normal">
                <thead>
                    <tr className="h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            Order ID
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            Order Amount
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            PNR
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            Paid Amount
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            Payment Status
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            Booking Status
                        </th>
                    </tr>
                </thead>
                <div className="my-[14px]"></div>
                <tbody>
                    {paymentsData &&
                        paymentsData.map((member, index) => {
                            const {
                                invoiceUuid,
                                invoiceAmount,
                                paidAmount,
                                status,
                                transactionId,
                                invoiceStatus,
                            } = member; //destructuring
                            return (
                                <tr key={invoiceUuid}
                                >
                                    <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm">
                                        {invoiceUuid}
                                    </td>
                                    <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm text-center">
                                        {invoiceAmount}
                                    </td>
                                    <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                                        {transactionId || "N/A"}
                                    </td>
                                    <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm text-center">
                                        {paidAmount || 0}
                                    </td>
                                    <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                                        <select className="w-full cursor-pointer bg-[transparent]" value={status.paymentStatus} onChange={e => { changePaymentStatus(member, e.target.value, index) }} >
                                            <option> Pending </option>
                                            <option value={"SUCCESSFUL"} > Accepted </option>
                                            <option value={"FAILED"} > Rejected </option>
                                        </select>
                                    </td>
                                    <td className="px-5 py-5 border-b border-t border-r bg-white text-sm">
                                        <select className="w-full cursor-pointer bg-[transparent]" value={invoiceStatus.invoiceStatus} onChange={e => { changeInvoiceStatus(member, e.target.value, index) }} >
                                            <option > Change Status </option>
                                            <option value={"PENDING_PAYMENT"} > Pending </option>
                                            <option value={"BOOKED"} > Confirmed </option>
                                            <option value={"REJECTED"} > Rejected </option>
                                            <option value={"MERCHANT_CANCELED"} > Merhcant Cancelled </option>
                                            <option value={"CANCELED"} > Cancelled </option>
                                        </select>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    )
}