import React, { useEffect, useState } from "react";
import DualListBox from "react-dual-listbox";
import "./dual.css";
import "react-dual-listbox/lib/react-dual-listbox.css";
import ButtonFilled from "../../../components/ButtonFilled";
import SingleSelectParentRulesReturn from "../comp/SingleSelectParentRulesReturn";
import {
  assignFunctionsToRoleList,
  fetchFunctions,
  fetchFunctionsToRoleList,
  fetchRoles,
} from "../../../store/user/api";
import { useDispatch, useSelector } from "react-redux";

// const primaryOptions = [
//   {id:1 , label: "Option One" },
//   {id:2 , label: "Option Two" },
//   {id:3 ,label: "Option three" },
//   {id:4 , label: "Option four" },
//   {id:5 , label: "Option five" },
//   {id:6 , label: "Option six" },
// ];

const Dual = () => {
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState([]);

  // some helper State
  const [flag, setFlag] = useState(0)

  console.log(selectedState, "selectedState")
  const fetchFunctionsToRole = useSelector((state) => state.fetchRoleReducer.data.roles);

  const fetchFunctionsToRoleEach = useSelector(
    (state) => state.fetchFunctionsToRoleReducers.data.functions
  );

  const roles = useSelector(
    (state) => state.fetchFunctionReducer.data.functions
  );

  console.log(fetchFunctionsToRoleEach, "fetchFunctionsToRoleEach");
  console.log(roles, "roles")
  
  useEffect(() => {
    dispatch(fetchRoles(0, 100));
  }, []);

  // console.log(roles, "roles")

  useEffect(() => {
    dispatch(fetchFunctions(0, 100));
  }, []);

  useEffect(() => {
    setSelectedState([])
    if(selectedRoles.id){
      dispatch(fetchFunctionsToRoleList(selectedRoles.id));
    }
    
  }, [selectedRoles.id]);
  useEffect(() => {
    
    if(fetchFunctionsToRoleEach){
      // fetchFunctionsToRoleEach.map((i) => {
      //   // console.log(i.id, "weytryueg")
      //   setSelectedState(i.id)
      // })
      setSelectedState(fetchFunctionsToRoleEach.map((item) => item.id))
      
      // fetchFunctionsToRoleEach.map((item) => console.log(item.id, "yeutueyr", ))
    }
  }, [fetchFunctionsToRoleEach])

  console.log(selectedRoles, "selectedRoles")

  useEffect(() => {
    if (roles) {
      setOptions(
        roles.map((item) => ({
          ...item,
          value: item.id,
          label: item.functionName,
        }))
      );
    }
  }, [roles]);

  const handleChange = (e) => {
    setSelectedState(e);
    console.log(e, "eeeeeee");
  };

  let assignObj = {
    "functionIds": selectedState,
    "requestId": "string"
  }

  const assignRoleToFunctionSubmit = () => {
    dispatch(assignFunctionsToRoleList(selectedRoles.id, assignObj));
  };

  return (
    <>
    <div>
      <h2>Assign Permission to Roles</h2>
    </div>
      <div className="">
        <div className="mb-2">
          <SingleSelectParentRulesReturn
            selectedValue={selectedRoles}
            setSelectedValue={setSelectedRoles}
            data={fetchFunctionsToRole}
            placeholder="Select Function to Roles"
            displayField={"authority"}
            selectedFieldName="roomCategoryId"
            // warningView={true}
            // modaltoppadding={true}
          />
        </div>
      </div>
      <div>
        <DualListBox
          options={options}
          selected={selectedState}
          // onFilterChange={handleChange}
          // selected={(e) => console.log(selectedState, e, "eeeee")}
          onChange={handleChange}
          icons={{
            moveLeft: <span className="fa fa-chevron-left" />,
            moveAllLeft: [
              <span key={0} className="fa fa-chevron-left" />,
              <span key={1} className="fa fa-chevron-left" />,
            ],
            moveRight: <span className="fa fa-chevron-right" />,
            moveAllRight: [
              <span key={0} className="fa fa-chevron-right" />,
              <span key={1} className="fa fa-chevron-right" />,
            ],
            moveDown: <span className="fa fa-chevron-down" />,
            moveUp: <span className="fa fa-chevron-up" />,
            moveTop: <span className="fa fa-double-angle-up" />,
            moveBottom: <span className="fa fa-double-angle-down" />,
          }}
        />
      </div>
      <div className="my-4 mx-10">
        <ButtonFilled
          title="Submit"
          buttonFilledHandle={assignRoleToFunctionSubmit}
          w="291px"
          h="49px"
        />
      </div>
    </>
  );
};

export default Dual;
