import { useEffect, useState } from "react";
import Modal from "react-modal";
import ButtonFilled from "./ButtonFilled";
import "./navbar.css";
import "../App.css";
let arr = []

export default function OTPModalWithButton({ modalIsOpen, setIsOpen, ValidateOtp, updateOTP, resendOtp }) {
  const [isActive, setActive] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const { } = {
    content: {

      right: "auto",
      bottom: "auto",
      //   minHeight: "60vh",
      maxHeight: "70vh",
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const handleChange = (element, index) => {
    const val = element.value;

    if (isNaN(val)) return false;

    if (val.length > 1) {
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
      return false;
    }

    arr.push(val)
    setOtp([...otp.map((d, idx) => (idx === index ? val : d))]);

    if (val.length === 0) {
      if (element.previousSibling) {
        element.previousSibling.focus();
      }
      return;
    }

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  useEffect(() => {
    // alert("modal loaded!")
  }, [])
  return (
    <Modal
      className=""
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Cart"
      ariaHideApp={false}
    >
      <div className="">
        <div className="col text-center py-10">
          <p className=" text-[18px] px-20 py-4 text-txt-primary">
            Please, enter the verification code you received in the message
          </p>
          <div className="flex flex-wrap justify-center gap-5">
            {otp.map((data, index) => {
              return (
                <input
                  className="border border-txt-hint h-12 text-txt-primary bg-bg-primary w-12 rounded-[8px] text-2xl text-center"
                  type="text"
                  name="otp"
                  key={index}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                // onFocus={(e) => e.target.select()}
                />
              );
            })}
            <ButtonFilled buttonFilledHandle={()=>{resendOtp()}} title={"Resend"} arrow="arrow" round="rounded-[8px]" />
          </div>
          <div className="mt-6 flex justify-center">
            <ButtonFilled title={"Submit"} w={500} round="rounded-[8px]" buttonFilledHandle={() => {
              updateOTP(otp.join(''));
            }} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
