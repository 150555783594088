import React from "react";
import AvailablityCalendar from "../../pages/AvailablityCalendar";
import DashboardBooking from "../../pages/DashboardBooking";
import "./CustomModal.css";

const BookingModal = ({ removeOverlay,loading, setCalenderOverlay, onConfirm, onReject, bookingItem, hotel,onRefuseReject,showControl=true, type="regular" }) => {
  const stopProp = (e) => {
    e.stopPropagation();
  };
  return (
    <div className="overlay_background" onClick={(e) => removeOverlay()}>
      <div className="overlay_card" onClick={(e) => stopProp(e)}>
        <DashboardBooking
          onConfirm={onConfirm}
          onReject={onReject}
          onRefuseReject={onRefuseReject}
          hotel={hotel}
          bookingItem={bookingItem}
          loading={loading}
          type={type}
          showControl={showControl}
          removeOverlay={removeOverlay}
          setCalenderOverlay={setCalenderOverlay} />
      </div>
    </div>
  );
};

export default BookingModal;