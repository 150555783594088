import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dual } from "./comp/Dual";
import ButtonFilled from "../../components/ButtonFilled";
import ButtonOutlined from "../../components/ButtonOutlined";
import InputComponent from "../../components/InputComponent";
import ModalGlobal from "../../components/ModalGlobal";
import {
    createFunction,
    createRole,
    deleteFunction,
    deleteRole,
    editFunction,
    editRole,
    fetchFunctions,
    fetchRoles,
} from "../../store/user/api";

function RolePermission() {
    const [openAddNew, setOpenAddNew] = useState(false);
    const [functionName, setfunctionName] = useState("");
    const [description, setDescription] = useState("");
    const [id, setId] = useState(0);

    // some helper state
    const [flag, setFlag] = useState(0);

    const roles = useSelector((state) => state.fetchFunctionReducer.data);

    const editRoles = (functionName, description, id) => {
        setId(id);
        setfunctionName(functionName);
        setDescription(description);
        setOpenAddNew(true);
    };

    const createOrEditRole = () => {
        if (id > 0) {
            dispatch(
                editFunction(id, { functionName, description }, () => {
                    setOpenAddNew(false);
                })
            );
        } else {
            dispatch(
                createFunction({ functionName, description }, () => {
                    setOpenAddNew(false);
                    dispatch(fetchFunctions(0, 100, setFlag));
                })
            );
        }
    };

    const createNewModal = () => {
        setId(0);
        setfunctionName("");
        setDescription("");
        setOpenAddNew(true);
    };

    const deleteRoles = (id) => {
        console.log("deleteRole", id);
        dispatch(
            deleteFunction(id, () => {
                dispatch(fetchFunctions(0, 100, setFlag));
            })
        );
    };

    const createRoleComponent = () => {
        return (
            <div className="flex flex-col px-12">
                <InputComponent
                    inputValue={functionName}
                    setInputValue={setfunctionName}
                    placeholder="Name"
                    modal={true}
                    warningView={false}
                    name="name"
                />

                <InputComponent
                    inputValue={description}
                    setInputValue={setDescription}
                    placeholder="Description"
                    modal={true}
                    warningView={false}
                    name="desc"
                />
                <div className="flex justify-center">
                    <ButtonFilled
                        title="Submit"
                        buttonFilledHandle={createOrEditRole}
                        w="176px"
                        h="49px"
                    />
                </div>
            </div>
        );
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchFunctions(0, 100, setFlag));
    }, []);
    return (
        <div className="">
            <div className="min-h-screen ">
                <ModalGlobal
                    header="Create/update Permissions"
                    body={createRoleComponent()}
                    openDefault={openAddNew}
                    setOpenDefault={setOpenAddNew}
                    // navigatepass="/admin/role"
                />
                <h1 className="text-2xl py-4 border-b-2">Permissions</h1>
                <div className=" flex content-end justify-items-end py-4 ">
                    <ButtonOutlined
                        title="Add New Role"
                        buttonOutlinedHandle={createNewModal}
                        w="176px"
                        h="49px"
                    />
                </div>
                <div>
                    <table className="table-auto w-full">
                        <thead>
                            <tr>
                                <th className="text-2xl">ID</th>
                                <th className="text-2xl">Name</th>
                                <th className="text-2xl">Description</th>
                                <th className="text-2xl">Edit</th>
                                <th className="text-2xl">Delete</th>
                            </tr>
                        </thead>
                        <tbody className="pt-8">
                            {roles.functions &&
                                roles.functions.map((role, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div className="flex justify-center">
                                                    <h2 className="">
                                                        {role.id}
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex justify-center">
                                                    <h2 className="">
                                                        {role.functionName}
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex justify-center">
                                                    <h2 className="">
                                                        {role.description}
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex justify-center">
                                                    <ButtonFilled
                                                        title="Edit"
                                                        buttonFilledHandle={() => {
                                                            editRoles(
                                                                role.functionName,
                                                                role.description,
                                                                role.id
                                                            );
                                                        }}
                                                        w="176px"
                                                        h="49px"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex justify-center">
                                                    <ButtonFilled
                                                        title="Delete"
                                                        buttonFilledHandle={() => {
                                                            deleteRoles(
                                                                role.id
                                                            );
                                                        }}
                                                        w="176px"
                                                        h="49px"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default RolePermission;
