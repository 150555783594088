import React from "react";
import "../../../App.css"

function CheckInCheckOutShortcutTable({ data }) {
  return (
    <div className="w-full custom-shadow rounded-[32px] overflow-hidden mt-4">
      {/* <div className="bg-btn-secondary w-full">
        <p className="text-[18px] py-[13px] text-txt-secondary text-center ">
          {title}
        </p>
      </div> */}
      <div>
        <table className="table-fixed w-full">
          <thead className="py-2 border-0">
            <tr className='bg-btn-secondary '>
              <th className="text-[18px] text-txt-secondary py-4">Property</th>
              <th className="text-[18px] text-txt-secondary py-4">Name</th>
              <th className="text-[18px] text-txt-secondary py-4">Time</th>
              <th className="text-[18px] text-txt-secondary py-4">Status</th>
            </tr>
          </thead>
          <tbody className='h-[10px] overflow-y-scroll'>
            {data &&
              data.map((item, index) => {
                return (
                  <tr key={index} className='border-b last:border-0 border-btn-border '>
                    <td className="text-[12px] py-4 text-center">{item.property}</td>
                    <td className="text-[12px] py-4 text-center">{item.name}</td>
                    <td className="text-[12px] py-4 text-center">{item.time}</td>
                    <td className="text-[12px] py-4 text-center">{'Accepted'}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CheckInCheckOutShortcutTable;
