import React from "react";

function TopShortcutItem({ title, list }) {
  return (
    <div className="w-full h-[200px] custom-shadow rounded-[32px] overflow-hidden">
      <div className="bg-btn-secondary w-full">
        <p className="text-[18px] py-[13px] text-txt-secondary text-center ">
          {title}
        </p>
      </div>
      <div className="flex justify-center items-center ">
        {list &&
          <table className="table-auto w-full my-4 ">
            <tbody className=''>
              {Object.keys(list).map((key, index) => {
                return (
                  <tr key={index} className=''>
                    <td className="text-[12px] pl-4 text-txt-hint capitalize">{key.replace(/([A-Z])/g, ' $1')}</td>
                    <td className="text-[12px] w-[80px]">{list[key]}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        }
        {!list && `No ${title}`}
      </div>
    </div>
  );
}

export default TopShortcutItem;
