import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import ButtonFilled from "../../../components/ButtonFilled";
import InputComponent from "../../../components/InputComponent";
import Loading from "../../../components/Loader/Loading";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import Paginations from "../Paginations/Paginations";

const AdminVerifyPayment = () => {
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedRef, setSelectedRef] = useState("");
  const [externalReference, setExternalReference] = useState("");
  //?pageNo=1&pageSize=20&requestId=11
  const [allPendingPayment, setAllPendingPayment] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPages, setCurrentPages] = useState(1);

  const [paymentDetails, setPaymentDetails] = useState([]);
  let PageSize = 10;
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl}${apiEndPoint.ADMIN_GET_ALL_UNVERIFIED_PAYMENTS}?pageNo=${currentPages}&pageSize=10&requestId=11`
      )
      .then((res) => {
        setTotalData(res.data);
        setAllPendingPayment(res.data?.manualPayments);
      })
      .then(() => {
        setLoading(false);
      });
  }, [currentPages]);

  console.log("allPendingPayment", allPendingPayment);

  const handleModal = (id, exRef) => {
    setShowConfirmModal(true);
    setSelectedId(id);
    setSelectedRef(exRef);
  };
  const handleConfirm = () => {
    console.log(selectedId, "test 1");
    if (selectedRef === externalReference) {
      authAxios
        .put(
          `${baseUrl}${apiEndPoint.ADMIN_VERIFY_PAYMENT_BY_ID}/${selectedId}?externalReference=${selectedRef}&requestId=11`
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Verification Successful", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setSelectedId(null);
            setSelectedRef("");
            setShowConfirmModal(false);
            setWarning(false);
            setExternalReference("");

          } else {
            toast.warn("Opps! Somthening went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .then(()=>{
          setTimeout(()=>{
            window.location.reload();
          },2000)
        })
    } else {
      setWarning(true);
    }
  };

  return (
    <>
    <h1 className=" mb-[2rem] text-center">Pending payments</h1>

    <div className="px-[16px] pb-[20px] xl:flex xl:justify-center overflow-x-scroll">
      <div className="w-full mt-[90px]">
        <div className="w-full">

          <div className="pb-20 ">
            <div className="w-full ">
              {!loading ? (
                // <table
                //     id="paymentTable"
                //     className="leading-normal"
                // >
                //     <thead>
                //         <tr className="sticky top-[90px] h-[78px] justify-center items-center flex flex-row gap-x-[2px] py-3 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">

                //             <th className=" w-[100px] text-left text-xs font-normal text-gray-700 tracking-wider">
                //                 Invoice ID
                //             </th>
                //             <th className="w-[100px]  text-left text-xs font-normal text-gray-700 tracking-wider">
                //                 Payment ID
                //             </th>
                //             <th className="w-[130px]  bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //                 Payment Date
                //             </th>
                //             <th className="w-[130px] bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //             Customer Name
                //             </th>
                //             <th className="w-[150px] bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //                 External Reference
                //             </th>
                //             <th className="w-[140px] bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //                 Payment Method
                //             </th>

                //             <th className="w-[180px] bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //                 Phone Number
                //             </th>
                //             <th className="w-[120px]  bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //                 Paid Amount
                //             </th>
                //             <th className="w-[130px]  bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //              Received Amount
                //             </th>

                //             <th className="  bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //                fee
                //             </th>
                //             <th className="w-[180px]  bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //                transaction Code
                //             </th>

                //             <th className="  bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //                 status
                //             </th>
                //             <th className="  bg-gray-100 text-left text-xs font-normal text-gray-700 tracking-wider">
                //                 Action
                //             </th>

                //         </tr>
                //     </thead>
                //     <tbody className="">
                //         {allPendingPayment &&
                //             !loading &&
                //             allPendingPayment?.map((slip, index) => {
                //                 const {
                //                     id,
                //                     invoiceId,
                //                     paymentDate,
                //                     name,
                //                     externalReference,
                //                     paymentMethod,
                //                     phoneNo,
                //                     paidAmount,
                //                     receivedAmount,
                //                     fee,
                //                     transactionCode,
                //                     status

                //                 } = slip;
                //                 return (
                //                     <tr
                //                         key={invoiceId || index}
                //                         className="border-b"
                //                     >

                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                //                             {invoiceId}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                //                             {id}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                //                             {new Date(
                //                             paymentDate
                //                             ).toDateString()}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                //                             {name}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                //                         {externalReference}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                //                         {paymentMethod}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                //                         {phoneNo}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                //                         {paidAmount}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                //                         {receivedAmount}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                //                         {fee}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                //                         {transactionCode}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                //                         {status}
                //                         </td>
                //                         <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">

                //                             <button onClick={()=>{ handleModal(id,externalReference) }} className='border w-[130px] h-[33px]  active:scale-90
                //                             transition duration-300 flex justify-center items-center rounded-[300px]
                //                              leading-small bg-bg-primary text-[18px] text-btn-primary
                //                               active:bg-txt-secondary active:border active:border-btn-primary
                //                              active:text-btn-primary border-btn-primary
                //                              hover:bg-btn-primary hover:text-bg-white py-[1.5rem]'>Verify</button>
                //                         </td>
                //                     </tr>
                //                 );
                //             })}

                //         {allPendingPayment.length === 0 && !loading && (
                //             <tr className="border">
                //                 <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                //                     No data
                //                 </td>
                //             </tr>
                //         )}
                //     </tbody>

                // </table>
                <table className="table-fixed border-collapse  w-full">
                  <thead className="sticky top-[88px] h-[60px] shadow-sm bg-btn-primary text-bg-primary  text-left">
                    <tr className="text-center">
                      <th className="w-[100px]  px-[8px]  text-xs font-normal text-gray-700 tracking-wider">
                        Invoice ID
                      </th>
                      <th className="px-[8px] w-[100px]   text-xs font-normal text-gray-700 tracking-wider">
                        Payment ID
                      </th>
                      <th className="px-[8px] w-[160px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        Payment Date
                      </th>
                      <th className="px-[8px] w-[120px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        Customer Name
                      </th>
                      <th className="px-[8px] w-[120px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        External Reference
                      </th>
                      <th className="px-[8px] w-[180px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        Payment Method
                      </th>

                      <th className="px-[8px] w-[150px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        Phone Number
                      </th>
                      <th className=" px-[8px] w-[100px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        Paid Amount
                      </th>
                      <th className=" px-[8px] w-[140px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        Received Amount
                      </th>

                      <th className=" px-[8px] w-[100px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        fee
                      </th>
                      <th className="px-[8px] w-[160px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        transaction Code
                      </th>
                      <th className="px-[8px] w-[190px]  bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        status
                      </th>
                      <th className="px-[8px] w-[120px] bg-gray-100  text-xs font-normal text-gray-700 tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPendingPayment?.map((slip, index) => (
                      <tr
                        key={index}
                        className="hover:bg-bg-primaryShadow text-center cursor-pointer border-b border-bg-semiblack"
                      >
                                                  <td className="h-[80px]  px-5 py-5 bg-white text-sm">
                                           {slip?.invoiceId}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                            {slip?.id}
                                         </td>
                                        <td className="h-[80px]  px-5 py-5 bg-white text-sm ">
                                             {new Date(
                                            slip?.paymentDate
                                            ).toDateString()}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm ">
                                             {slip?.name}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm ">
                                         {slip?.externalReference}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm ">
                                         {slip?.paymentMethod}
                                         </td>
                                        <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                         {slip?.phoneNo}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                         {slip?.paidAmount}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                         {slip?.receivedAmount}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                         {slip?.fee}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                         {slip?.transactionCode}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm ">
                                         {slip?.status}
                                         </td>
                                         <td className="h-[80px] px-5 py-5 bg-white text-sm ">

                                            <button onClick={()=>{ handleModal(slip?.id,slip?.externalReference) }} className='border w-[130px] h-[33px]  active:scale-90
                                             transition duration-300 flex justify-center items-center rounded-[300px]
                                              leading-small bg-bg-primary text-[18px] text-btn-primary
                                               active:bg-txt-secondary active:border active:border-btn-primary
                                              active:text-btn-primary border-btn-primary
                                              hover:bg-btn-primary hover:text-bg-white py-[1.5rem]'>Verify</button>
                                         </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="w-full flex justify-center items-center">
                  <div>
                    <Loading />
                  </div>
                </div>
              )}
            </div>
          </div>

        </div>
        <Paginations
            currentPage={currentPages}
            totalCount={totalData?.totalPages}
            pageSize={10}
            onPageChange={(page) => setCurrentPages(page)}
          />
      </div>
      <ReactModal
        style={{ content: { width: "auto" } }}
        isOpen={showConfirmModal}
        onRequestClose={() => {
          setShowConfirmModal(false);
          setWarning(false);
          setExternalReference("");
        }}
      >
        <div className="px-10 py-8 ">
          <div className="text-center text-2xl mb-4">
            To proceed with verification, please enter external reference number
            below.
          </div>
          <div className="my-2 flex flex-col items-center ">
            <InputComponent
              name={"ExternalReference"}
              inputValue={externalReference}
              setInputValue={(val) => setExternalReference(val)}
              warningView={false}
              placeholder="External reference"
              clickHandler={() => {
                setWarning(false);
              }}
            />
            <div>
              {warning && (
                <span className="text-[11px] text-btn-pink">
                  Enter correct reference number
                </span>
              )}
            </div>
          </div>

          <div className="flex justify-center gap-2 mt-8">
            <ButtonFilled
              title={"Yes"}
              w="150px"
              arrow={"no"}
              buttonFilledHandle={() => {
                handleConfirm();
              }}
            />
          </div>
        </div>
      </ReactModal>
    </div>
    </>
  );
};

export default AdminVerifyPayment;
