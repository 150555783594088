import React from 'react'
import { Link } from 'react-router-dom';
import baloon from "../assets/images/baloon.jpg";

function NotFound() {
  return (
    <div className="bg-bg-default bg-cover h-screen px-10 py-10" style={{ backgroundImage: `url(${baloon})` }}>
      <div className="flex items-center justify-center h-full w-full">
        <div className='cursor-default text-bg-white'>
          <p className="text-5xl text-center font-bold yx-50 tracking-widest	my-10">404<br />Not Found</p>
          <p className="text-xl text-center yx-50">Sorry, the page you are looking for <br /> does not exist, or was moved</p>
          <Link to={'/'} className=''>
            <button className='bg-btn-primary text-bg-white w-full py-2 rounded-[24px] hover:bg-btn-secondary text-xl my-2'>
              Take me to Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound