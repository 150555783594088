import React, { useEffect, useRef } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
// import SingleSelect from "./SingleSelect";
 
import RootComponent from "../../../components/rootComponent/RootComponent";
import SingleSelectHotelReturn from "../../../components/singleSelect/singleSelectHotelReturn/SingleSelectHotelReturn"

function SingleSelectParentCategoryReturn({
  data,
  selectedValue,
  setSelectedValue,
  placeholder,
  displayField,
  selectedFieldName,
  propertyName,
  type,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  // rejectionArray,
  setRejectionArray,
  name,
  modaltoppadding,
  warningView
}) {
  const [visible, setVisible] = React.useState(false);

  // state for ref width support
  const [width, setWidth] = React.useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  console.log(width, "refref", ref)

  console.log(selectedValue[displayField], "selectedValue")

  // console.log(selectedArray.join(","), "selectedArray");
  // useEffect(() => {

  // }, [selectedValue]);
  // const [display, setDisplay] = React.useState(selectedValue);
  let component = (
    <ReactOutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div className="w-full flex flex-col rounded-lg ">
        <div className=" bg-bg-primary pb-2 pt-2">
          <div
            ref={ref}
            className="w-full rounded-[8px] outline-custom black_box h-[49px] flex outline-[1px] outline-offset-[-1px] outline-none"
            onClick={() => setVisible(!visible)}
          >
            <p
              className={`pl-4 py-3 bg-bg-primary ${
                selectedValue.id || propertyName
                  ? "text-txt-primary"
                  : "text-bg-semiblack"
              }`}
            >
              {placeholder}:
            </p>
            <p className="py-3 px-2">{selectedValue
                ? selectedValue[displayField]
                : placeholder}</p>
            <button className="bg-btn-primary w-[49px] rounded-[8px] px-5 ml-auto">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.5L6 8.5L10 4.5"
                  stroke="#FFFBEF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        {visible && (
          <div
            className=" pt-4 rounded-2xl mt-16 bg-bg-primary custom-shadow z-70 absolute max-h-[200px] h-[200px] overflow-scroll"
            style={{ width: 400 }}
          >
            <div className="">
              {data && data.length
                ? data.map((item, index) => {
                    return (
                      <SingleSelectHotelReturn
                        displayField={displayField}
                        key={index}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        item={item}
                        setVisible={setVisible}
                        selectedFieldName={selectedFieldName}
                        setSearchValue={()=>{}}
                      />
                      // <p>{item[displayField]}</p>
                    );
                  })
                : null}
            </div>
          </div>
        )}
      </div>
    </ReactOutsideClickHandler>
  )
  let readOnlyViewComponent = <p>{selectedValue.propertyName}</p>;
  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        // rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        name={name}
        warningView={warningView}
      />
    </div>
  );
}

export default SingleSelectParentCategoryReturn;
