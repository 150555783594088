import React from "react";

const SuccessSVG = () => {
  return (
    <div>
      <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="49" height="49" rx="24.5" fill="#4E90A8" />
        <path
          d="M23.5396 31.9987C23.0393 31.9987 22.5655 31.7957 22.2619 31.4457L18.153 26.7247C18.026 26.5789 17.9332 26.4128 17.8801 26.2358C17.8269 26.0588 17.8144 25.8744 17.8432 25.6932C17.8721 25.512 17.9417 25.3376 18.0481 25.1798C18.1546 25.022 18.2957 24.884 18.4635 24.7737C18.6312 24.6629 18.8225 24.582 19.0262 24.5356C19.23 24.4892 19.4423 24.4783 19.6509 24.5033C19.8596 24.5284 20.0605 24.5891 20.2421 24.6818C20.4238 24.7745 20.5826 24.8975 20.7094 25.0437L23.4131 28.1477L30.2107 18.6557C30.4362 18.3423 30.7954 18.1194 31.2095 18.036C31.6237 17.9526 32.0589 18.0154 32.4199 18.2107C33.1709 18.6167 33.402 19.4767 32.9328 20.1307L24.9012 31.3407C24.7641 31.5329 24.5751 31.6931 24.3507 31.8075C24.1262 31.9218 23.8731 31.9867 23.6132 31.9967C23.5879 31.9987 23.5649 31.9987 23.5396 31.9987V31.9987Z"
          fill="#FFFBEF"
        />
      </svg>
    </div>
  );
};

export default SuccessSVG;
