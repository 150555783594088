import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ButtonFilled from "../../../components/ButtonFilled";
import ButtonOutlined from "../../../components/ButtonOutlined";
import InputComponent from "../../../components/InputComponent";
import MultiSelectParent from "../../../components/multiselect/MultiSelectParent";
import SingleSelectParentCategoryReturn from "../../../components/singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturn";
import { apiEndPoint, PROPERTY_ENDPOINTS } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import { toastMessage } from "../../../utils/toast";
import SecondaryNav from "../comp/SecondaryNav";

export default function PolicyManagement() {
    const [hotelList, setHotelList] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [selectedItemId, setSelectedItemId] = useState(null);

    const [policyList, setPolicyList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getHotels();
    }, []);

    const getHotels = () => {
        authAxios
            .get(`${apiEndPoint.MERCHANT_GET_OWN_HOTELS}`)
            .then((res) => {
                setHotelList(res.data.propertyDataList);
                setSelectedHotel(res.data.propertyDataList[0]);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        if (selectedHotel) {
            getPolicyByHotel();
        }
    }, [selectedHotel]);

    const getPolicyByHotel = () => {
        authAxios
            .get(
                `${apiEndPoint.MERCHANT_GET_POLICY}/${selectedHotel?.id}/policy`
            )
            .then((res) => {
                setPolicyList(res.data.policyData ? res.data.policyData : []);
            })
            .catch((err) => {
                toastMessage("Error fetching data", "error");
            });
    };

    const handleEdit = () => {
        authAxios
            .patch(`${apiEndPoint.MERCHANT_EDIT_POLICY}/${selectedItemId}`, {
                title,
                description,
                requestId: "123",
            })
            .then((res) => {
                getHotels();
                setShowModal();
                setSelectedItemId(null);
            })
            .catch((err) => {});
    };
    const addNew = () => {
        authAxios
            .post(`${apiEndPoint.MERCHANT_ADD_POLICY}`, {
                title,
                description,
                requestId: "",
                propertyId: selectedHotel?.id,
            })
            .then((res) => {
                setPolicyList([...policyList, res.data.policyData]);
                setShowModal(false);
                setSelectedItemId(null);

            })
            .catch((err) => {});
    };

    const handleDelete = () => {
        authAxios
            .delete(`${apiEndPoint.MERCHANT_DELETE_POLICY}/${selectedItemId}`)
            .then((res) => {
                setPolicyList(
                    policyList.filter((item) => item.id !== selectedItemId)
                );
                setShowDeleteModal(false);
                setSelectedItemId(null);
            })
            .catch((err) => {});
    };

    return (
        <div className="">
            <SecondaryNav/>
            <div className="container mx-auto">
                <h1>Custom Policies</h1>

                <div className="mt-10">
                    <div>Filter by hotel</div>
                    <div className="md:w-1/2">
                        <SingleSelectParentCategoryReturn
                            warningView={false}
                            selectedValue={selectedHotel}
                            setSelectedValue={setSelectedHotel}
                            data={hotelList}
                            placeholder="Select Hotel"
                            displayField={"propertyName"}
                            selectedFieldName="id"
                        />
                    </div>
                </div>

                <div className="my-10">
                    <div className=" ">
                        <table className="w-full table-fixed border-0  text-base12 rounded-[24px] shadow-customShadow ">
                            <thead className="">
                                <tr className="bg-btn-secondary text-txt-secondary text-base18 font-normal ">
                                    <th className=" font-normal w-[350px] py-[1rem] rounded-tl-[24px] ">
                                        Policy Title
                                    </th>
                                    <th className=" py-[1rem] font-normal rounded-tr-[24px]">
                                        Policy Description
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="">
                                {policyList.map((pol, index) => (
                                    <tr
                                        key={pol.id}
                                        className="border-t border-[#e1e1e1]"
                                    >
                                        <td className="p-4">
                                            <InputComponent
                                                height="72px"
                                                name={`pol${index}`}
                                                inputValue={pol.title}
                                                warningView={false}
                                                setInputValue={() => {}}
                                            />
                                        </td>
                                        <td className="p-4 flex items-center gap-2">
                                            <textarea
                                                id={`policy${pol.id}`}
                                                name="desc"
                                                value={pol.description?.description}
                                                onChange={() => {}}
                                                rows="1"
                                                className="resize-none h-[72px] w-full validation align-text-top bg-bg-primary border border-[#9e9989] rounded-[8px]  outline-none p-4 pt-[20px] "
                                            />

                                            <button
                                                onClick={() => {
                                                    setSelectedItemId(pol.id);
                                                    setTitle(
                                                        policyList[index].title
                                                    );
                                                    setDescription(
                                                        policyList[index]
                                                            .description.description
                                                    );
                                                    setShowModal(true);
                                                }}
                                                className="text-btn-primary p-2 hover:shadow-lg rounded-sm active:scale-90 duration-200 flex items-center justify-center"
                                            >
                                                <i className="fas fa-edit"> </i>
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setSelectedItemId(pol.id);
                                                    setShowDeleteModal(true);
                                                }}
                                                className="text-btn-primary p-2 hover:shadow-lg rounded-sm active:scale-90 duration-200 flex items-center justify-center"
                                            >
                                                <i className="fas fa-trash"> </i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {policyList?.length === 0 && (
                                    <tr className="border-t border-[#e1e1e1]">
                                        <td className="p-4 text-txt-hint">
                                            No custom policy
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="flex justify-end p-4">
                            <ButtonFilled
                                title={"+"}
                                arrow="no"
                                buttonFilledHandle={() => {
                                    setTitle("");
                                    setDescription("");
                                    setShowModal(true);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex justify-end mt-10">
                    <div className="w-[300px]">
                        <ButtonFilled
                            title={"Done"}
                            buttonFilledHandle={() => {
                                navigate("/dashboard/hotels");
                            }}
                            w="100%"
                            arrow={"no"}
                        />
                    </div>
                </div>
            </div>

            <ReactModal
                style={{ content: { width: "60vw" } }}
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <div className="px-10 py-8 relative">
                    <button
                        className="absolute top-5 right-6"
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                                fill="#2A220E"
                                fillOpacity="0.45"
                            />
                        </svg>
                    </button>
                    <div className="text-center text-2xl mb-4">
                        {selectedItemId ? "Edit Policy" : "Add Policy"}
                    </div>
                    <div>Policy title</div>
                    <InputComponent
                        inputValue={title}
                        setInputValue={setTitle}
                        required={true}
                        warningView={false}
                    />

                    <div className="mt-6"> Description </div>
                    <textarea
                        className="w-full bg-[transparent] rounded-[8px] validation flex border-[#9e9989] border p-2 outline-none"
                        value={description}
                        rows="6"
                        onChange={(e) => setDescription(e.target.value)}
                    />

                    <div className="flex justify-center gap-2 mt-8">
                        {selectedItemId && (
                            <ButtonOutlined
                                title={"Delete"}
                                w="200px"
                                buttonOutlinedHandle={() => {
                                    // setShowDeleteModal(true);
                                    setShowModal(false);
                                }}
                            />
                        )}
                        <ButtonFilled
                            title={"Submit"}
                            w="200px"
                            buttonFilledHandle={() => {
                                selectedItemId && handleEdit();
                                !selectedItemId && addNew();
                            }}
                        />
                    </div>
                </div>
            </ReactModal>

            <ReactModal
                style={{ content: { width: "auto" } }}
                isOpen={showDeleteModal}
                onRequestClose={() => {}}
            >
                <div className="px-10 py-8">
                    <div className="text-center text-2xl mb-4">
                        Are you sure you want to delete this item ?
                    </div>
                    <div className="flex justify-center gap-2 mt-8">
                        {selectedItemId && (
                            <ButtonOutlined
                                title={"No"}
                                w="150px"
                                buttonOutlinedHandle={() => {
                                    setShowDeleteModal(false);
                                    setShowModal(true);
                                }}
                            />
                        )}
                        <ButtonFilled
                            title={"Yes"}
                            w="150px"
                            arrow={"no"}
                            buttonFilledHandle={() => {
                                handleDelete();
                            }}
                        />
                    </div>
                </div>
            </ReactModal>
        </div>
    );
}
