import React from "react";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import FooterButton from './FooterButton';

function Footer() {
  return (
    <div className="bg-btn-primary md:bg-bg-primary md:w-[90%] mx-auto py-10">

      <div className="grid md:grid-cols-4 gap-x-8 content-center justify-center object-center">
        <div className="flex flex-col">
          <div className="hidden md:block">
            <svg
              width="126"
              height="32"
              viewBox="0 0 126 32"
              fill="rgb(250,250,250)"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48.8435 14.2848H55.892V20.9723C54.3045 22.6317 51.731 23.6661 49.3248 23.6661C44.5857 23.6661 40.5935 19.7457 40.5935 15.1035C40.5935 10.4613 44.5873 6.56445 49.3248 6.56445C51.7295 6.56445 54.3045 7.57539 55.892 9.23477L53.7514 11.2317C52.717 10.0285 50.9373 9.30664 49.3248 9.30664C46.2451 9.30664 43.6482 11.952 43.6482 15.1035C43.6482 18.2785 46.2466 20.9239 49.3248 20.9239C50.5748 20.9239 51.9217 20.491 52.9576 19.7457V16.7395H48.8435V14.2848Z"
                fill="#376576"
              />
              <path
                d="M69.1937 15.4624V23.3765H66.3546V16.0405C66.3546 14.3562 65.2718 13.1546 63.7327 13.1546C62.0734 13.1546 60.7015 14.3577 60.7015 15.8484V23.3765H57.7671V5.61084L60.7015 6.5624V12.6484C61.4468 11.4452 62.914 10.6515 64.5734 10.6515C67.2937 10.6515 69.1937 12.6484 69.1937 15.4624Z"
                fill="#376576"
              />
              <path
                d="M82.5185 10.9405V23.3765H79.5841V21.7171C78.8138 22.8718 77.3716 23.6655 75.7357 23.6655C73.0169 23.6655 71.1169 21.6687 71.1169 18.8546V10.9405H73.956V18.2765C73.956 19.9609 75.0388 21.1624 76.5779 21.1624C78.117 21.1624 79.392 20.128 79.5841 18.8046V10.939H82.5185V10.9405Z"
                fill="#376576"
              />
              <path
                d="M96.9265 6.5626V23.3767H93.9921V21.4517C93.0062 22.847 91.5155 23.6642 89.6858 23.6642C86.3905 23.6642 83.9858 20.9704 83.9858 17.1454C83.9858 13.3454 86.3905 10.6501 89.6858 10.6501C91.514 10.6501 93.0046 11.4673 93.9921 12.8626V5.60791L96.9265 6.5626ZM93.9921 17.147C93.9921 14.8142 92.5484 13.1532 90.4796 13.1532C88.3874 13.1532 86.8952 14.8126 86.8952 17.147C86.8952 19.4798 88.3858 21.1642 90.4796 21.1642C92.5484 21.1642 93.9921 19.4798 93.9921 17.147Z"
                fill="#376576"
              />
              <path
                d="M111.406 6.5626V23.3767H108.472V21.4517C107.486 22.847 105.995 23.6642 104.165 23.6642C100.87 23.6642 98.4653 20.9704 98.4653 17.1454C98.4653 13.3454 100.87 10.6501 104.165 10.6501C105.994 10.6501 107.484 11.4673 108.472 12.8626V5.60791L111.406 6.5626ZM108.472 17.147C108.472 14.8142 107.028 13.1532 104.959 13.1532C102.867 13.1532 101.375 14.8126 101.375 17.147C101.375 19.4798 102.865 21.1642 104.959 21.1642C107.028 21.1642 108.472 19.4798 108.472 17.147Z"
                fill="#376576"
              />
              <path
                d="M113.653 27.0561L114.278 24.9389C114.807 25.228 115.407 25.3967 115.865 25.3967C116.564 25.3967 117.092 25.1795 117.453 24.5795L117.982 23.4733L112.714 10.9404H115.817L119.45 20.2248L122.865 10.9404H125.896L120.629 24.242C119.692 26.5998 118.248 27.6811 116.371 27.7061C115.432 27.7061 114.398 27.4655 113.653 27.0561Z"
                fill="#376576"
              />
              <path
                d="M31.1608 15.1313L16.0296 0L0.896729 15.1313L12.4046 26.6392L8.46237 31.2048H23.5952L19.653 26.6392L31.1608 15.1313ZM27.4249 16.1063L18.3749 25.1563L17.0046 23.5704V16.1063H27.4249ZM27.4249 14.1547H17.0046V3.73439L27.4249 14.1547ZM12.7264 29.2517L16.0296 25.4267L19.3327 29.2517H12.7264ZM15.053 23.572L13.6827 25.1579L4.63267 16.1079H15.053V23.572ZM4.63267 14.1547L15.053 3.73439V14.1547H4.63267Z"
                fill="#376576"
              />
            </svg>
          </div>
          <div className="md:hidden flex justify-center">
            <svg width="126" height="32" viewBox="0 0 126 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48.8435 14.2838H55.892V20.9713C54.3045 22.6307 51.731 23.6651 49.3248 23.6651C44.5857 23.6651 40.5935 19.7448 40.5935 15.1026C40.5935 10.4604 44.5873 6.56348 49.3248 6.56348C51.7295 6.56348 54.3045 7.57442 55.892 9.23379L53.7514 11.2307C52.717 10.0275 50.9373 9.30567 49.3248 9.30567C46.2451 9.30567 43.6482 11.951 43.6482 15.1026C43.6482 18.2776 46.2466 20.9229 49.3248 20.9229C50.5748 20.9229 51.9217 20.4901 52.9576 19.7448V16.7385H48.8435V14.2838Z" fill="#FFFBEF" />
              <path d="M69.1936 15.4619V23.376H66.3545V16.0401C66.3545 14.3557 65.2717 13.1541 63.7326 13.1541C62.0732 13.1541 60.7014 14.3572 60.7014 15.8479V23.376H57.767V5.61035L60.7014 6.56192V12.6479C61.4467 11.4447 62.9139 10.651 64.5733 10.651C67.2936 10.651 69.1936 12.6479 69.1936 15.4619Z" fill="#FFFBEF" />
              <path d="M82.5186 10.94V23.376H79.5843V21.7166C78.8139 22.8713 77.3718 23.6651 75.7358 23.6651C73.0171 23.6651 71.1171 21.6682 71.1171 18.8541V10.94H73.9561V18.276C73.9561 19.9604 75.0389 21.1619 76.578 21.1619C78.1171 21.1619 79.3921 20.1276 79.5843 18.8041V10.9385H82.5186V10.94Z" fill="#FFFBEF" />
              <path d="M96.9265 6.56211V23.3762H93.9921V21.4512C93.0062 22.8465 91.5155 23.6637 89.6858 23.6637C86.3905 23.6637 83.9858 20.9699 83.9858 17.1449C83.9858 13.3449 86.3905 10.6496 89.6858 10.6496C91.514 10.6496 93.0046 11.4668 93.9921 12.8621V5.60742L96.9265 6.56211ZM93.9921 17.1465C93.9921 14.8137 92.5484 13.1527 90.4796 13.1527C88.3874 13.1527 86.8952 14.8121 86.8952 17.1465C86.8952 19.4793 88.3858 21.1637 90.4796 21.1637C92.5484 21.1637 93.9921 19.4793 93.9921 17.1465Z" fill="#FFFBEF" />
              <path d="M111.406 6.56211V23.3762H108.472V21.4512C107.486 22.8465 105.995 23.6637 104.165 23.6637C100.87 23.6637 98.4653 20.9699 98.4653 17.1449C98.4653 13.3449 100.87 10.6496 104.165 10.6496C105.994 10.6496 107.484 11.4668 108.472 12.8621V5.60742L111.406 6.56211ZM108.472 17.1465C108.472 14.8137 107.028 13.1527 104.959 13.1527C102.867 13.1527 101.375 14.8121 101.375 17.1465C101.375 19.4793 102.865 21.1637 104.959 21.1637C107.028 21.1637 108.472 19.4793 108.472 17.1465Z" fill="#FFFBEF" />
              <path d="M113.653 27.0561L114.278 24.9389C114.808 25.228 115.407 25.3967 115.865 25.3967C116.564 25.3967 117.092 25.1795 117.453 24.5795L117.983 23.4733L112.714 10.9404H115.817L119.45 20.2248L122.865 10.9404H125.897L120.629 24.242C119.692 26.5998 118.248 27.6811 116.372 27.7061C115.432 27.7061 114.398 27.4655 113.653 27.0561Z" fill="#FFFBEF" />
              <path d="M31.1608 15.1313L16.0296 0L0.896729 15.1313L12.4046 26.6392L8.46237 31.2048H23.5952L19.653 26.6392L31.1608 15.1313ZM27.4249 16.1063L18.3749 25.1563L17.0046 23.5704V16.1063H27.4249ZM27.4249 14.1547H17.0046V3.73439L27.4249 14.1547ZM12.7264 29.2517L16.0296 25.4267L19.3327 29.2517H12.7264ZM15.053 23.572L13.6827 25.1579L4.63267 16.1079H15.053V23.572ZM4.63267 14.1547L15.053 3.73439V14.1547H4.63267Z" fill="#FFFBEF" />
            </svg>
          </div>
          <div className="my-14">
            <div className="flex justify-between w-full md:max-w-[174px]">
              <img src={instagram} alt="facebook" width={30} height={30} />
              <img src={facebook} alt="facebook" width={30} height={30} />
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill=""
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.6626 19.0558L23.0351 26.7363L15.0043 20.5983L20.6626 19.0558ZM28 10.6713H18.0713L15.0054 0.736328L11.9288 10.6736L2 10.6599L10.0405 16.8082L6.96383 26.7352L15.0043 20.5972L19.9692 16.8082L28 10.6724V10.6713Z"
                  fill="#000032"
                />
              </svg>
            </div>
          </div>

          <div className="text-bg-white md:text-txt-primary ">
            <p className="tracking-primary leading-small  text-[12px]">
              &copy; All Rights Reserved. Ghuddy, {new Date().getFullYear()}
            </p>
            <div className="flex items-center justify-between w-56 mt-2">
              <p className="tracking-primary leading-small  text-[12px]">
                Terms of Use
              </p>
              <div className="border-r w-[1px] bg-txt-primary h-3"></div>
              <p className="tracking-primary leading-small  text-[12px]">
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <h2 className="text-lg leading-medium tracking-primary text-txt-primary font-bold">
            Company
          </h2>
          <ul className="text-txt-primary">
            <li className="text-[12px] leading-small my-5">How it Works</li>
            <li className="text-[12px] leading-small my-5">Careers</li>
            <li className="text-[12px] leading-small my-5">F.A.Q</li>
          </ul>
        </div>
        <div className="hidden md:block">
          <h2 className="text-lg leading-medium tracking-primary text-txt-primary font-bold">
            Ghuddy Product
          </h2>
          <ul className="text-txt-primary">
            <li className="text-[12px] leading-small my-5">Rental Services</li>
            <li className="text-[12px] leading-small my-5">Become Merchant</li>
            <li className="text-[12px] leading-small my-5">Blog</li>
          </ul>
        </div>
        <div className="hidden md:block">
          <FooterButton title="Language" w="248px" h="49px" />
          <br />
          <FooterButton title="Currency" w="248px" h="49px" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
