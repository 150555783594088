import React from "react";

const FullSubmitButton = ({ buttonOutlinedHandle, disable, title }) => {
  return (
    <div>
      <button
        // disabled={disable}
        onClick={buttonOutlinedHandle}
        className={`w-[100%] h-[49px] border flex justify-center items-center rounded-[300px] leading-[25.2px] bg-btn-primary text-[18px] mt-[16px] text-bg-white  active:bg-txt-secondary hover:bg-btn-primary hover:text-bg-semiwhite` }
      >
        {title ? title : "Submit"}
      </button>
    </div>
  );
};

export default FullSubmitButton;
