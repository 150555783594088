import React, { useEffect, useState } from "react";
import Modal from "react-modal";
 
import FullSubmitButton from "../FullSubmitButton";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "300px",
    width: "535px",
    borderRadius: "32px",
    backgroundColor: "#FFFBEF",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const ModalWithSingleInput = ({
  openDefault,
  setOpenDefault,
  rejectionArray,
  setRejectionArray,
  name,
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    if (openDefault == true) {
      setIsOpen(true);
    }
  }, [openDefault]);
  // if(openDefault){
  //   openDefault ? openModal() : null
  // }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    setOpenDefault(false);
  }

  function handleInput(event) {
    const target = event.target;
    // const valuee = target.type === "checkbox" ? target.checked : target.value;
    const valuee = target.value;
    const namee = target.name;

    console.log("name:", namee);
    console.log("value:", valuee);

    setRejectionArray((prev) => {
      return {
        ...prev,
        [namee]: valuee,
      };
    }
    );
    // console.log("rejectionArray:", rejectionArray);
  }
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        // onClick={() => navigatepass ? navigate(`${navigatepass}`) : null}
        // onClick={() => console.log("hfbsh")}
      >
        <div className="bg-bg-primary min-h-full m-6">
          <button onClick={closeModal}>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                fill="#2A220E"
                fillOpacity="0.45"
              />
            </svg>
          </button>
          <div className="text-center py-[1%] leading-[4rem] font-sans ">
            <input
              placeholder={rejectionArray&& rejectionArray[name]?rejectionArray[name]:"Write tag note for this field"}
              defaultValue={rejectionArray&& rejectionArray[name]?rejectionArray[name]:""}
              className="bg-bg-primary border-2 border-btn-primary rounded-[8px] w-full"
              id={name}
              name={name}
              onChange={handleInput}
            />
            <FullSubmitButton buttonOutlinedHandle={closeModal}/>
            {
              rejectionArray&& rejectionArray[name]&& <FullSubmitButton title={"Approve"} buttonOutlinedHandle={()=>{
                setRejectionArray((prev)=>{
                  return {
                    ...prev,
                    [name]: ""
                  }
                })
                closeModal()
              }}/>
            }
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalWithSingleInput;
