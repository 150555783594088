import { getApps, initializeApp } from "firebase/app";
import "firebase/auth";
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    //   apiKey: "AIzaSyD02ba2cbE_X3sTRL85VzcWupQSuJUhlAU",
    // authDomain: "ghuddy-auth.firebaseapp.com",
    // projectId: "ghuddy-auth",
    // storageBucket: "ghuddy-auth.appspot.com",
    // messagingSenderId: "897050914613",
    // appId: "1:897050914613:web:8fa90b42ccb0d32fcf667c",
    // measurementId: "G-GCGDCKEE9H"
    // apiKey: "AIzaSyACW-AKI9d_K0x8pGErraFRe7nnb4ivPDE",
    // authDomain: "ghuddy.firebaseapp.com",
    // databaseURL: "https://ghuddy-default-rtdb.firebaseio.com",
    // projectId: "ghuddy",
    // storageBucket: "ghuddy.appspot.com",
    // messagingSenderId: "1003172544492",
    // appId: "1:1003172544492:web:5232793c0ad110dcb70ac9",
    // measurementId: "G-7SBF021ZDD"

    apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// const app = initializeApp(firebaseConfig);
export const firebaseApp = !getApps.length
    ? initializeApp(firebaseConfig)
    : null;
// console.log(firebaseApp);
