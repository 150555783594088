import React, { useEffect, useState } from "react";
import ButtonFilled from "../../components/ButtonFilled";
import SingleSelectParentCategoryReturn from "../../components/singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturn";
import { apiEndPoint, PROPERTY_ENDPOINTS } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { toastMessage } from "../../utils/toast";

export default function AdminCheleKhelaSync() {
    const [hotelList, setHotelList] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState(null);

    useEffect(() => {
        authAxios
            .get(`${apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN}?pageNumber=1&pageSize=145&requestId=1`)
            .then((res) => {
                console.log('test 1')
                setHotelList(res.data.entries);
                setSelectedHotel(res.data.entries[0]);
            })
            .catch((err) => {
                console.log('test 1',err)
                toastMessage("Error fetching data", "error",err);
            });
    }, []);

    const syncFunction = () => {
        authAxios
            .get(`${apiEndPoint.ADMIN_SYNC_HOTEL}${selectedHotel.propertyId}`)
            .then((res) => {
                toastMessage("Operation Successful");
            })
            .catch((err) => {});
    };

    return (
        <div className="container mx-auto">
            <div>
                {/* {selectedHotel && ( */}
                <SingleSelectParentCategoryReturn
                    warningView={false}
                    selectedValue={selectedHotel}
                    setSelectedValue={setSelectedHotel}
                    data={hotelList}
                    placeholder="Select Hotel"
                    displayField={"propertyName"}
                    selectedFieldName="id"
                />
                {/* )} */}
            </div>

            <div className="flex justify-center">
                <ButtonFilled
                    buttonFilledHandle={syncFunction}
                    title={"Sync now"}
                    arrow={"no"}
                    w={"300px"}
                />
            </div>
        </div>
    );
}