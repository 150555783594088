import React from "react";

export const SvgPDF = () => {
  return (
    <div>
      <svg
        width="36"
        height="42"
        viewBox="0 0 36 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.9394 9.02094V38.1209C35.9394 39.2809 35.3594 40.3109 34.4794 40.9309C33.9294 41.3309 33.2394 41.5509 32.5094 41.5509H7.53938C6.80938 41.5509 6.12937 41.3209 5.56938 40.9309C4.68938 40.3109 4.10938 39.2909 4.10938 38.1209V3.88094C4.10938 2.72094 4.68938 1.69094 5.57938 1.07094C6.12938 0.690938 6.79938 0.460938 7.52938 0.460938H27.6394L35.9394 9.02094Z"
          fill="#EAEAEA"
        />
        <path
          d="M35.9367 9.02094H29.9267C28.6667 9.02094 27.6367 8.00094 27.6367 6.73094V0.460938L35.9367 9.02094Z"
          fill="#A8A8A8"
        />
        <path
          d="M31.8494 19.25V31.93C31.8494 32.57 31.3294 33.09 30.6894 33.09H4.10938V19.25H31.8494Z"
          fill="#D4D4D6"
        />
        <path
          d="M31.8486 19.2004V30.5104C31.8486 31.3604 31.1586 32.0604 30.2986 32.0604H1.60859C0.758593 32.0604 0.0585938 31.3704 0.0585938 30.5104V19.2004C0.0585938 18.3504 0.748593 17.6504 1.60859 17.6504H30.3086C31.1586 17.6504 31.8486 18.3404 31.8486 19.2004Z"
          fill="#F15642"
        />
        <path
          d="M7.29688 28.18V21.5H9.45687C10.2769 21.5 10.8069 21.53 11.0569 21.6C11.4369 21.7 11.7569 21.92 12.0169 22.25C12.2769 22.59 12.4069 23.02 12.4069 23.55C12.4069 23.96 12.3369 24.3 12.1869 24.58C12.0369 24.86 11.8469 25.08 11.6169 25.24C11.3869 25.4 11.1569 25.5 10.9169 25.56C10.5969 25.62 10.1269 25.66 9.51688 25.66H8.63688V28.18H7.29688ZM8.64687 22.63V24.53H9.38687C9.91687 24.53 10.2769 24.5 10.4569 24.43C10.6369 24.36 10.7769 24.25 10.8769 24.1C10.9769 23.95 11.0269 23.78 11.0269 23.58C11.0269 23.34 10.9569 23.14 10.8169 22.98C10.6769 22.82 10.4969 22.72 10.2769 22.68C10.1169 22.65 9.79687 22.63 9.30687 22.63H8.64687Z"
          fill="#EAEAEA"
        />
        <path
          d="M13.5156 21.5H15.9856C16.5456 21.5 16.9656 21.54 17.2556 21.63C17.6456 21.75 17.9856 21.95 18.2656 22.25C18.5456 22.54 18.7556 22.91 18.9056 23.33C19.0556 23.76 19.1256 24.28 19.1256 24.91C19.1256 25.46 19.0556 25.93 18.9156 26.33C18.7456 26.82 18.5056 27.21 18.1956 27.51C17.9656 27.74 17.6456 27.92 17.2456 28.04C16.9456 28.13 16.5456 28.18 16.0556 28.18H13.5156V21.5ZM14.8656 22.63V27.05H15.8756C16.2556 27.05 16.5256 27.03 16.6956 26.99C16.9156 26.94 17.0956 26.84 17.2356 26.71C17.3756 26.58 17.4956 26.36 17.5856 26.07C17.6756 25.77 17.7256 25.36 17.7256 24.85C17.7256 24.33 17.6756 23.94 17.5856 23.66C17.4956 23.38 17.3656 23.17 17.2056 23.01C17.0456 22.86 16.8356 22.75 16.5856 22.7C16.3956 22.66 16.0256 22.64 15.4756 22.64H14.8656V22.63Z"
          fill="#EAEAEA"
        />
        <path
          d="M20.2656 28.18V21.5H24.8456V22.63H21.6156V24.21H24.4056V25.34H21.6156V28.18H20.2656Z"
          fill="#EAEAEA"
        />
        <path
          d="M31.2383 9.02148L35.9383 13.8615V9.02148H31.2383Z"
          fill="#D4D4D6"
        />
      </svg>
    </div>
  );
};

export const SvgPrint = () => {
  return (
    <div>
      <svg
        width="34"
        height="36"
        viewBox="0 0 34 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="11.7109"
          width="33.8313"
          height="17.7831"
          rx="2.4"
          fill="#4E90A8"
        />
        <mask id="path-2-inside-1_6077_18056" fill="white">
          <rect
            x="4.77344"
            y="17.3496"
            width="24.2892"
            height="18.6506"
            rx="2.4"
          />
        </mask>
        <rect
          x="4.77344"
          y="17.3496"
          width="24.2892"
          height="18.6506"
          rx="2.4"
          fill="#FFFBEF"
          stroke="#4E90A8"
          strokeWidth="6"
          mask="url(#path-2-inside-1_6077_18056)"
        />
        <path
          d="M16.699 10.8438L21.3943 5.96423H12.0037L16.699 10.8438Z"
          fill="#4E90A8"
        />
        <rect x="15.6133" width="2.16867" height="6.07229" fill="#4E90A8" />
        <rect
          x="4.77344"
          y="6.07227"
          width="2.16867"
          height="5.63855"
          fill="#4E90A8"
        />
        <rect
          x="26.8906"
          y="6.07227"
          width="2.16867"
          height="5.63855"
          fill="#4E90A8"
        />
      </svg>
    </div>
  );
};

export const SvgMail = () => {
  return (
    <div>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.9887 29.8828C36.1252 29.514 20.4556 17.6822 20.4556 17.6822C18.4938 16.2345 17.4621 16.2118 15.5222 17.6822C15.5222 17.6822 -0.124625 29.5044 0.00707031 29.8681L0.00390625 29.8708C0.00552344 29.874 0.008125 29.8761 0.0103047 29.879C0.14298 30.2407 0.336003 30.5773 0.581172 30.8744C0.684742 31 0.809547 31.1052 0.92957 31.2145L0.923734 31.219C0.933297 31.2276 0.946023 31.2315 0.955586 31.24C1.4643 31.6938 2.10583 31.9877 2.81851 32.0716C2.82969 32.0729 2.83869 32.0788 2.84987 32.0801H2.89291C3.00126 32.0904 3.10426 32.1164 3.21522 32.1164H32.7972C34.2538 32.1164 35.4879 31.2004 35.9782 29.9163C35.984 29.9067 35.993 29.8998 35.9983 29.89L35.9887 29.8828Z"
          fill="#376576"
        />
        <path
          d="M18.0098 16.3252L0.0859375 29.7681V10.724C0.0859375 8.86776 1.59041 7.36328 3.44666 7.36328H32.5729C34.4292 7.36328 35.9336 8.86776 35.9336 10.724V29.7681L18.0098 16.3252Z"
          fill="#4E90A8"
        />
        <path
          d="M0.292969 9.55471L15.5961 21.5746C17.5276 22.9999 18.5435 23.0224 20.4535 21.5746L35.7327 9.57377C35.4676 9.09325 35.0707 8.71363 34.6169 8.41094L34.827 8.24627C34.3141 7.77932 33.6584 7.47662 32.9306 7.39844L3.08613 7.39949C2.36037 7.47663 1.70365 7.78038 1.19177 8.24627L1.40039 8.4089C0.951797 8.70737 0.558609 9.08045 0.292969 9.55471Z"
          fill="#FFFBEF"
        />
        <path
          d="M0.290672 9.58016C0.156305 9.94333 15.5845 21.5927 15.5845 21.5927C17.516 23.018 18.5318 23.0405 20.4419 21.5927C20.4419 21.5927 35.8476 9.95275 35.7178 9.59458L35.721 9.59198C35.7194 9.58881 35.7168 9.58677 35.7147 9.58389C35.5841 9.22778 35.3941 8.89639 35.1527 8.6038C35.0507 8.48012 34.9278 8.37663 34.8097 8.26891L34.8154 8.26448C34.806 8.25611 34.7935 8.25217 34.7841 8.2438C34.2832 7.79704 33.6516 7.50763 32.9499 7.42502C32.9389 7.42368 32.93 7.41798 32.919 7.41665H32.8767C32.7701 7.40645 32.6686 7.38086 32.5593 7.38086H3.43308C1.99884 7.38086 0.783703 8.28283 0.301148 9.54712C0.295383 9.55654 0.286523 9.56336 0.28125 9.57299L0.290672 9.58016Z"
          fill="#FEE29A"
        />
      </svg>
    </div>
  );
};

export const SvgCross = () => {
  return (
    <div>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.148 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
          fill="#2A220E"
          fillOpacity="0.45"
        />
      </svg>
    </div>
  );
};

export const SvgPlus = () => {
  return (
    <div>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.724 0V5.859H0V7.938H5.724V13.77H8.019V7.938H13.77V5.859H8.019V0H5.724Z"
          fill="#376576"
        />
      </svg>
    </div>
  );
};

export const HorizontalThreeDot = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26C17.1046 26 18 25.1046 18 24Z"
          fill="#2A220E"
        />
        <path
          d="M18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16Z"
          fill="#2A220E"
        />
        <path
          d="M18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8Z"
          fill="#2A220E"
        />
      </svg>
    </div>
  );
};

export const RoundThreeDot = () => {
  return (
    <div>
      <svg
        width="74"
        height="72"
        viewBox="0 0 74 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_2923_14520)">
          <rect
            x="19.8203"
            y="15"
            width="41.131"
            height="41"
            rx="20.5"
            fill="#FFFBEF"
            fillOpacity="0.7"
            shapeRendering="crispEdges"
          />
          <path
            d="M42.8203 44C42.8203 42.8954 41.9249 42 40.8203 42C39.7157 42 38.8203 42.8954 38.8203 44C38.8203 45.1046 39.7157 46 40.8203 46C41.9249 46 42.8203 45.1046 42.8203 44Z"
            fill="#376576"
          />
          <path
            d="M42.8203 36C42.8203 34.8954 41.9249 34 40.8203 34C39.7157 34 38.8203 34.8954 38.8203 36C38.8203 37.1046 39.7157 38 40.8203 38C41.9249 38 42.8203 37.1046 42.8203 36Z"
            fill="#376576"
          />
          <path
            d="M42.8203 28C42.8203 26.8954 41.9249 26 40.8203 26C39.7157 26 38.8203 26.8954 38.8203 28C38.8203 29.1046 39.7157 30 40.8203 30C41.9249 30 42.8203 29.1046 42.8203 28Z"
            fill="#376576"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2923_14520"
            x="0.820312"
            y="-1"
            width="73.1328"
            height="73"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-3" />
            <feGaussianBlur stdDeviation="8" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2923_14520"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2923_14520"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export const CategoryEnableSvg = () => {
  return (
    <div>
      <svg
        width="32"
        height="16"
        viewBox="0 0 32 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <rect
            x="0.5"
            y="0.5"
            width="31"
            height="15"
            rx="7.5"
            stroke="#2A220E"
            strokeOpacity="0.45"
          />
        </g>
        <rect width="32" height="16" rx="8" fill="#4E90A8" />
        <circle cx="24" cy="8" r="5" fill="#FFFBEF" />
      </svg>
    </div>
  );
};

export const CategoryDisableSvg = () => {
  return (
    <div>
      <svg
        width="32"
        height="16"
        viewBox="0 0 32 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <rect width="32" height="16" rx="8" fill="#FFFBEF" />
          <rect
            x="0.5"
            y="0.5"
            width="31"
            height="15"
            rx="7.5"
            stroke="#2A220E"
            strokeOpacity="0.45"
          />
        </g>
        <circle cx="8" cy="8" r="5" fill="#4E90A8" />
      </svg>
    </div>
  );
};
