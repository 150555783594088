import { format } from 'date-fns';
import React from 'react';

const AdminPaymentSlipPrinted = (props) => {
    const {receiptNr,paymentDate,invoiceDetails,externalReference,paidAmount,receivedAmount,fee} = props;
    return (
        <div
            id="printable"
            className=' bg-[#FFFFFF] pb-[30px] w-full flex flex-col justify-center'>
           
                            
                            <div className="p-4 flex justify-between w-full">
                                <div>
                                    <img src="/logo.svg" alt="logo" />
                                </div>
                                <div className="text-xl font-black uppercase tracking-[0.5rem]">
                                Cash Receipt
                                </div>
                            </div>
                        <hr className="my-2" />

                        <div className='p-4'>
                          <div className='flex justify-between my-4'>
                            <div>Receipt Nr. {receiptNr && receiptNr}</div>
                            <div> Payment date :{format(
                                                new Date(paymentDate),
                                                "d/M/yyyy"
                                            )}</div>

                          </div>

                          <div className='mt-[40px] flex flex-col space-y-[5px]'>
                            <span>Received from : {invoiceDetails?.customerName} </span>
                            <span>Phone number : {invoiceDetails?.phoneNumber} </span>
                            <span>External Reference : {externalReference}</span>
                            <span>Payment amount details:</span>

                          </div>

                          {/* table */}
                          <div className='flex   text-[16px] '>
                            <div className=' flex flex-col items-start w-[200px]'>
                              <span className='border w-full pl-[5px]'> Paid Amount</span>
                              <span className='border w-full  pl-[5px]'> Received Amount</span>
                              <span className='border w-full  pl-[5px]'> Fee</span>
                            </div>
                            <div className=' flex flex-col items-start w-[200px]'>
                            <span className='border w-full pl-[5px]'> {paidAmount? paidAmount : "0"}</span>
                              <span className='border w-full  pl-[5px]'> {receivedAmount? receivedAmount : "0"} </span>
                              <span className='border w-full  pl-[5px]'> {fee} </span>
                            </div>
                          </div>

                        </div>

                        <div className='w-full flex justify-end mt-[50px]'>
                        <div className="p-4 tracking-wider text-[12px]">
                                            Ghuddy Limited <br />
                                            Rahman Villa, Ka-18, Baridhara North Road
                                            <br />
                                            Gulshan, Dhaka-1212, Bangladesh
                                            <br />
                                            +880 1797078379
                                            <br />
                                            support@ghuddy.com
                                            <br />
                                        </div>

                        </div>

                        <div className="text-[12px] text-center flex flex-col justify-end mt-[20px]">
                                    <div className="py-2"> Ghuddy </div>
                                    <div className="px-2 border-t py-2 ">
                                        <div className="font-bold uppercase tracking-wider">
                                            Thank you for your purchase
                                        </div>
                                    </div>
                                </div>

            </div>
    );
};

export default AdminPaymentSlipPrinted;