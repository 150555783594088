import { UserType } from "../contants/Constants";

export const RouteType={
    DASHBOARD:"DASHBOARD",
    REGISTRATION:"REGISTRATION",
}

export default function dashboardOrRegistrationRoute(){
    if (window.location.pathname.includes('registration') || process.env.REACT_APP_USER_TYPE === UserType.ADMIN ) {
        return RouteType.REGISTRATION;
    }
    else if (window.location.pathname.includes('dashboard')){
        return RouteType.DASHBOARD
    }
}