import React, { useEffect, useState } from "react";

const CurrentTimeDateShow = ({time, date}) => {
  const [dateState, setDateState] = useState(new Date());

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    var strTime = hours + ":" + minutes + ampm;
    return strTime;
  }

  function formatDate(date) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + " " + monthNames[monthIndex] + " " + year;
  }

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 10000);
  }, []);
  return (
    <div className="w-full h-[200px] custom-shadow rounded-[32px] py-6 px-6">
      <p className="text-[40px]">{formatAMPM(dateState)} <br />{formatDate(dateState)}</p>
    </div>
  );
};

export default CurrentTimeDateShow;
