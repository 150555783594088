// product list

export const ADMIN_CONTRACT_REQUEST = 'ADMIN_CONTRACT_REQUEST';
export const ADMIN_CONTRACT_SUCCESS = 'ADMIN_CONTRACT_SUCCESS';
export const ADMIN_CONTRACT_ERROR = 'ADMIN_CONTRACT_ERROR'; 

export const OWNER_INFO_GET_REQUEST = 'OWNER_INFO_GET_REQUEST';
export const OWNER_INFO_GET_SUCCESS = 'OWNER_INFO_GET_SUCCESS';
export const OWNER_INFO_GET_ERROR = 'OWNER_INFO_GET_ERROR'; 

export const DOCUMENTS_INFO_GET_REQUEST = 'DOCUMENTS_INFO_GET_REQUEST';
export const DOCUMENTS_INFO_GET_SUCCESS = 'DOCUMENTS_INFO_GET_SUCCESS';
export const DOCUMENTS_INFO_GET_ERROR = 'DOCUMENTS_INFO_GET_ERROR'; 

export const CATEGORIES_INFO_GET_REQUEST = 'CATEGORIES_INFO_GET_REQUEST';
export const CATEGORIES_INFO_GET_SUCCESS = 'CATEGORIES_INFO_GET_SUCCESS';
export const CATEGORIES_INFO_GET_ERROR = 'CATEGORIES_INFO_GET_ERROR'; 

export const CATEGORIES_DETAILS_GET_REQUEST = 'CATEGORIES_DETAILS_GET_REQUEST';
export const CATEGORIES_DETAILS_GET_SUCCESS = 'CATEGORIES_DETAILS_GET_SUCCESS';
export const CATEGORIES_DETAILS_GET_ERROR = 'CATEGORIES_DETAILS_GET_ERROR';   


