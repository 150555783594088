import React, { useEffect, useState } from 'react';

const DayComponents = (props) => {
  const{days,setDays,dayName,isAllday,setIsAllDay, dayTagName, setIsAllDayCheck, isAlldayCheck} = props;
  const [isChecked,setIsChecked] = useState(false);

  const handleChecked =()=>{
    if(isChecked){
      setIsChecked(false);
      setDays(days.filter((day)=>(day !== dayName)));
      setIsAllDayCheck(false);
    }else{
    setIsChecked(true);
    setDays([...days,dayName])
  }
  }

  // const handleDays=()=>{
  //   if(isChecked){
  //     setDays([...days,dayName])
  //   }else{
  //     setDays(days.filter((day)=>(day !== dayName)))
  // }
  // };

  
  useEffect(()=>{
    if(isAlldayCheck === true){
      setDays(dayTagName);
      setIsChecked(true);
      console.log(dayName)
    }
    else if(!isAllday){
      setIsChecked(false);
      setDays([]);
    }
   // handleDays();
  },[isAllday,dayName,isAlldayCheck])

  return (

          <li onClick={handleChecked} className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div className="flex items-center pl-3">
                  <input checked={isChecked} onChange={()=>{}} id="check-day" type="checkbox"  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="check-day" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{props.dayName}</label>
              </div>
          </li>
  );
};

export default DayComponents;
