import React from 'react';
import { useNavigate } from 'react-router';
import { adminRoute } from '../../contants/Constants';

const AdmincontractReview = () => {

    const newcontract = [{contractId:58, contractName:'name of contract'},{contractId:59, contractName:'name of contract'},{contractId:60, contractName:'name of contract'},];
    const navigate = useNavigate();
    const handleToReview = (id)=>{
    navigate(`${adminRoute.ADMIN_HOTEL}${id}`)
    }
    return (
        <div>
            <div className="flex flex-cols w-full">
        <div>
          <h2 className="text-3xl mb-6 text-center">Ghuddy Admin</h2>
          <h1 className='text-2xl text-btn-secondary'>Review contract to accept</h1>
          <h2 className="text-xl my-6">Total Contract : {newcontract.length}</h2>
          <table id="students" className="min-w-full leading-normal">
            <thead>
              <tr className="h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Contract ID
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Contract Name
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                Action
                </th>
              </tr>
            </thead>
            <div className="my-[14px]"></div>
            <tbody>
              {newcontract &&
                newcontract.map((member, index) => {
                  const {
                    contractId,contractName
                  } = member; //destructuring
                  return (
                    <tr className="cursor-pointer"
                      //onClick={() => handleClickContract(contractId)}
                      key={contractId}
                    >
                      <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm">
                        {contractId}
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                        {contractName}
                      </td>
                      <td className="px-5 py-5 border-b border-r border-t bg-white text-sm ">
                      <span onClick={()=>handleToReview(contractId)}  className='border py-[1rem] px-[0.8rem] cursor-pointer rounded-[0.8rem] hover:text-bg-primary hover:bg-btn-secondary'>
                        Review Hotel
                      </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
            
        </div>
    );
};

export default AdmincontractReview;