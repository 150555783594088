import React, { useState } from "react";

const Singletask = ({
    status,
    total,
    duration,
    checkIn,
    onModalOpen,
    itemIndex,
    sourceIndex,
    id,
    name,
    email,
}) => {
    // Booking modal trigger
    return (
        <div className="px-3">
            <div className="custom-shadow rounded-[8px] bg-bg-semiblack ">
                <div className="task bg-bg-primary hover:bg-bg-primaryShadow active:bg-bg-primaryShadow" draggable="true">
                    <div className="task__tags">
                        <span className=""></span>
                        <button
                            className="task__options"
                            onClick={() => {
                                onModalOpen(itemIndex, sourceIndex);
                            }}
                        >
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M23.9297 13.8086C22.8251 13.8086 21.9297 14.704 21.9297 15.8086C21.9297 16.9132 22.8251 17.8086 23.9297 17.8086C25.0343 17.8086 25.9297 16.9132 25.9297 15.8086C25.9297 14.704 25.0343 13.8086 23.9297 13.8086Z"
                                    fill="#376576"
                                />
                                <path
                                    d="M15.9297 13.8086C14.8251 13.8086 13.9297 14.704 13.9297 15.8086C13.9297 16.9132 14.8251 17.8086 15.9297 17.8086C17.0343 17.8086 17.9297 16.9132 17.9297 15.8086C17.9297 14.704 17.0343 13.8086 15.9297 13.8086Z"
                                    fill="#376576"
                                />
                                <path
                                    d="M7.92969 13.8086C6.82512 13.8086 5.92969 14.704 5.92969 15.8086C5.92969 16.9132 6.82512 17.8086 7.92969 17.8086C9.03426 17.8086 9.92969 16.9132 9.92969 15.8086C9.92969 14.704 9.03426 13.8086 7.92969 13.8086Z"
                                    fill="#376576"
                                />
                            </svg>
                        </button>
                    </div>
                    {/* availability calender popup */}

                    {/* availability calender popup */}
                    <p className="py-2 break-words">Booking #{id} </p>
                    <p className="py-1 grid grid-cols-2">
                        <span>Name:</span>
                        {name}
                    </p>
                    <p className="py-1 grid grid-cols-2 break-words">
                        <span>Email:</span>
                        {email}
                    </p>
                    <p className="py-1 grid grid-cols-2">
                        <span>CheckIn:</span>
                        {checkIn}{" "}
                    </p>
                    <p className="py-1 grid grid-cols-2">
                        <span>Duration:</span>
                        {duration} Nights
                    </p>
                    <div className="flex justify-between py-1">
                        <p className="">Total: {total}</p>
                        <div className="task__stats text-btn-primary">
                            <span
                                className={`capitalize ${
                                    status === "REJECTED" && "text-[red]"
                                } ${(status === "BOOKED" || status === 'ACCEPTED' ) && "text-[green]"}`}
                            >
                                {(status === "BOOKING_REQUESTED" ||
                                    status === "INIT") &&
                                    "NEW"}
                                {status === "BOOKED" && "CONFIRMED"}
                                {status === "REJECTED" && "REJECTED"}
                                {status === "ACCEPTED" && "ACCEPTED"}
                                {status === "MERCHANT_CANCELED" &&
                                    "MERCHANT CANCELLED"}
                                {status === "CANCELED" && "CANCELED"}
                                {(status === "BOOKING_IN_PROGRESS"  || status === 'IN_PROCESS') && "IN PROGRESS"}
                                
                            </span>
                            <svg
                                width="50"
                                height="2"
                                viewBox="0 0 50 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="50" height="2" fill="#C4C4C4" />
                                <rect width="30" height="2" fill="#376576" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Singletask;
