import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router";
import ButtonFilled from "../../../components/ButtonFilled";
import FileMultipleCategory from "../../../components/multipleimage/FileMultipleCategory";
import { apiEndPoint } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import { toastMessage } from "../../../utils/toast";

function AdminTourEditCategory() {
    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image"],
            ["clean"],
        ],
    };

    const [title, setTitle] = useState("");
    const [amenty, setAmnety] = useState("");
    const [inclusionText, setInclusionText] = useState("");
    const [exclusionText, setExclusionText] = useState("");
    const [description, setDescription] = useState("");
    const [duration, setDuration] = useState(1);
    const [person, setPerson] = useState(0);
    const [price, setPrice] = useState(0);

    const [amneties, setAmneties] = useState([]);
    const [exclusion, setExclusion] = useState([]);
    const [inclusion, setInclusion] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);

    const navigate = useNavigate();
    const { catId } = useParams();
    function createMarkup() {
        return { __html: description };
    }

    const submitData = () => {
        setIsLoading(true);
        try {
            const data = {
                amenities: amneties,
                description: description,
                duration,
                exclusion,
                imageUrls: imageUrls.map((el) => el.url),
                inclusion,
                person,
                price,
                requestId: "string",
                title,
            };
            // console.log(data);
            authAxios
                .patch(
                    `${apiEndPoint.ADMIN_CREATE_TOUR_PACKAGE}/${catId}`,
                    data
                )
                .then((res) => {
                    toastMessage("Updated Successfully");
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toastMessage("Operation Failed");
                });
        } catch (error) {
            setIsLoading(false);
            toastMessage("Operation Failed");
        }
    };

    const getPackageDetails = () => {
        authAxios
            .get(`${apiEndPoint.ADMIN_CREATE_TOUR_PACKAGE}/${catId}`)
            .then((res) => {
                const response = res.data.tourCategoryData;
                setTitle(response.title);
                setDescription(response.description.description);
                setAmneties(response.amenities.dataList);
                setInclusion(response.inclusion.dataList);
                setExclusion(response.exclusion.dataList);
                setDuration(response.duration);
                setImageUrls(
                    response.imageUrls.imageUrls.map((item) => {
                        return {
                            url: item,
                        };
                    })
                );
                setPerson(response.person);
                setPrice(response.price);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        if (catId) {
            getPackageDetails();
        }
    }, [catId]);

    return (
        <div className="container mx-auto">
            <div className="flex justify-between mb-2">
                <div className="text-xl gap-2 flex items-center">
                    <span className="">
                        <button
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <i className="fa fa-arrow-left"></i>
                        </button>
                    </span>
                    Edit Tour Package
                </div>
                <ButtonFilled
                    title={"Submit"}
                    arrow="no"
                    w={"250px"}
                    buttonFilledHandle={submitData}
                />
            </div>
            <hr />

            <div className="flex gap-4 items-start pt-4">
                <div className="flex flex-col gap-y-4 w-1/2 h-[800px] overflow-y-scroll">
                    <div>
                        <div className="mb-1"> Package Title </div>
                        <input
                            type={"text"}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                        />
                    </div>
                    <div>
                        <div className="mb-1"> Package description </div>
                        <ReactQuill
                            theme="snow"
                            defaultValue={""}
                            value={description}
                            onChange={setDescription}
                            placeholder={"Write something..."}
                            modules={modules}
                        />
                    </div>
                    <div className="flex gap-2 w-full">
                        <div className="w-full">
                            <div className="mb-1"> Package Price </div>
                            <input
                                type={"text"}
                                value={price}
                                onChange={(e) => {
                                    setPrice(e.target.value);
                                }}
                                className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                            />
                        </div>
                        <div className="w-full">
                            <div className="mb-1">Total Person</div>
                            <input
                                type={"text"}
                                value={person}
                                onChange={(e) => {
                                    setPerson(e.target.value);
                                }}
                                className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                            />
                        </div>
                    </div>
                    <div className="flex gap-2 w-full">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (inclusionText.length > 0) {
                                    inclusion.push(inclusionText);
                                    setInclusionText("");
                                }
                            }}
                            className="w-full"
                        >
                            <div className="mb-1"> Add inclusion </div>
                            <input
                                type={"text"}
                                value={inclusionText}
                                onChange={(e) => {
                                    setInclusionText(e.target.value);
                                }}
                                className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                            />
                            <button type="submit"></button>
                        </form>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (exclusionText.length > 0) {
                                    exclusion.push(exclusionText);
                                    setExclusionText("");
                                }
                            }}
                            className="w-full"
                        >
                            <div className="mb-1"> Add exclusion </div>
                            <input
                                type={"text"}
                                value={exclusionText}
                                onChange={(e) => {
                                    setExclusionText(e.target.value);
                                }}
                                className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                            />
                        </form>
                        <button type="submit"></button>
                    </div>
                    <div className="flex gap-2 w-full">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (amenty.length > 0) {
                                    amneties.push(amenty);
                                    setAmnety("");
                                }
                            }}
                            className="w-full"
                        >
                            <div className="mb-1"> Add amneties </div>
                            <input
                                type={"text"}
                                value={amenty}
                                onChange={(e) => {
                                    setAmnety(e.target.value);
                                }}
                                className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                            />
                            <button type="submit"></button>
                        </form>

                        <div className="w-full">
                            <div className="mb-1">Package Duration (days)</div>
                            <input
                                type={"text"}
                                value={duration}
                                onChange={(e) => {
                                    setDuration(e.target.value);
                                }}
                                className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                            />
                        </div>
                    </div>

                    <div className="text-[#1b1b1b]">
                        <div className="mb-2">Package images</div>
                        <FileMultipleCategory
                            id={1}
                            setNeedUpdate={() => {}}
                            urlTempStorageArray={imageUrls}
                            setUrlTempStorageArray={setImageUrls}
                            multiple={true}
                            type={"default"}
                            upload={true}
                        />
                    </div>
                </div>

                <div className="border-l border-[#e1e1e1] pl-5 w-1/2 h-[800px] overflow-y-scroll ">
                    <div className="p-6 shadow-customShadow rounded-lg">
                        <div className="flex gap-2 flex-wrap mt-2">
                            {imageUrls.map((image) => (
                                <div className="rounded-lg shadow-lg border border-[#e2e2e2] overflow-hidden w-[220px] h-[120px]">
                                    <img
                                        src={image.url}
                                        alt="Banner"
                                        className="object-cover w-full"
                                    />
                                </div>
                            ))}
                            {imageUrls.length === 0
                                ? [1, 2, 3].map((item) => (
                                      <div className="rounded-lg  shadow overflow-hidden w-[220px] h-[120px] flex justify-center items-center text-[#818181] font-extralight">
                                          Add Image
                                      </div>
                                  ))
                                : null}
                        </div>
                        <div className="text-4xl font-bold my-8 text-[#1b1b1b]">
                            {title || "Package Title"}
                        </div>
                        <div className="flex justify-start gap-4 my-2">
                            <div className="bg-btn-primary px-4 py-1 text-bg-white rounded-xl">
                                {person} person
                            </div>
                            <div className="bg-btn-secondary px-4 py-1 text-bg-white rounded-xl">
                                {price} BDT
                            </div>
                            <div style={{ flex: "1 1 auto" }}></div>
                            <div className="text-[#838383] text-xl">
                                <span>
                                    <i className="fa fa-clock"></i>
                                </span>
                                {duration} Days
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-4 my-4">
                            {amneties.map((item) => (
                                <div className="flex items-center shadow-lg pl-3  cursor-pointer rounded group/item">
                                    <div className="py-1 pr-2">{item}</div>
                                    <button
                                        onClick={() => {
                                            setAmneties([
                                                ...amneties.filter(
                                                    (el) => el !== item
                                                ),
                                            ]);
                                        }}
                                        className="hover:bg-bg-white hidden bg-bg-primary py-1 px-2 group/edit group-hover/item:block"
                                    >
                                        X
                                    </button>
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-between">
                            <div className="w-1/2">
                                <div className="font-semibold">Inclusions:</div>
                                <ul className="pl-2">
                                    {inclusion.map((item) => (
                                        <li>
                                            {item}
                                            <button
                                                onClick={() => {
                                                    setInclusion([
                                                        ...inclusion.filter(
                                                            (el) => el !== item
                                                        ),
                                                    ]);
                                                }}
                                                className="hover:bg-bg-white bg-bg-primary py-1 px-2 "
                                            >
                                                X
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="w-1/2">
                                <div className="font-semibold">Exclusions:</div>
                                <ul className="pl-2">
                                    {exclusion.map((item) => (
                                        <li>
                                            {item}
                                            <button
                                                onClick={() => {
                                                    setExclusion([
                                                        ...exclusion.filter(
                                                            (el) => el !== item
                                                        ),
                                                    ]);
                                                }}
                                                className="hover:bg-bg-white bg-bg-primary py-1 px-2 "
                                            >
                                                X
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-4 font-semibold">Description</div>
                        <div
                            className="mt-2 min-h-[200px]"
                            dangerouslySetInnerHTML={createMarkup()}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminTourEditCategory;
