import React from "react";

const WarningSVG = () => {
  return (
    <div>
      <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="49" height="49" rx="24.5" fill="#FEE29A" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.8968 35.2058C25.4227 35.2058 25.927 34.9969 26.2988 34.625C26.6707 34.2532 26.8796 33.7489 26.8796 33.223C26.8796 32.6971 26.6707 32.1928 26.2988 31.821C25.927 31.4491 25.4227 31.2402 24.8968 31.2402C24.371 31.2402 23.8666 31.4491 23.4948 31.821C23.123 32.1928 22.9141 32.6971 22.9141 33.223C22.9141 33.7489 23.123 34.2532 23.4948 34.625C23.8666 34.9969 24.371 35.2058 24.8968 35.2058Z"
          fill="#FFFBEF"
        />
        <path
          d="M24.8984 15.3789V28.0686"
          stroke="#FFFBEF"
          strokeWidth="3.17241"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default WarningSVG;
