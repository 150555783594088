import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ButtonFilled from '../../../components/ButtonFilled';
import InputComponent from '../../../components/InputComponent';
import SingleDropdownSelect from '../../../components/SingleDropdownSelect';
import SingleSelectFromSimpleArray from '../../../components/singleSelect/SingleSelectFromSimpleArray';
import { apiEndPoint, baseUrl } from '../../../contants/Endpoints';
import { authAxios } from '../../../utils/axiosWrapper';

const AdminCreatePaymentMethod = () => {
    const [bankAccountNr,setBankAccountNr]= useState('');
    const [bankBranchName,setBankBranchName]= useState('');
    const [bankName,setBankName]= useState('');
    const [methodName,setmethodName]= useState('');
    const [methodType,setmethodType]= useState('MOBILE');
    const [phoneNo,setPhoneNo] = useState('');
    const [allMethodType,setAllMethodType] = useState([]);
    const [allMethod,setAllMethod] = useState([]);


    useEffect(()=>{
        authAxios.get(`${baseUrl}${apiEndPoint.ADMIN_GET_PAYMENT_METHOD_TYPE}`)
        .then((res)=>{
          setAllMethodType(res.data.paymentMethodTypes);
        })
    },[setAllMethodType])

    useEffect(()=>{
      authAxios.get(`${baseUrl}${apiEndPoint.ADMIN_GET_ALL_PAYMENT_METHOD}`)
      .then((res)=>{
        setAllMethod(res.data.paymentMethods);
      })
    },[])

    //console.log(allMethodType,'testi')
    const adminCreateMethodHandler =()=>{
      const method = {
        bankAccountNr,
        bankBranchName,
        bankName,
        id:0,
        methodName,
        methodType,
        phoneNo,
        requestId:"11"
      }
      authAxios.post(`${baseUrl}${apiEndPoint.ADMIN_CREATE_PAYMENT_METHOD}`,method)
      .then((res) => {    
        toast.success("Created successfully!");
        window.reload();
    })
    .catch((err) => {});

    }



    return (
        <div className="px-[16px]   border  ">
        <div className="max-w-[1100px]  mx-auto ">
          <h2 className="text-center text-[22px] pt-[30px] underline">
            Create Payment Method
          </h2> 
        

          <div className='flex mt-[2rem] gap-6'>
          <div className='w-1/2  flex justify-center '>
            <div className='  px-4 py-2 border  border-txt-hint rounded-[1rem] w-[300px] lg:w-[600px]'>
            <div className='text-btn-primary w-full text-center'>Create New</div>
            <div className='space-y-[1rem] mt-[2rem] '> 
            <div className='bg-bg-primary w-[250px] '>
                <p>Select Method type :</p>
               {allMethodType.length>0 && 
               <SingleSelectFromSimpleArray  
                              required={true}
                              data={allMethodType}
                              selectedValue={methodType}
                              setSelectedValue={
                                  setmethodType
                              }
                              placeholder="Method Type"
                              isAdmin={false}
                              isReadOnly={false}
                              // rejectionArray={
                              //     addressRejectArray
                              // }
                              // setRejectionArray={
                              //     setAddressRejectArray
                              // }
                              name="MethodSelector"
                          />}
            </div>
           <div className='flex'>
           <InputComponent
                                name={"MethodName"}
                                inputValue={methodName}
                                setInputValue={(val) => setmethodName(val)}
                                warningView={false}
                                placeholder="Method Name" 
                                
                                />
                                
           </div>

                
                               
                {methodType==="BANK" && <InputComponent
                                name={"BankName"}
                                inputValue={bankName}
                                setInputValue={(val) => setBankName(val)}
                                warningView={false}
                                placeholder="Bank Name" 
                                
                                />}
                {methodType==="BANK" &&<InputComponent
                                name={"AccountNo"}
                                inputValue={bankAccountNr}
                                setInputValue={(val) => setBankAccountNr(val)}
                                warningView={false}
                                placeholder="Bank Account No." 
                                
                                />}
               {methodType==="BANK" && <InputComponent
                                name={"BankBranch"}
                                inputValue={bankBranchName}
                                setInputValue={(val) => setBankBranchName(val)}
                                warningView={false}
                                placeholder="Bank Branch Name" 
                                
                                />}
               {methodType==="BANK" || <InputComponent
                                name={"Phone"}
                                inputValue={phoneNo}
                                setInputValue={(val) => setPhoneNo(val)}
                                warningView={false}
                                placeholder="Phone Number" 
                                
                                />}
                
                <ButtonFilled  title="Submit"
                                            buttonFilledHandle={
                                                adminCreateMethodHandler
                                            }
                                            w="100%"
                                            h="49px" />
                                        

            </div>
           
            </div>
          </div>
          <div className='w-1/2 flex justify-center'>
          <div className=' border border-txt-hint px-4 py-2 rounded-[1rem] ' >
            <div className='text-btn-primary w-full text-center'>Existing Payment Methods</div>
            <div className='space-y-[1rem] mt-[2rem] h-[400px] overflow-y-scroll '> 
                {allMethod?.map((method)=>
                <div className='shadow p-2 hover:shadow-lg cursor-pointer'>
                 <div className='flex items-center space-x-[10px]'>
                 <span className='bg-btn-primary w-[10px] h-[10px] rounded-full'></span>
                 <span className='text-btn-primary'>{method?.methodName}</span> 
                 </div>
                 <div className='flex flex-col text-[12px]'>
                    {<span>Type : {method?.methodType}</span>}
                    {method.bankName && <span>Bank Name : {method?.bankName} </span>}
                    {method.bankBranchName && <span>Branch : {method?.bankBranchName} </span>}
                    {method.bankAccountNr && <span>Account No : {method?.bankAccountNr} </span>}
                    {method.phoneNo && <span>Marchant phoneNo : {method.phoneNo} </span>}
                 </div>
                </div>)}

                
                                        
            </div>


          </div>

          </div>
          


          </div>

          
          </div> 
        </div>
    );
};

export default AdminCreatePaymentMethod;