import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./utils/firebase";
import { Provider } from "react-redux";
import rootReducer from "./store/rootReducer";
import thunk from "redux-thunk";
import { applyMiddleware, createStore, compose } from "redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./styles/toast.css";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    {/* <ToastContainer /> */}

    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

    {/* <ThemeProvider theme={theme}> */}
    <BrowserRouter>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  
    </BrowserRouter>
    {/* </ThemeProvider> */}
    {/* strict mode removed */}
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
