import React from "react";
import {
  adminRoute,
  merchantRoute,
  SummeryStatus,
  UserType,
} from "../../contants/Constants";
import bgimage from "../../assets/images/hotelBg4.jpg";
import cardEdit from "../../assets/images/Close_Map.png";
import { useLocation, useNavigate } from "react-router";
import { returnAdminOrUserBasedOnPath } from "../../utils/returnAdminOrUserBasedOnPath";

function HotelCard({
  id,
  propertyName,
  bedRooms,
  street,
  place,
  zip,
  status,
  images,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleEditHotel = (id) => {
    navigate(
      returnAdminOrUserBasedOnPath(location.pathname) == UserType.USER
        ? merchantRoute.HOTEL + id
        : adminRoute.ADMIN_HOTEL +
            id
    );
  };
  return (
    <div>
      <div className="relative">
        <div className="">
          {/* bg-btn-primary w-[315px] h-[293px] rounded-[19px] */}
          <img
            className={`rounded-[19px] object-contain ${
              status == SummeryStatus.DRAFT
                ? " opacity-95 brightness-50 contrast-75 grayscale"
                : ""
            }`}
            src={bgimage}
            width={"315px"}
            height={"293px"}
            alt=""
          />
        </div>
        <div className="absolute top-0 w-[315px] flex flex-col h-auto rounded-xl">
          <div className="flex justify-end py-1 px-4">
            <button
              onClick={() => handleEditHotel(id)}
              className="cursor-pointer w-[51px] h-[51px] flex items-center justify-center"
            >
              <img src={cardEdit} alt="" />
            </button>
          </div>
          <h1 className="text-txt-hint text-[40px] text-center pt-10">
            {status == SummeryStatus.ACCEPTED ? "" : status}
          </h1>
          <div className=" h-[150px] rounded-br-xl rounded-bl-xl">
            <div className="flex justify-between items-center px-4 pt-4">
              <div className="flex items-center">
                <span>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 2.1875C14.3103 2.19126 11.2523 3.46005 8.99679 5.71553C6.74131 7.97101 5.47253 11.029 5.46876 14.2188C5.46494 16.8254 6.3164 19.3613 7.89251 21.4375C7.89251 21.4375 8.22064 21.8695 8.27423 21.9319L17.5 32.8125L26.7302 21.9264C26.7783 21.8684 27.1075 21.4375 27.1075 21.4375L27.1086 21.4342C28.6839 19.359 29.535 16.8242 29.5313 14.2188C29.5275 11.029 28.2587 7.97101 26.0032 5.71553C23.7478 3.46005 20.6897 2.19126 17.5 2.1875ZM17.5 18.5938C16.6347 18.5938 15.7889 18.3372 15.0694 17.8564C14.3499 17.3757 13.7892 16.6924 13.458 15.893C13.1269 15.0936 13.0403 14.2139 13.2091 13.3652C13.3779 12.5166 13.7946 11.737 14.4064 11.1252C15.0183 10.5133 15.7978 10.0966 16.6465 9.92781C17.4952 9.759 18.3748 9.84564 19.1743 10.1768C19.9737 10.5079 20.657 11.0687 21.1377 11.7881C21.6184 12.5076 21.875 13.3535 21.875 14.2188C21.8736 15.3786 21.4122 16.4906 20.592 17.3107C19.7718 18.1309 18.6599 18.5923 17.5 18.5938Z"
                      fill="#FFFBEF"
                    />
                  </svg>
                </span>
                <div className="ml-[10px]">
                  <h3 className="text-[10px]  leading-4 text-txt-secondary">
                    {propertyName}
                  </h3>
                  <p className="text-[7px] leading-4 text-txt-secondary">
                    {street} {place}-{zip}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center px-2">
                <button className="bg-bg-semiwhite backdrop-blur-[4px] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                  <p className="text-[10px] leading-medium text-txt-secondary">
                    N/A
                  </p>
                </button>
                <p className="text-[7.17px] leading-small text-txt-secondary mt-1">
                  0 reviews
                </p>
              </div>
            </div>
            <hr className="bg-bg-white text-txt-secondary mt-[15px] mb-[22px]" />
            <div className="flex flex-col">
              <div className="px-2 flex items-center justify-start">
                <p className="text-[7.17px] px-2 leading-small text-txt-secondary">
                  {bedRooms} Bedrooms
                </p>
                <div className="">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.48781 7C5.07317 7 4.71951 6.85714 4.42683 6.57143C4.14228 6.27755 4 5.92245 4 5.50612C4 5.08163 4.14228 4.72653 4.42683 4.44082C4.71951 4.14694 5.07317 4 5.48781 4C5.91057 4 6.26829 4.14694 6.56098 4.44082C6.85366 4.72653 7 5.08163 7 5.50612C7 5.92245 6.85366 6.27755 6.56098 6.57143C6.26829 6.85714 5.91057 7 5.48781 7Z"
                      fill="#FFFBEF"
                    />
                  </svg>
                </div>
                <p className="text-[7.17px] px-2 leading-small text-txt-secondary">
                  Up to 6 Guests
                </p>
                <div className="">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.48781 7C5.07317 7 4.71951 6.85714 4.42683 6.57143C4.14228 6.27755 4 5.92245 4 5.50612C4 5.08163 4.14228 4.72653 4.42683 4.44082C4.71951 4.14694 5.07317 4 5.48781 4C5.91057 4 6.26829 4.14694 6.56098 4.44082C6.85366 4.72653 7 5.08163 7 5.50612C7 5.92245 6.85366 6.27755 6.56098 6.57143C6.26829 6.85714 5.91057 7 5.48781 7Z"
                      fill="#FFFBEF"
                    />
                  </svg>
                </div>
                <p className="text-[7.17px] px-2 leading-small text-txt-secondary">
                  Kids Friendly Area
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotelCard;
