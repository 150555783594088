import React, { useState } from "react";
import FileUpload from "./FileUpload";
import RootComponent from "./rootComponent/RootComponent";
import RootComponentTable from "./rootComponent/RootComponentTable";
import SingleDropdownSelect from "./SingleDropdownSelect";
import TrippleDocActionButton from "./TrippleDocActionButton";

function DocumentUploadRow({
  name,
  isSelected,
  setIsSelected,
  selectedFile,
  setSelectedFile,
  // name,
  isAdmin,
  isReadOnly,
  rejectionArray,
  setRejectionArray,
}) {
  console.log(selectedFile, "selectedFile");
  function decideImage(url) {
    return (
      <a href={url} target="_blank">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M42.9399 13.0209V42.1209C42.9399 43.2809 42.3599 44.3109 41.4799 44.9309C40.9299 45.3309 40.2399 45.5509 39.5099 45.5509H14.5399C13.8099 45.5509 13.1299 45.3209 12.5699 44.9309C11.6899 44.3109 11.1099 43.2909 11.1099 42.1209V7.88094C11.1099 6.72094 11.6899 5.69094 12.5799 5.07094C13.1299 4.69094 13.7999 4.46094 14.5299 4.46094H34.6399L42.9399 13.0209Z"
            fill="#EAEAEA"
          />
          <path
            d="M42.9401 13.0209H36.9301C35.6701 13.0209 34.6401 12.0009 34.6401 10.7309V4.46094L42.9401 13.0209Z"
            fill="#A8A8A8"
          />
          <path
            d="M38.8503 23.25V35.93C38.8503 36.57 38.3303 37.09 37.6903 37.09H11.1104V23.25H38.8503Z"
            fill="#D4D4D6"
          />
          <path
            d="M38.8501 23.2004V34.5104C38.8501 35.3604 38.1601 36.0604 37.3001 36.0604H8.61006C7.76006 36.0604 7.06006 35.3704 7.06006 34.5104V23.2004C7.06006 22.3504 7.75006 21.6504 8.61006 21.6504H37.3101C38.1601 21.6504 38.8501 22.3404 38.8501 23.2004Z"
            fill="#F15642"
          />
          <path
            d="M14.2998 32.18V25.5H16.4598C17.2798 25.5 17.8098 25.53 18.0598 25.6C18.4398 25.7 18.7598 25.92 19.0198 26.25C19.2798 26.59 19.4098 27.02 19.4098 27.55C19.4098 27.96 19.3398 28.3 19.1898 28.58C19.0398 28.86 18.8498 29.08 18.6198 29.24C18.3898 29.4 18.1598 29.5 17.9198 29.56C17.5998 29.62 17.1298 29.66 16.5198 29.66H15.6398V32.18H14.2998ZM15.6498 26.63V28.53H16.3898C16.9198 28.53 17.2798 28.5 17.4598 28.43C17.6398 28.36 17.7798 28.25 17.8798 28.1C17.9798 27.95 18.0298 27.78 18.0298 27.58C18.0298 27.34 17.9598 27.14 17.8198 26.98C17.6798 26.82 17.4998 26.72 17.2798 26.68C17.1198 26.65 16.7998 26.63 16.3098 26.63H15.6498Z"
            fill="#EAEAEA"
          />
          <path
            d="M20.52 25.5H22.99C23.55 25.5 23.97 25.54 24.26 25.63C24.65 25.75 24.99 25.95 25.27 26.25C25.55 26.54 25.76 26.91 25.91 27.33C26.06 27.76 26.13 28.28 26.13 28.91C26.13 29.46 26.06 29.93 25.92 30.33C25.75 30.82 25.51 31.21 25.2 31.51C24.97 31.74 24.65 31.92 24.25 32.04C23.95 32.13 23.55 32.18 23.06 32.18H20.52V25.5ZM21.87 26.63V31.05H22.88C23.26 31.05 23.53 31.03 23.7 30.99C23.92 30.94 24.1 30.84 24.24 30.71C24.38 30.58 24.5 30.36 24.59 30.07C24.68 29.77 24.73 29.36 24.73 28.85C24.73 28.33 24.68 27.94 24.59 27.66C24.5 27.38 24.37 27.17 24.21 27.01C24.05 26.86 23.84 26.75 23.59 26.7C23.4 26.66 23.03 26.64 22.48 26.64H21.87V26.63Z"
            fill="#EAEAEA"
          />
          <path
            d="M27.27 32.18V25.5H31.85V26.63H28.62V28.21H31.41V29.34H28.62V32.18H27.27Z"
            fill="#EAEAEA"
          />
          <path
            d="M38.2402 13.0215L42.9402 17.8615V13.0215H38.2402Z"
            fill="#D4D4D6"
          />
        </svg>
      </a>
    );
  }
  const [value, setValue] = useState("select..");
  const values = ["...", "Edit", "Delete"];
  const component = (
    <>
      <td className="py-5">
        <p className="px-8 ">{name}</p>
      </td>
      <td>
        <FileUpload
          name={name}
          // name={name}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </td>
      <td>
        <p>{isSelected ? selectedFile.name : ""}</p>
      </td>
      <td>
        <p className="">{isSelected ? decideImage(selectedFile.url) : ""}</p>
      </td>

      <td>
        <TrippleDocActionButton />
        {/* <SingleDropdownSelect values={values} setValue={setValue} value={value} /> */}
      </td>
    </>
  );
  let readOnlyViewComponent = <p>{"inputValue"}</p>;

  return (
    <>
      {selectedFile ? (
        <RootComponentTable
          component={component}
          readOnlyViewComponent={readOnlyViewComponent}
          // warningText={warningText}
          // warningname={warningname}
          isAdmin={isAdmin}
          isReadOnly={isReadOnly}
          rejectionArray={rejectionArray}
          setRejectionArray={setRejectionArray}
          name={selectedFile.id}
        />
      ) : (
        <tr>{component}</tr>
      )}
    </>
  );
}

export default DocumentUploadRow;
